import { v4 } from 'uuid'

export const syncScrollRef = v4()

/**是否强制安装App或者桌面快捷方式 0关闭 1开启 */
export const ForceInStallationSwitch = {
  /**关闭 */
  Close: 0,
  /**开启1 */
  Open: 1
}

/**引导安装的类型  0关闭 1开启安装app 2开启快捷方式开关 */
export const BootInstallationSwitch = {
  /**关闭 */
  Close: 0,
  /**提示安装APP */
  APP: 1,
  /**提示安装桌面快捷方式 */
  H5: 2
}
