import { GlobalService } from '@/context'
import { IpCheck, TraceMessage } from '../entity/site'
import { param } from 'typox'
import type { AxiosError, AxiosResponse } from 'axios'
import type { Response } from '@/context/network'

type IpCheckConfig = {
  silentOnError?: boolean
  cache?: boolean | number
  useCache?: boolean
  params?: unknown
  useCacheInError?: (
    res: AxiosResponse<Response> | AxiosError,
    errorType: false | 'axiosError' | 'dataError'
  ) => boolean
}
export class SiteRepository {
  /**
   * 返回依赖客户端IP的所有数据
   * @returns IpCheck
   */
  static async ipCheck(
    {
      silentOnError = true,
      cache = 1000 * 60 * 15,
      useCache = true,
      params = {},
      useCacheInError = (_, errorType) => {
        return !!errorType
      }
    }: IpCheckConfig = {
      silentOnError: true,
      /**
       * 默认15分钟内用缓存,浏览器刷新也使用缓存数据
       */
      cache: 1000 * 60 * 15,
      useCache: true,
      params: {},
      useCacheInError: (_, errorType) => {
        return !!errorType
      }
    }
  ): Promise<IpCheck> {
    return GlobalService.get({
      url: '/ipCheck',
      customParams: {
        noMerge: true,
        noEncrypt: true,
        noErrorMessage: true,
        cache,
        useCache,
        cacheExpiredOnReload: false,
        /**
         * 缓存参数只与传入参数有关
         */
        cacheDep: (id) => id,
        useCacheInError,
        silentOnError
      },
      params,
      /**
       * 南美停机后请求都是挂起状态，需要客户端主动抛出超时错误
       * 不影响
       */
      timeout: 1 * 5 * 1000
    }).then((res) => {
      const raw = new IpCheck().parse(res.data.data)
      raw.axios = res
      return raw
    })
  }

  /**
   * 日志上报
   * @param message TraceMessage
   * @returns
   */
  @param(TraceMessage)
  static async report(message: TraceMessage): Promise<unknown> {
    return GlobalService.post({
      url: '/v1/netstat/player/logger',
      data: message.reverse(),
      customParams: {
        noErrorMessage: true,
        silentOnError: true,
        noResponseDataCodeChecked: true,
        noEncrypt: true
      }
    })
  }
}
