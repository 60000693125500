export class Event {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public target: any
  public type: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(type: string, target: any) {
    this.target = target
    this.type = type
  }
}

export class ErrorEvent extends Event {
  public message: string
  public error: Error
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(error: Error, target: any) {
    super('error', target)
    this.message = error.message
    this.error = error
  }
}

export class CloseEvent extends Event {
  public code: number
  public reason: string
  public wasClean = true
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(code = 1000, reason = '', target: any) {
    super('close', target)
    this.code = code
    this.reason = reason
  }
}
export interface WebSocketEventMap {
  close: CloseEvent
  error: ErrorEvent
  message: MessageEvent
  open: Event
}

export interface WebSocketEventListenerMap {
  close: (
    event: CloseEvent
  ) => void | { handleEvent: (event: CloseEvent) => void }
  error: (
    event: ErrorEvent
  ) => void | { handleEvent: (event: ErrorEvent) => void }
  message: (
    event: MessageEvent
  ) => void | { handleEvent: (event: MessageEvent) => void }
  open: (event: Event) => void | { handleEvent: (event: Event) => void }
}
