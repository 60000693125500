export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'সিস্টেম ত্রুটি',
        NETWORK_ERROR:
          'নেটওয়ার্ক সংযোগ ব্যর্থ হয়েছে, অনুগ্রহ করে নেটওয়ার্ক স্যুইচ করুন এবং আবার চেষ্টা করুন৷',
        RSP_DATA_NOT_JSON: 'প্রতিক্রিয়া মান ফর্ম্যাট ত্রুটি',
        GEETEST_RESTRICTED:
          'অপারেট ঘন ঘন, দয়া করে {সেকেন্ডে} সেকেন্ডে পুনরায় চেষ্টা করুন'
      },
      tips: { title: 'রিমাইস' }
    }
  }
}
