import { IndexDBStorage, MyStorage } from '@/plugins/pinia-plugin-persist'

/**
 * 包装创建持久化的方法，
 * 当 storage 为 IndexDBStorage 实例时 ,setItem方法无效
 */
export const createMyPersistedStorage = (
  config?: Partial<MyStorage> | null,
  storage: MyStorage = new IndexDBStorage()
) => {
  return {
    withIndexDB: storage instanceof IndexDBStorage ? storage.withIndexDB : null,
    overwriteConfig: config,
    setItem: storage.setItem.bind(storage),
    getItem: storage.getItem.bind(storage),
    removeItem: storage.removeItem.bind(storage)
  } as MyStorage
}
