import { Base, Component, Prop } from '@/vue-property-decorator'
import AppTypes, { string } from '@/vue-types'

export type Props = {
  remUnit?: number
  unit?: 'rem' | 'px'
}

@Component<MyUnit>({
  name: 'MyUnit'
})
export default class MyUnit extends Base {
  @Prop(AppTypes.number.def(100))
  protected readonly remUnit!: NonNullable<Props['remUnit']>
  @Prop(string<NonNullable<Props['unit']>>().def('rem'))
  protected readonly unit!: NonNullable<Props['unit']>

  protected get isRemMode() {
    return this.unit === 'rem'
  }

  /**
   * 基准值，rem模式时使用remUnit，px模式时使用1
   */
  protected get baseUnit() {
    return this.isRemMode ? this.remUnit : 1
  }
}
