import { CombinePictureUrl } from '../util/combinePictureUrl'
import { Entity, Model, from, nullable, parse, type } from 'typox'
import { GamePlatformCatgory } from '@/views/game/consts/categorys'
import { _GameItemInter, _GameItemType } from './gameItemInter'
import { gameOssDomin } from '../util/util'
import { numberToBool } from '@/service/extensions'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any

/** 热门游戏或普通子游戏信息 */
@Entity()
export default class _GameItem extends Model implements _GameItemInter {
  /** 类型守卫 */
  public static isThisType(data: Any): data is _GameItem {
    const keys = ['gameIcon', 'gameType']
    if (keys.every((key) => Reflect.has(data, key))) {
      return true
    } else {
      return false
    }
  }

  /** web端使用的唯一id */
  @type(String)
  @parse((_, item) => `${item?.g9}_${item?.g10}_${item?.g0}`)
  webId = ''

  /** 游戏 id */
  @from('g0')
  @type(Number)
  @nullable()
  gameId = 0

  /** 游戏名称 */
  @from('g1')
  @type(String)
  @nullable()
  gameName = ''

  /** 游戏图标 */
  // @from('g2')
  @parse((val, data) => CombinePictureUrl.parseGameItem(data))
  @type(String)
  @nullable()
  gameIcon = ''

  /** 自定义图片 0-否, 1-是  */
  @from('g3')
  @type(Boolean)
  @parse(numberToBool)
  @nullable()
  isCustomIcon = false

  /** 是否美术上传的原始图片 0-否, 1-是 */
  @from('g16')
  @type(Boolean)
  @parse(numberToBool)
  @nullable()
  isOriginImg = false

  /** 维护状态 0-否, 1-是  */
  @from('g4')
  @type(Boolean)
  @parse(numberToBool)
  maintainStatus = false

  /** 是否推荐 0-否, 1-是  */
  @from('g5')
  @type(Boolean)
  @parse(numberToBool)
  recommendStatus = false

  /** 是否支持试玩 0-否, 1-是  */
  @from('g6')
  @type(Boolean)
  @parse(numberToBool)
  demoModeSupport = false

  /** 屏幕方向: 0=横屏, 1=竖屏  */
  @from('g7')
  @type(Boolean)
  @parse(numberToBool)
  screenDirection = false

  /** 是否主播号: 0-否, 1-是 */
  @from('g8')
  @type(Boolean)
  @parse(numberToBool)
  anchorSupport = false

  /** 游戏类别  */
  @from('g9')
  @type(Number)
  @nullable()
  gameCategoryId = 0

  /** 游戏平台ID
   * 注: 方型卡片,需要特殊判断platformId,如果是13(WG)平台,则要进行logo的拼接,其它平台全都是整图,则没有这个问题.
   */
  @from('g10')
  @type(Number)
  @nullable()
  platformId = 0

  /**
   * 热门类型 1=平台入口 2=普通游戏
   * 这里的普通游戏可能会不给返回值，那我们就给它默认赋值2(普通游戏)
   */
  @from('g11')
  @type(Number)
  @nullable()
  @parse(
    (_, item) =>
      item?.g11 ||
      (GamePlatformCatgory.includes(item.g9)
        ? _GameItemType.Platform
        : _GameItemType.NormalGame)
  )
  gameType: _GameItemType = _GameItemType.NormalGame

  /** 是否三合一图(图片/logo/背景色) 0-否，1是 */
  @from('g12')
  @type(Boolean)
  @parse(numberToBool)
  isCombinate = false

  /** 方形图标5159独有后续会删除 */
  @from('g13')
  @type(String)
  @parse(gameOssDomin)
  squareGameIcon = ''

  /** 自定义图片5159独有后续会删除 0-否, 1-是  */
  @from('g14')
  @type(Boolean)
  @parse(numberToBool)
  isSquareCustomIcon = false

  /** 是否方形三合一图5159独有后续会删除(图片/logo/背景色) 0-否，1是 */
  @from('g15')
  @type(Boolean)
  @parse(numberToBool)
  isSquareCombinate = false

  /** 平台名称 */
  platformName = ''

  /** 平台logo */
  platformLogo = ''

  /** 游戏打开方式 0-内嵌, 1-外链 */
  isExternalLink = false

  /** 最小准入余额 */
  minimumEntryBalance = 0
}
