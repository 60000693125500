export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'సిస్టమ్ లోపం',
        NETWORK_ERROR:
          'నెట్‌వర్క్ కనెక్షన్ విఫలమైంది, దయచేసి నెట్‌వర్క్‌ను మార్చిన తర్వాత మళ్లీ ప్రయత్నించండి',
        RSP_DATA_NOT_JSON: 'ప్రతిస్పందన విలువ ఆకృతి తప్పు',
        GEETEST_RESTRICTED:
          'తరచుగా చేసే కార్యకలాపాలు, దయచేసి {seconds}సెకన్లలో మళ్లీ ప్రయత్నించండి'
      },
      tips: { title: 'చిట్కాలు' }
    }
  }
}
