import { AntLocale } from '@/plugins/ant-design-vue/type'
import { antDesignMessages, baseMessages, momentLocale } from './map'
import {
  cloneDeep,
  forOwn,
  get,
  isArray,
  isObject,
  merge,
  mergeWith,
  set
} from 'lodash'
import { getStorage, setStorage } from '@/utils/Tool'
// import { loadImage } from '@/utils/Loader'
import { GlobalEvents } from '@/context'
import { tiled } from './auto-script/utils'
import { useMainStore } from '@/store/index'
import Vue from 'vue'
import VueI18n, { LocaleMessageObject } from 'vue-i18n'
import localforage from 'localforage'
import moment from 'moment'

export const languageList = [
  'en_US',
  'zh_CN',
  'zh_TW',
  'th_TH',
  'vi_VN',
  'ko_KR',
  'ja_JP',
  'pt_PT',
  'es_ES',
  'de_DE',
  'fr_FR',
  'it_IT',
  'ru_RU',
  'my_MM',
  'id_ID',
  'hi_IN',
  'tl_PH',
  'km_KH',
  'te_IN',
  'ta_LK',
  'mr_IN',
  'kn_IN',
  'tr_TR',
  'bn_BN',
  'pa_PA'
] as const

export type Language = typeof languageList[number]

/**
 * 下载完毕后 i18n.messages的类型
 */
export type I18nMessages = Record<
  Language,
  LocaleMessageObject & { ant?: AntLocale }
>

// eslint-disable-next-line @typescript-eslint/ban-types
type I18nInstance = VueI18n
// eslint-disable-next-line @typescript-eslint/ban-types
type MessageObject = LocaleMessageObject | AntLocale

/**
 * 逐步添加的语言配置
 */
export type Messages = Record<
  Language,
  (() => Promise<{ default: MessageObject }>) | MessageObject
>

interface LocaleOptions {
  defaultLanguage?: Language //初始装载的语言
  language?: Language[] //需要下载的语言列表
  messages: Messages | Messages[] // messsage配置
  loadAntLocale?: boolean // 是否混入Ant的国际化
  loadLanguageAsync?: boolean // 是否开启语言的按需加载，开启后，使用该语言时才会进行下载
}

/** 附加日志 */
export const addtialLog = (
  newMessages: VueI18n.LocaleMessageObject & {
    ant?: AntLocale
  },
  oldMessages: VueI18n.LocaleMessageObject
) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const i18nDiffLog = Object.entries(tiled(oldMessages))
      .filter(([key, value]) => {
        // 此处key必须是以lobby.开头是因为还可能存在ant等的多语言中的内容
        return key.startsWith('lobby.') && get(newMessages, key) !== value
      })
      .map(([key, value]) => {
        return {
          key,
          pre: value,
          next: get(newMessages, key)
        }
      })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).i18nDiffLogArrDif = []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).i18nDiffLogArrDif.push(...(i18nDiffLog || []))
  } catch (error) {}
}

/** 是否含有\n */
export const hasBreakN = (
  messages: VueI18n.LocaleMessageObject & {
    ant?: AntLocale
  }
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(window as any).i18nHasN = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const recursiveIterate = (obj: Record<string, any>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    forOwn(obj, (value: any, key) => {
      if (isObject(value)) {
        recursiveIterate(value) // 递归遍历子对象
      } else {
        if (typeof value === 'string') {
          if (value.includes(`\\n`)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ;(window as any).i18nHasN.push({ key, value })
          }
        }
      }
    })
  }
  recursiveIterate(messages)
}

export class Locale {
  public store = localforage.createInstance({
    name: 'i18n-messages-store'
  })
  private options!: LocaleOptions
  private messages: Set<Messages> = new Set()
  private loading = false
  public i18n!: VueI18n
  constructor(options: LocaleOptions) {
    this.setOptions(options)
  }

  public setOptions(options: Partial<LocaleOptions>) {
    this.options = mergeWith(
      {},
      this.options,
      options,
      (objValue, srcValue) => {
        if (isArray(objValue)) {
          return srcValue
        }
      }
    )
    return this
  }

  private async loadMessages(
    messages: Messages
  ): Promise<Record<Language, MessageObject>> {
    const promises = Object.entries(messages).map(([key, value]) => {
      return (async () => {
        const lang = key as Language
        const message: MessageObject =
          typeof value === 'function' ? (await value()).default : value
        return [lang, message] as [Language, MessageObject]
      })()
    })
    const result = await Promise.all(promises)
    return result.reduce((pre, [key, value]) => {
      pre[key] = value
      return pre
    }, {} as Record<Language, MessageObject>)
  }

  public async loadLanguageAsync(
    filterLanguages: Language[],
    isInit = false
  ): Promise<I18nMessages> {
    this.loading = true
    let message = {} as I18nMessages
    try {
      const needLoadLanguages = filterLanguages.filter(Boolean)
      const { loadAntLocale } = this.options
      const doMerge = (messagesArg: I18nMessages) => {
        message = merge({}, messagesArg)
        for (const lang of needLoadLanguages) {
          if (this.i18n) {
            const oldMessages = this.i18n.messages?.[lang] || {}
            const newMessages = message[lang] || {}
            //RETURN66 因本地也维护了一份多语言表,故收集每次更新的差异项,正式上线需要注释掉
            // addtialLog(newMessages, oldMessages)
            //RETURN66 遍历数据,查看是否有包含\n的字符串
            // hasBreakN(newMessages)
            // hasBreakN(oldMessages)
            this.i18n.mergeLocaleMessage(
              lang,
              Object.entries(newMessages).reduce((pre, [key, value]) => {
                set(pre, key, value)
                return pre
              }, cloneDeep(oldMessages))
            )
            this.store.setItem(lang, this.i18n.messages?.[lang] || {})
          }
        }
      }
      if (loadAntLocale && !isInit) {
        const antLocale = (await this.loadMessages(
          this.messagesFilter(antDesignMessages, needLoadLanguages)
        )) as Record<Language, AntLocale>
        doMerge(
          Object.keys(antLocale).reduce((pre, cur) => {
            const key = cur as Language
            merge(pre, {
              [key]: {
                ant: antLocale[key]
              }
            })
            return pre
          }, {}) as I18nMessages
        )
      }

      for (const iterator of this.messages) {
        doMerge(
          await this.loadMessages(
            this.messagesFilter(iterator, needLoadLanguages)
          )
        )
      }

      return message
    } catch (error) {
      return message
    } finally {
      this.loading = false
    }
  }

  private messagesFilter(
    messages: Messages,
    filterLanguages?: Language[]
  ): Messages {
    return Object.entries(messages).reduce((pre, [lang, value]) => {
      const languages = filterLanguages || []
      if (languages.includes(lang as Language)) {
        pre[lang as Language] = value
      }
      return pre
    }, {} as Messages)
  }

  public setAntLocale(locale_?: Language) {
    const appStore = useMainStore()
    locale_ = locale_ || (this.i18n.locale as Language)
    const antLocale = (this.i18n.messages as I18nMessages)[locale_].ant
    const toRaw = (s: string) => s
    moment.locale(antLocale?.locale, {
      // 保持moment阿拉伯数字的国际化
      preparse: toRaw,
      postformat: toRaw
    })
    appStore.$patch({
      language: locale_
    })
  }

  public async create(): Promise<I18nInstance> {
    const {
      defaultLanguage: locale_,
      loadLanguageAsync,
      language
    } = this.options

    const filterLanguages =
      loadLanguageAsync && locale_ ? [locale_] : language || []
    Vue.use(VueI18n)
    this.add(this.options.messages)
    const messages = (await this.loadLanguageAsync(filterLanguages, true)) || {}

    this.i18n = new VueI18n({
      locale: locale_,
      messages,
      missing: () => {
        return ''
      },
      silentTranslationWarn: true // 解决开发模式下i18n一直warning的问题
    })

    return this.i18n
  }

  public add(messages: Messages | Messages[]) {
    const messagesArr = Array.isArray(messages) ? messages : [messages]
    for (const iterator of messagesArr) {
      this.messages.add(iterator)
    }
    return this
  }

  public async setLanguage(
    locale_: Language,
    instance: I18nInstance = this.i18n
  ): Promise<Language> {
    await Promise.all([
      this.loadLanguageAsync([locale_]),
      momentLocale[locale_]()
    ])

    GlobalEvents.dispatch({
      type: 'LANGUAGE_CHANGE',
      payload: locale_
    })

    this.setAntLocale(locale_)
    instance.locale = locale_
    return locale_
  }

  public t(
    path: string,
    options: string[] | { [key: string]: unknown } = []
  ): string {
    const i18nResult = this.i18n.t(
      path,
      !Array.isArray(options) ? options : undefined
    ) as string

    return Array.isArray(options)
      ? i18nResult.replace(/\{\d\}/g, (match) => {
          const index = +match.replace(/[{}]/g, '')
          if (Array.isArray(options)) {
            return options[index] || match
          }
          return match
        })
      : i18nResult
  }
}

export const languageFromStorage = getStorage('app', window.sessionStorage)[
  'language'
]

export const defaultLanguage = window.LOBBY_SITE_CONFIG.SITE_DOMAIN_NOT_MATCH
  ? 'en_US'
  : languageList.includes(languageFromStorage)
  ? languageFromStorage
  : languageList[0]

/**
 * 确保语言code更新后，页面语言不出错
 */
setStorage(
  {
    language: defaultLanguage
  },
  'app',
  window.sessionStorage
)

const locale = new Locale({
  messages: [baseMessages],
  defaultLanguage,
  language: [defaultLanguage],
  loadAntLocale: true,
  loadLanguageAsync: true
})

export default locale

export const useI18n = () => {
  const instance = locale.i18n
  return {
    ...instance,
    t: instance.t.bind(instance),
    loadLanguageAsync: (langs: Language[]) => locale.loadLanguageAsync(langs)
  }
}
