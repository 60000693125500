export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'Erro no sistema',
        NETWORK_ERROR:
          'A conexão de rede falhou, tente novamente depois de mudar a rede',
        RSP_DATA_NOT_JSON: 'O formato do valor da resposta está incorreto',
        GEETEST_RESTRICTED:
          'Operações frequentes, tente novamente em {seconds} segundos'
      },
      tips: { title: 'Lembrete' }
    }
  }
}
