export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'Errore del sistema',
        NETWORK_ERROR:
          'La connessione di rete non è riuscita, riprova dopo aver cambiato la rete',
        RSP_DATA_NOT_JSON: 'Formato del valore della risposta errato',
        GEETEST_RESTRICTED:
          'Operazioni frequenti, riprova tra {seconds} secondi'
      },
      tips: { title: 'Suggerimenti' }
    }
  }
}
