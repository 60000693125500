import { Base, Component, Prop, Watch } from '@/vue-property-decorator'
import { DownContext } from '../..'
import { GlobalEvents } from '@/context'
import { createSizeObserver } from '@/views/game/utils/createSizeObserver'
import { delay } from '@/utils/Tool'
import { useHomeStore } from '@/store/home'
import style from './style.module.scss'
interface State {
  // 是否高度为0
  isHeight0: boolean
  // dom是否存在于页面上
  isDomExist: boolean
  /** 是否有过渡 */
  isTrasition: boolean
  /** 是否displayNone让元素不可见 */
  isDisplayNone: boolean
}

/** 引导下载 */
@Component<FoldableBox>({ name: 'FoldableBox' })
export default class FoldableBox extends Base<State> {
  @Prop()
  private downContext!: DownContext
  state: State = {
    isHeight0: true,
    isDomExist: false,
    isTrasition: true,
    isDisplayNone: true
  }

  @Watch('state.isDomExist', { immediate: true })
  protected async throwAnimationEvent(isDomExist: boolean) {
    if (!isDomExist) {
      return
    }
    // 延迟一下,以保证dom可以渲染出来
    await delay(10)
    const foldableBoxRef = this.$refs['foldableBoxRef'] as HTMLSelectElement
    if (!foldableBoxRef) {
      return
    }
    // 当盒子的宽高动画执行时,向外抛出事件.因为它可能会影响一些fixed的定位的布局
    const { stop } = createSizeObserver(foldableBoxRef, () => {
      GlobalEvents.dispatch({ type: 'SUGGEST_STRETCH_ANIMATION' })
    })
    this.$once('hook:beforeDestroy', stop)
  }

  /** 隐藏模块
   * @param isTrasition 是否有过渡动画
   * @param isForever 是否永久删除节点(永久删除dom,节约性能)
   */
  public handleHidden = async (isTrasition = true, isForever = false) => {
    const { setIsShowSuggest } = useHomeStore()
    // 先动画将内容最小化,然后再displayNone隐藏
    this.state.isTrasition = isTrasition
    await Promise.resolve()
    this.state.isHeight0 = true
    if (isTrasition) {
      setTimeout(() => {
        this.state.isDisplayNone = true
      }, 500)
    } else {
      this.state.isDisplayNone = true
    }
    if (!isForever) return
    // 永久删除的话,将dom销毁节约性能.
    setTimeout(() => {
      this.setState({ isDomExist: false })
    }, 500)
    setIsShowSuggest(false)
  }
  /** 展示模块 */
  public handleShow = async (isTrasition = true) => {
    const { setIsShowSuggest } = useHomeStore()
    this.state.isDisplayNone = false
    this.state.isDomExist = true
    this.state.isTrasition = isTrasition
    if (isTrasition) {
      setTimeout(() => {
        this.state.isHeight0 = false
      }, 0)
    } else {
      this.state.isHeight0 = false
    }
    setIsShowSuggest(true)
  }
  render() {
    const placement = this.downContext?.ctxConfig?.placement
    const { isDisplayNone } = this.state
    return (
      (this.state.isDomExist && (
        <section
          key={'foldableBox'}
          ref={'foldableBoxRef'}
          class={{
            [style['foldable-box']]: true,
            [style['is-height0']]: this.state.isHeight0,
            [style['top']]: placement === 'top',
            [style['middle']]: placement === 'middle',
            [style['bottom']]: placement === 'bottom',
            [style['is-center-layout']]:
              parseInt(String(this.downContext.centerMaxWidth)) > 0
          }}
          style={{
            transition: this.state.isTrasition ? 'height 0.5s' : 'none',
            display: isDisplayNone ? 'none' : ''
          }}
        >
          <div class={style['stabilized']}>{this.$slots?.default}</div>
        </section>
      )) || <section></section>
    )
  }
}
