import { createSvgSprite } from '@/utils/business-utils/svg'
/* eslint-disable @typescript-eslint/no-explicit-any*/

import { Icon, Input } from 'ant-design-vue'
import { get, merge } from 'lodash'
import {
  getComponentFromProp,
  getListeners,
  getOptionProps
} from 'ant-design-vue/lib/_util/props-util'
import type { Component, PluginObject } from 'vue'
const ActionMap = {
  click: 'click',
  hover: 'mouseover'
}
import { IconType } from '../../type'
import { Merge } from 'type-fest'
import AppTypes, { func } from '@/vue-types'
import classNames from 'classnames'
const props = {
  hack_customSuffix: AppTypes.bool.def(false),
  hack_defaultVisible: AppTypes.bool.def(false),
  hack_eyeIcon: AppTypes.string.def('eye'),
  hack_eyeInvisibleIcon: AppTypes.string.def('eye-invisible'),
  hack_getEyeIconComponent: func<(visible: boolean) => Component>().def(
    (visible) => {
      return {
        functional: true,
        render() {
          return (
            <icon-sprite
              sprite={
                visible
                  ? createSvgSprite('comm_icon_show')
                  : createSvgSprite('comm_icon_hide')
              }
            />
          )
        }
      }
    }
  )
}

export type PropsHack = Merge<
  ExtractPropTypesForOutside<typeof props>,
  {
    /** 使用自定义suffix */
    hack_customSuffix?: boolean
    hack_defaultVisible?: boolean
    hack_eyeIcon?: IconType
    hack_eyeInvisibleIcon?: IconType
    hack_getEyeIconComponent?: (visible: boolean) => Component
  }
>

export function resolveOnChange(
  target: any,
  e: any,
  onChange: (e: Event) => void
) {
  if (onChange) {
    let event = e
    if (e.type === 'click') {
      // click clear icon
      event = Object.create(e, {
        target: {
          writable: true
        },
        currentTarget: {
          writable: true
        }
      })
      event.target = target
      event.currentTarget = target
      const originalInputValue = target.value
      // change target ref value cause e.target.value should be '' when clear input
      target.value = ''
      onChange(event)
      // reset target ref value
      target.value = originalInputValue
      return
    }
    onChange(event)
  }
}

const plugin: PluginObject<Record<string, unknown>> = {
  install() {
    /**
     * 测试antd 清空按钮失去焦点不可以点击（暂存，还未解决
     */
    merge(Input, {
      mixins: [
        ...(get(Input, 'mixins') || []),
        {
          mounted() {
            this._handleCloseIconTimer = null
            /**
             * 纠正初始状态
             */
            this._handleFocus(this.$refs.input === document.activeElement, 0)
            this.$refs.input.addEventListener('focus', this._onFocus)
            this.$refs.input.addEventListener('blur', this._onBlur)
          },
          beforeDestroy() {
            this.$refs.input.removeEventListener('focus', this._onFocus)
            this.$refs.input.removeEventListener('blur', this._onBlur)
          },
          methods: {
            _onFocus() {
              this._handleFocus(true)
            },
            _onBlur() {
              this._handleFocus(false)
            },
            _handleFocus(focus: boolean, delay = 300) {
              const context = this as any
              const done = () => {
                const $closeIcon = context.$el.querySelector(
                  '.anticon-close-circle'
                )
                $closeIcon &&
                  ($closeIcon.style.visibility = focus ? '' : 'hidden')
              }

              window.clearTimeout(context.handleCloseIconTimer)
              if (delay === 0) {
                return done()
              }
              context.handleCloseIconTimer = window.setTimeout(() => {
                done()
              }, delay)
            }
          }
        }
      ]
    })
    merge(Input.Password, {
      props,
      mixins: [
        ...(get(Input.Password, 'mixins') || []),
        {
          created() {
            this.visible = this.hack_defaultVisible
          }
        }
      ],
      methods: {
        getIcon(prefixCls: string) {
          const context = this as any
          const { action } = context.$props
          const iconTrigger = ActionMap[action as 'click' | 'hover'] || ''
          const component = context.hack_getEyeIconComponent?.(context.visible)
          const iconProps = {
            props: {
              type: context.visible
                ? context.hack_eyeIcon
                : context.hack_eyeInvisibleIcon,
              component
            },
            on: {
              [iconTrigger]: context.onVisibleChange,
              mousedown: (e: Event) => {
                e.preventDefault()
              },
              mouseup: (e: Event) => {
                e.preventDefault()
              }
            },
            class: `${prefixCls}-icon`,
            key: 'passwordIcon'
          }
          return <Icon {...iconProps} />
        }
      },
      render() {
        const context = this as any
        const {
          prefixCls: customizePrefixCls,
          inputPrefixCls: customizeInputPrefixCls,
          size,
          type,
          // suffix,
          visibilityToggle,
          ...restProps
        } = getOptionProps(this)

        const getPrefixCls = context.configProvider.getPrefixCls
        const inputPrefixCls = getPrefixCls('input', customizeInputPrefixCls)
        const prefixCls = getPrefixCls('input-password', customizePrefixCls)

        let suffixIcon, inputType
        if (context.hack_customSuffix) {
          suffixIcon = getComponentFromProp(this, 'suffix')
          inputType = type
        } else {
          suffixIcon = visibilityToggle && context.getIcon(prefixCls)
          inputType = context.visible || !context.value ? 'text' : 'password'
        }
        const inputClassName = classNames(prefixCls, {
          [`${prefixCls}-${size}`]: !!size
        })
        const inputProps = {
          props: {
            ...restProps,
            prefixCls: inputPrefixCls,
            size,
            suffix: suffixIcon,
            prefix: getComponentFromProp(this, 'prefix'),
            addonAfter: getComponentFromProp(this, 'addonAfter'),
            addonBefore: getComponentFromProp(this, 'addonBefore')
          },
          attrs: {
            ...context.$attrs,
            /**
             * 输入框空时不调用浏览器密码选择框，但也只是首次有效
             * 输入字符后再删除为空仍然会弹密码选择框
             */
            type: inputType
          },
          class: inputClassName,
          ref: 'input',
          on: getListeners(this)
        }
        return (
          <Input data-xxx={JSON.stringify(context.value)} {...inputProps} />
        )
      }
    })
  }
}
export default plugin
