import { Base, Component, Emit, Prop } from '@/vue-property-decorator'
import { BrandLogoType } from '@/api/common/type'
import { Button } from '@/plugins/ant-design-vue/tsx-support/index'
import { DownContext } from '../..'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
// const comm_icon_x_svg = createSvgSprite('comm_icon_x')
import { GlobalEvents } from '@/context'
import style from './style.module.scss'
// 下载按钮的样式
type BtnDownloadStyle = {
  backgroundColor: string
  border: string
  color: string
}
interface Props {
  /** 下载配置的环境对象 */
  downContext: DownContext
}
interface Events {
  /** 关闭 */
  onClose: () => void
  /** 下载 */
  onDownload: () => void
}
/** 引导下载 */
@Component<MainContent>({ name: 'MainContent' })
export default class MainContent extends Base<unknown, Props, Events> {
  state: unknown = {}
  @Prop()
  private downContext!: DownContext
  private mainStore = useMainStore()
  /** 隐藏模块 */
  public handleHidden = () => {
    this.setState({ isHidden: true })
  }
  /** 展示模块 */
  public handleShow = () => {
    this.setState({ isHidden: false })
  }

  @Emit('close')
  private handleClose(event: MouseEvent) {
    event.stopPropagation()
  }
  /** 下载 */
  private handleDownload() {
    GlobalEvents.dispatch({ type: 'download_app' })
  }

  /** 渲染卡片的盒子,因为在青蓝版中,卡片是需要有版心(1200px)的,背景盒子的颜色需要和它保持一致 */
  private renderCardBox(card: VueTsxSupport.JSX.Element) {
    const { centerMaxWidth, ctxConfig } = this.downContext
    return (
      <div class={style['main-container-box']}>
        {/* 给左右着色,但是不给中间差色,避免半透明重叠变色 */}
        <div
          class={style['main-container-left']}
          style={{ backgroundColor: ctxConfig.bgColor }}
        ></div>
        {/* 中间不再重复着色,避免半透明重叠变色 */}
        <div
          class={style['main-container']}
          style={{ maxWidth: centerMaxWidth }}
        >
          {card}
        </div>
        {/* 给左右着色,但是不给中间差色,避免半透明重叠变色 */}
        <div
          class={style['main-container-right']}
          style={{ backgroundColor: ctxConfig.bgColor }}
        ></div>
      </div>
    )
  }
  /** 下载按钮的样式 */
  get btnDownloadStyle() {
    const style = {} as BtnDownloadStyle
    if (!this.downContext?.ctxConfig) return style
    const { placement, iconDownloadColor } = this.downContext.ctxConfig
    if (placement === 'middle') {
      // 中部按钮无底色,文字与边框使用配置颜色
      style.border = `${iconDownloadColor} thin solid`
      style.color = iconDownloadColor
    } else {
      // 上下按钮有底色,无边框,文字固定是白色
      style.backgroundColor = iconDownloadColor
      style.color = '#fff'
      style.border = 'none'
    }
    return style
  }
  /**
   * 渲染面板区域 此处的渲染相对简单,没太多逻辑
   * @param advertisement 广告区域JSX
   */
  private renderCard(advertisement: VueTsxSupport.JSX.Element) {
    const { ctxConfig } = this.downContext
    const { t } = useI18n()
    // 中部按钮无底色
    // const btnBgColor = placement === 'middle' ? undefined : iconDownloadColor
    // const btnBorderColor = btnBgColor ? 'none' : undefined
    return (
      <div
        class={{
          [style['main-content']]: true
        }}
        style={{
          backgroundColor: ctxConfig.bgColor
        }}
        onClick={this.handleDownload.bind(this)}
      >
        <div
          class={style['close-icon-wrapper']}
          onClick={this.handleClose.bind(this)}
        >
          <icon-sprite
            sprite={createSvgSprite('comm_icon_x')}
            useSkeleton
            style={{ color: ctxConfig.iconCloseColor }}
          />
        </div>
        {/* 渲染广告区域(含PC和Phone)  */}
        {advertisement}
        <div class={style['download-btn']}>
          <Button.Tsx type="default" style={this.btnDownloadStyle}>
            {t('lobby.gameMenu.toDownloadApp')}
          </Button.Tsx>
        </div>
      </div>
    )
  }

  /** 渲染广告区域(含PC和Phone)*/
  private renderAdvertisement() {
    const { renderType, brandName, langConfig, ctxConfig } = this.downContext
    const { brandNameColor, downloadTextColor } = ctxConfig
    // 如果没有查找到合适的配置项,那一定是后台配置错误,做一个容错处理.
    if (!langConfig) return <div></div>
    // 展示图片
    const exhibitImg = langConfig.src
    // 描述
    const description = langConfig.value
    /** 渲染的映射 */
    const rennderMapper = {
      // 简单的回显一张图片
      [BrandLogoType.CUSTOM]: (
        <div
          class={{ [style['advertisement']]: true, [style['only-img']]: true }}
        >
          <my-img height={51} useSkeleton src={exhibitImg}></my-img>
        </div>
      ),
      // 用户配置的图片/标题/描述内容拼装在一起
      [BrandLogoType.IMA_TXT]: (
        <div
          class={{
            [style['advertisement']]: true,
            [style['assembly-plank']]: true
          }}
        >
          <my-img height={51} useSkeleton src={exhibitImg}></my-img>
          <div class={style['detail-box']}>
            <div
              class={style['detail-title']}
              style={{ color: brandNameColor }}
            >
              {brandName}
            </div>
            <div class={style['detail-description']} title={description}>
              <div
                class={style['detail-description-text']}
                style={{ color: downloadTextColor }}
              >
                {description}
              </div>
            </div>
          </div>
        </div>
      )
    }
    return rennderMapper[renderType]
  }

  render() {
    const { ctxConfig, centerMaxWidth } = this.downContext
    // 无环境,不渲染
    if (!ctxConfig) return <template></template>
    // 大多数情况下(不需要版心的渲染)
    if (!centerMaxWidth) this.renderCard(this.renderAdvertisement())
    // 少量情况需要版心的渲染(如:青蓝版pc_top)
    return this.renderCardBox(this.renderCard(this.renderAdvertisement()))
  }
}
