import { Base, Component } from '@/vue-property-decorator'
import { RouteName } from '@/router/config'
import Skin from '@/controller/Skin'
import style from './style.module.scss'
@Component<Container>({
  name: 'Container'
})
export default class Container extends Base {
  render() {
    const { isGalaxyGold, isCrownGold } = Skin.currentTheme()
    const useScreenWidth =
      RouteName.GAME === this.$route.name ||
      RouteName.SUB_GAME === this.$route.name
    return (
      <div class={style.wrap}>
        {/* <PortalTarget name={GlobalConst.PortalName.RouterViewTop} /> */}
        <div class={[style.main, 'container-main']}>
          {/* {this.showDecorate && <LayoutHoliday />} */}
          <div
            class={[
              style.container,
              (isGalaxyGold || isCrownGold) && useScreenWidth
                ? style.fullWidth
                : '',
              'container-content'
            ]}
          >
            {this.$slots?.default}
          </div>
        </div>
        {/* <PortalTarget name={GlobalConst.PortalName.DownloadAppCyanBlue} /> */}
      </div>
    )
  }
}
