import { Model, parse } from 'typox'

export class ModelExtensions {
  /**
   * 将一个数组的源数据解析为实体类数组
   * @param sourceArray 源数据数组
   * @param model 解析方法的模型
   * @returns 返回构造好的实体类数组
   */
  static parseArray<T extends Model>(
    sourceArray: unknown[],
    model: new () => T
  ): T[] {
    return sourceArray.map((sourceItem) => new model().parse(sourceItem))
  }
}

/**
 * 处理数组数据类型的装饰器
 * @param model 用于解析值的Model子类的构造函数
 * @returns 能够处理与model类匹配的数组模型类型的装饰器
 */
export const parseArray = (model: new () => Model) => {
  return parse((value: unknown) =>
    Array.isArray(value) ? value.map((item) => new model().parse(item)) : []
  )
}

/**
 * 将Number转换为boolean
 */
export const numberToBool = (value: number) => Boolean(value)
