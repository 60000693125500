export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: '系統錯誤',
        NETWORK_ERROR: '網絡連接失敗，請切換網絡後再次嘗試',
        RSP_DATA_NOT_JSON: '響應值格式錯誤',
        GEETEST_RESTRICTED: '操作頻繁，請{seconds}秒後再試'
      },
      tips: { title: '溫馨提示' }
    }
  }
}
