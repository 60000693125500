/* eslint-disable @typescript-eslint/no-explicit-any*/

import { Select } from 'ant-design-vue'
import { get, merge } from 'lodash'
import PerfectScrollbar from 'perfect-scrollbar'
import VueUtils from '@/utils/VueUtils'
import type { PluginObject } from 'vue'

function antDesignSelectScrollBarHack(this: any, destroy = false) {
  if (destroy) {
    this._lobbyHackPerfectScrollInstance &&
      this._lobbyHackPerfectScrollInstance.destroy()
    this._lobbyHackSelectPopupWatch && this._lobbyHackSelectPopupWatch()
    return
  }

  /**
   * 找到弹层实例
   */
  const basePopupCom = get(
    VueUtils.getChilds((vm: Vue) => {
      return vm.$options.name === 'Select'
    }, this),
    '0.selectTriggerRef'
  )
  /**
   * 手动添加visible的监听
   */
  this._lobbyHackSelectPopupWatch = this.$watch(
    () => basePopupCom.visible,
    (visible: boolean) => {
      this.$nextTick(() => {
        if (visible) {
          /**
           * 弹层被打开时若有 PerfectScrollbar 则更新，否则销毁
           */
          if (this._lobbyHackPerfectScrollInstance) {
            return this._lobbyHackPerfectScrollInstance.update()
          }
          const scrollParent = basePopupCom
            .getPopupDOMNode()
            .querySelector('.ant-select-dropdown-menu')
          scrollParent.style.position = 'relative'
          this._lobbyHackPerfectScrollInstance = new PerfectScrollbar(
            scrollParent
          )
        }
      })
    }
  )
}

const selectScrollBarHack = {
  mounted() {
    antDesignSelectScrollBarHack.call(this)
  },
  beforeDestroy() {
    antDesignSelectScrollBarHack.call(this, true)
  }
}

const plugin: PluginObject<Record<string, unknown>> = {
  install() {
    merge(Select, {
      mixins: [...(get(Select, 'mixins') || []), selectScrollBarHack]
    })
  }
}
export default plugin
