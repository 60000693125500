import './icons/icon-rewrite'
/**
 * 需要显式调用，否则新版网络层里的错误弹窗css不正确
 */
import 'ant-design-vue/es/message/style/index.css'
import { Modal } from 'ant-design-vue'
import ConfigProvider from './components/config-provider'
import InputHack from './components/input'
import SelectHack from './components/select'
import type { PluginObject } from 'vue'

/**用于ant-design-vue插件全局重写 */
const Ant = {
  install: (Vue) => {
    Vue.use(InputHack).use(Modal).use(ConfigProvider).use(SelectHack)
  }
} as PluginObject<Record<string, unknown>>

export default Ant
