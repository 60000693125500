export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'கணினி பிழை',
        NETWORK_ERROR:
          'பிணைய இணைப்பு தோல்வியடைந்தது, பிணையத்தை மாற்றிய பின் மீண்டும் முயற்சிக்கவும்',
        RSP_DATA_NOT_JSON: 'பதில் மதிப்பு வடிவம் தவறானது',
        GEETEST_RESTRICTED:
          'அடிக்கடி செயல்பாடுகள், {seconds}வினாடிகளில் மீண்டும் முயற்சிக்கவும்'
      },
      tips: { title: 'குறிப்புகள்' }
    }
  }
}
