import { Base, Component } from '@/vue-property-decorator'
import { useMainStore } from '@/store/index'

@Component<OssAssets>({
  name: 'OssAssets'
})
export default class OssAssets extends Base {
  /**
   * 需要检测的目标资源路径
   */
  protected get ossTargetSrc() {
    return ''
  }
  protected get ossDomain() {
    const { ossDomain } = useMainStore()
    return ossDomain
  }

  protected get assetsPathOrigin() {
    const { assetsPathOrigin } = useMainStore()
    return assetsPathOrigin
  }

  /** 兼容domain可能是对象的场景,至少让程序先不报错 */
  private handleDomain(domain: string) {
    let src = ''
    if (typeof this.ossTargetSrc === 'string') {
      src = this.ossTargetSrc
    }
    return src.startsWith(domain)
  }
  /**
   * 是否为oss资源
   */
  protected get ossAssetsHost() {
    return this.ossDomain.find(this.handleDomain)
  }

  /**
   * 是否为oss资源中/lobby_asset 资源 （皮肤资源）
   */
  protected get ossLobbyAssetPathOrigin() {
    const { ossSkinOrigin } = useMainStore()
    return ossSkinOrigin.find(this.handleDomain)
  }

  /**
   * oss公共桶域名列表
   */
  protected get ossCommonDomain() {
    const { ossCommonDomain } = useMainStore()
    return ossCommonDomain
  }

  /**
   * 是否为oss公共桶资源
   */
  protected get ossCommonAssetsHost() {
    return this.ossCommonDomain?.find(this.handleDomain)
  }
}
