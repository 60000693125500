import { BrowserType, getBrowserType } from './const'
import { getGameOsType } from '@/views/game/utils'
import { osType } from '@/views/game/consts'
const browserType = getBrowserType()

/**支持桌面快捷方式的浏览器 */
export const supportShortcutBrowser = () => {
  // 设备的系统
  const deviceOS = getGameOsType()
  let isSupport = false
  if (deviceOS === osType.IOS) {
    isSupport = browserType === BrowserType.IsSafari
  } else if (deviceOS === osType.Android) {
    if (
      [
        BrowserType.IsChrome,
        BrowserType.IsEdge,
        BrowserType.IsFirefox,
        BrowserType.IsHiBrowser,
        BrowserType.IsNaver,
        BrowserType.IsDuckDuckGo
      ].includes(browserType)
    ) {
      isSupport = true
    }
  }
  return isSupport
}
