import {
  // Affix,
  Breadcrumb,
  Button,
  Carousel,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Drawer,
  Dropdown,
  Empty,
  FormModel,
  Icon,
  Input,
  InputNumber,
  Layout,
  List,
  Menu,
  Modal,
  Pagination,
  // Popconfirm,
  Popover,
  Progress,
  Radio,
  Row,
  Select,
  // Skeleton,
  Slider,
  Space,
  Spin,
  Steps,
  Switch,
  Table,
  Tabs,
  // Tag,
  Tooltip
  // Upload
} from 'ant-design-vue'
import { ExtractClassProps, IconType } from '../type'
import { FormModelItem } from 'ant-design-vue/types/form-model/form-item'
import { PropsHack as InputPasswordPropsHack } from '../components/input'
import { Merge } from 'type-fest'
import { merge } from 'lodash'
import { ofType } from '@/vue-tsx-support'
import CustomButton from '../components/button'
import type { RuleItem } from 'async-validator'

type InputProps = Input & { loading: boolean }
const InputTsx = merge(Input, {
  Group: merge(Input.Group, {
    Tsx: ofType<ExtractClassProps<typeof Input.Group>>().convert(Input.Group)
  }),
  Search: merge(Input.Search, {
    Tsx: ofType<ExtractClassProps<InputProps & typeof Input.Search>>().convert(
      Input.Search
    )
  }),
  TextArea: merge(Input.TextArea, {
    Tsx: ofType<ExtractClassProps<typeof Input.TextArea>>().convert(
      Input.TextArea
    )
  }),
  Password: merge(Input.Password, {
    Tsx: ofType<
      ExtractClassProps<
        InputProps & typeof Input.Password & InputPasswordPropsHack
      >
    >().convert(Input.Password)
  }),

  Tsx: ofType<ExtractClassProps<InputProps>>().convert(Input)
})
const ButtonTsx = merge(Button, {
  Tsx: ofType<
    ExtractClassProps<
      Omit<Button, 'type'> & {
        type:
          | 'link'
          | 'primary'
          | 'danger'
          | 'dashed'
          | 'ghost'
          | 'default'
          | 'finance'
          | 'success'
      }
    >
  >().convert(CustomButton)
})
const CheckboxTsx = merge(Checkbox, {
  Group: merge(Checkbox.Group, {
    Tsx: ofType<ExtractClassProps<typeof Checkbox.Group>>().convert(
      Checkbox.Group
    )
  }),
  Tsx: ofType<ExtractClassProps<Checkbox>>().convert(Checkbox)
})
const DatePickerTsx = merge(DatePicker, {
  RangePicker: merge(DatePicker.RangePicker, {
    Tsx: ofType<ExtractClassProps<typeof DatePicker.RangePicker>>().convert(
      DatePicker.RangePicker
    )
  }),
  Tsx: ofType<ExtractClassProps<DatePicker>>().convert(DatePicker)
})
const IconTsx = merge(Icon, {
  Tsx: ofType<
    ExtractClassProps<Omit<Icon, 'type'> & { type: IconType }>
  >().convert(Icon)
})
export type ModalProps = ExtractClassProps<Modal> & {
  keyboard?: boolean
  dialogStyle?: object
  /**执行额外的方法，当弹出框被关闭前执行 */
  extraCallbackBeforeClose?: () => void
}
export type ModalEmits = {
  ok: Event
  cancel: Event
}
const ModalTsx = merge(Modal, {
  Tsx: ofType<
    ModalProps,
    EmitsToPrefixedEvents<ModalEmits>,
    Record<string, unknown>,
    ModalEmits
  >().convert(Modal)
})
const PaginationTsx = merge(Pagination, {
  Tsx: ofType<ExtractClassProps<Pagination>>().convert(Pagination)
})

const LayoutTsx = merge(Layout, {
  Header: merge(Layout.Header, {
    Tsx: ofType<ExtractClassProps<typeof Layout.Header>>().convert(
      Layout.Header
    )
  }),
  Content: merge(Layout.Content, {
    Tsx: ofType<ExtractClassProps<typeof Layout.Content>>().convert(
      Layout.Content
    )
  }),
  Footer: merge(Layout.Footer, {
    Tsx: ofType<ExtractClassProps<typeof Layout.Footer>>().convert(
      Layout.Footer
    )
  }),
  Sider: merge(Layout.Sider, {
    Tsx: ofType<ExtractClassProps<typeof Layout.Sider>>().convert(Layout.Sider)
  }),
  Tsx: ofType<ExtractClassProps<Layout>>().convert(Layout)
})

const DrawerTsx = merge(Drawer, {
  Tsx: ofType<ExtractClassProps<Drawer>>().convert(Drawer)
})
const DropdownTsx = merge(Dropdown, {
  Tsx: ofType<ExtractClassProps<Dropdown>>().convert(Dropdown)
})
const SpaceTsx = merge(Space, {
  Tsx: ofType<ExtractClassProps<Space>>().convert(Space)
})
const MenuTsx = merge(Menu, {
  Item: merge(Menu.Item, {
    Tsx: ofType<ExtractClassProps<typeof Menu.Item>>().convert(Menu.Item)
  }),
  Tsx: ofType<ExtractClassProps<Menu>>().convert(Menu)
})

const RowTsx = merge(Row, {
  Tsx: ofType<
    ExtractClassProps<
      Omit<Row, 'type'> & {
        type: 'flex'
      }
    >
  >().convert(Row)
})
const ColTsx = merge(Col, {
  Tsx: ofType<ExtractClassProps<Col>>().convert(Col)
})

// const TagTsx = merge(Tag, {
//   Tsx: ofType<ExtractClassProps<Tag>>().convert(Tag)
// })
const TableTsx = merge(Table, {
  Tsx: ofType<ExtractClassProps<Table>>().convert(Table),
  Column: {
    Tsx: ofType<ExtractClassProps<typeof Table.Column>>().convert(Table.Column)
  }
})
const TabsTsx = merge(Tabs, {
  TabPane: {
    Tsx: ofType<ExtractClassProps<typeof Tabs.TabPane>>().convert(Tabs.TabPane)
  },
  Tsx: ofType<ExtractClassProps<Tabs>>().convert(Tabs)
})
export type FormRuleItem = Merge<
  RuleItem,
  { trigger?: Arrayed<'change' | 'blur'> }
>

type FormModelEmits = {
  validate: (prop: string, passed: boolean, error: string) => void
  submit: Event
}

const FormModelTsx = merge(FormModel, {
  Item: {
    Tsx: ofType<ExtractClassProps<FormModelItem>>().convert(FormModel.Item)
  },
  Tsx: ofType<
    ExtractClassProps<
      Omit<FormModel, 'rules'> & {
        rules: Record<string, Arrayed<FormRuleItem>>
      }
    >,
    EmitsToPrefixedEvents<FormModelEmits>,
    FormModelEmits
  >().convert(FormModel)
})

const EmptyTsx = merge(Empty, {
  Tsx: ofType<ExtractClassProps<Empty>>().convert(Empty)
})
const SliderTsx = merge(Slider, {
  Tsx: ofType<ExtractClassProps<Slider>>().convert(Slider)
})

const SelectTsx = merge(Select, {
  Option: {
    Tsx: ofType<ExtractClassProps<typeof Select.Option>>().convert(
      Select.Option
    )
  },
  Tsx: ofType<
    ExtractClassProps<
      Omit<Select, 'mode'> & {
        mode: 'combobox' | Select['mode']
      }
    >
  >().convert(Select)
})

const RadioTsx = merge(Radio, {
  Group: {
    Tsx: ofType<ExtractClassProps<typeof Radio.Group>>().convert(Radio.Group)
  },
  Button: {
    Tsx: ofType<ExtractClassProps<typeof Radio.Button>>().convert(Radio.Button)
  },
  Tsx: ofType<ExtractClassProps<Radio>>().convert(Radio)
})

const SpinTsx = merge(Spin, {
  Tsx: ofType<ExtractClassProps<Spin>>().convert(Spin)
})

const ConfigProviderTsx = merge(ConfigProvider, {
  Tsx: ofType<ExtractClassProps<ConfigProvider>>().convert(ConfigProvider)
})

const PopoverTsx = merge(Popover, {
  Tsx: ofType<ExtractClassProps<Popover>>().convert(Popover)
})
const ProgressTsx = merge(Progress, {
  Tsx: ofType<ExtractClassProps<Progress>>().convert(Progress)
})
// const PopconfirmTsx = merge(Popconfirm, {
//   Tsx: ofType<ExtractClassProps<Popconfirm>>().convert(Popconfirm)
// })

const StepsTsx = merge(Steps, {
  Tsx: ofType<ExtractClassProps<Steps>>().convert(Steps),
  Step: {
    Tsx: ofType<ExtractClassProps<typeof Steps.Step>>().convert(Steps.Step)
  }
})

const TooltipTsx = merge(Tooltip, {
  Tsx: ofType<ExtractClassProps<Tooltip>>().convert(Tooltip)
})

// const UploadTsx = merge(Upload, {
//   Tsx: ofType<ExtractClassProps<Upload>>().convert<Omit<Upload, 'data'>>(Upload)
// })

const InputNumberTsx = merge(InputNumber, {
  Tsx: ofType<ExtractClassProps<InputNumber>>().convert(InputNumber)
})

const BreadcrumbTsx = merge(Breadcrumb, {
  Tsx: ofType<ExtractClassProps<Breadcrumb>>().convert(Breadcrumb)
})
const SwitchTsx = merge(Switch, {
  Tsx: ofType<ExtractClassProps<Switch>>().convert(Switch)
})
const DividerTsx = merge(Divider, {
  Tsx: ofType<ExtractClassProps<Divider>>().convert(Divider)
})
const ListTsx = merge(List, {
  Tsx: ofType<ExtractClassProps<List>>().convert(List)
})
// const SkeletonTsx = merge(Skeleton, {
//   Tsx: ofType<ExtractClassProps<Skeleton>>().convert(Skeleton)
// })

// const AffixTsx = merge(Affix, {
//   Tsx: ofType<ExtractClassProps<Affix>>().convert(Affix)
// })

const CarouselTsx = merge(Carousel, {
  Tsx: ofType<ExtractClassProps<Carousel>>().convert(Carousel)
})

export { message, notification } from 'ant-design-vue'

export {
  InputTsx as Input,
  ButtonTsx as Button,
  CheckboxTsx as Checkbox,
  DatePickerTsx as DatePicker,
  DividerTsx as Divider,
  IconTsx as Icon,
  ModalTsx as Modal,
  PaginationTsx as Pagination,
  LayoutTsx as Layout,
  DrawerTsx as Drawer,
  DropdownTsx as Dropdown,
  MenuTsx as Menu,
  SpaceTsx as Space,
  // TagTsx as Tag,
  TableTsx as Table,
  RowTsx as Row,
  ColTsx as Col,
  TabsTsx as Tabs,
  FormModelTsx as FormModel,
  EmptyTsx as Empty,
  SliderTsx as Slider,
  SelectTsx as Select,
  RadioTsx as Radio,
  ConfigProviderTsx as ConfigProvider,
  SpinTsx as Spin,
  PopoverTsx as Popover,
  StepsTsx as Steps,
  TooltipTsx as Tooltip,
  ProgressTsx as Progress,
  // PopconfirmTsx as Popconfirm,
  // UploadTsx as Upload,
  InputNumberTsx as InputNumber,
  BreadcrumbTsx as Breadcrumb,
  SwitchTsx as Switch,
  ListTsx as List,
  // SkeletonTsx as Skeleton,
  // AffixTsx as Affix,
  CarouselTsx as Carousel
}
