import { Base, Component } from '@/vue-property-decorator'
import { fiberExecute } from '@/utils/Tool'

export default function (count = 10) {
  @Component<Defer>({
    name: 'MyDefer'
  })
  class Defer extends Base {
    private displayPriority = 0
    mounted() {
      this.runDisplayPriority()
    }
    private runDisplayPriority() {
      fiberExecute(
        Array.from({ length: count }).map(() => {
          this.displayPriority++
        }),
        'requestAnimationFrame'
      )
    }
    protected defer(priority: number) {
      return this.displayPriority >= priority
    }
  }

  return Defer
}
