export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'Sistem hatası',
        NETWORK_ERROR:
          'Ağ bağlantısı başarısız oldu, lütfen ağı değiştirdikten sonra tekrar deneyin',
        RSP_DATA_NOT_JSON: 'Yanıt değeri format hatası',
        GEETEST_RESTRICTED:
          'Sık işlem yapılıyor, lütfen {second} saniye içinde tekrar deneyin'
      },
      tips: { title: 'Ipuçları' }
    }
  }
}
