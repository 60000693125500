import { DirectDisplay } from '@/api/common/type'
import { useI18n } from '@/i18n'

export default class CheckPrompt {
  /**
   * 动态生成校验提示语 :6-16位，至少包含小写字母/数字/符号
   * @param length
   * @param lowercase
   * @param uppercase
   * @param num
   * @param special
   * @returns
   */
  static getPrompt(
    length: number,
    lowercase: DirectDisplay,
    uppercase: DirectDisplay,
    num: DirectDisplay,
    special: DirectDisplay
  ): string {
    const { t } = useI18n()
    let str = ''
    if (lowercase) {
      str += (str ? '/' : '') + t('lobby.common.formRules.lowercase')
    }
    if (uppercase) {
      str += (str ? '/' : '') + t('lobby.common.formRules.uppercase')
    }
    if (num) {
      str += (str ? '/' : '') + t('lobby.common.formRules.num')
    }
    if (special) {
      str += (str ? '/' : '') + t('lobby.common.formRules.special')
    }
    str =
      (length === 16 ? String(length) : `${length ?? 6}-16`) +
      (t('lobby.common.formRules.atLeast') as string) +
      str

    return str
  }
}
