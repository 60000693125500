export const MomentLanguageMap = {
  zh_CN: 'zh-cn',
  zh_TW: 'zh-tw',
  th_TH: 'th',
  vi_VN: 'vi',
  ko_KR: 'ko',
  ja_JP: 'ja',
  pt_PT: 'pt',
  es_ES: 'es',
  de_DE: 'de',
  fr_FR: 'fr',
  it_IT: 'it',
  ru_RU: 'ru',
  my_MM: 'my',
  id_ID: 'id',
  hi_IN: 'hi',
  tl_PH: 'tl-ph',
  km_KH: 'km',
  te_IN: 'te',
  ta_LK: 'ta',
  mr_IN: 'mr',
  kn_IN: 'kn',
  tr_TR: 'tr',
  bn_BN: 'bn',
  pa_PA: 'pa'
}
