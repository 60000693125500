/**
 * 什么都不传时平替Math.random
 * @param max 控制最大值
 * @param floating 控制是否小数
 * @returns
 * @example random() // [0,1)
 */
export function random(max = 999999, floating = true): number {
  let value
  try {
    const randomBuffer = new Uint32Array(1)
    window.crypto.getRandomValues(randomBuffer)
    value = randomBuffer[0] / (0xffffffff + 1)
  } catch (error) {
    value = require('bignumber.js').random()
  }
  return floating ? value * max : Math.floor(value * max)
}
