/* eslint-disable @typescript-eslint/no-explicit-any*/
import { PatternCode } from './patternCode'
import { SelfOpertedId } from '@/views/game/consts/platforms'
import { useMainStore } from '../../../../../store/index/index'
import Skin, { ThemeSkinType } from '@/controller/Skin'

/**
平台：(不在热门下的)
默认素材 https://wtio1r-080-ddd-backup.oss-cn-hongkong.aliyuncs.com/game_pictures/p/EA/3/2/default.png
默认无log素材 https://wtio1r-080-ddd-backup.oss-cn-hongkong.aliyuncs.com/game_pictures/p/EA/3/2/default_unLogo.png
平台:(在热门下的)
自定义素材:https://wtio1r-080-ddd-backup.oss-cn-hongkong.aliyuncs.com/game_pictures/p/080/EA/9/1/custom_CNY.png
平台(热门图素材) https://wtio1r-080-ddd-backup.oss-cn-hongkong.aliyuncs.com/game_pictures/p/080/EA/hot/9/1/custom_CNY.png

子游戏:(在不在热门下的都一样)
自游戏默认:https://wtio1r-080-ddd-backup.oss-cn-hongkong.aliyuncs.com/game_pictures/g/EA/14/3/140001/default.png
自定义:https://wtio1r-080-ddd-backup.oss-cn-hongkong.aliyuncs.com/game_pictures/g/080/HZ/13/11/5001/custom.png
无logo的原图:https://xvo35z-024-ttt.oss-ap-southeast-1.aliyuncs.com/game_pictures/g/CL/103/3/1030001/default_unLogo.png
 */
/** 游戏类型 */
export enum GameType {
  /** 平台 */
  Platform = 'p',
  /** 个体游戏 */
  Individual = 'g'
}

/** 拼接的参数 */
export type CombineParams = {
  /** 卡片原始数据(用于方便调试,无实际作用) */
  cardData: any
  /** 渲染的数据(用于方便调试,无实际作用) */
  renderName: string
  /** 游戏类型 */
  gameType: GameType
  /** 平台id */
  platformId: number
  /** 游戏类别id */
  gameCategoryId: number
  /** 游戏类别 */
  gameId?: number
  /** 是否自定义图片 */
  isCustomIcon: boolean
  /** 是否美术上传的原始图片 */
  isOriginImg?: boolean
  /** 是否热门列表下的平台
   * 如果是单个游戏的数据结构,类型又写的平台,则一定是热门列表下的平台类型,此种数据路径要单独拼接一个hot
   */
  isHotListPlatform?: boolean
}

/** 拼装地址 */
export class PictureUrlCore {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
  private static instance: PictureUrlCore
  private static getInstance() {
    if (!this.instance) {
      this.instance = new PictureUrlCore()
    }
    return this.instance
  }

  /** 对外暴露的静态方法,用来将卡片数据解析成资源路径 */
  public static run(combineParams: CombineParams) {
    /** 生成最终的图片资源路径 */
    return this.getInstance().generateIconPath(combineParams)
  }

  /** 图标形状代号控制器 */
  private patternCode = new PatternCode()
  /** 站点code */
  private siteCode = useMainStore().siteCode
  /** 图片资源域名地址 */
  private ossHost = useMainStore().ossHost

  /** 获得站点信息拼接字符串 */
  private getSiteCodeStr(params: CombineParams) {
    const { isCustomIcon, isOriginImg, gameType } = params
    if (gameType === GameType.Individual) {
      // 如果是子游戏,并且是自定义,则拼接站点,否则其它情况都不拼接.
      if (isCustomIcon) {
        return `/${this.siteCode}`
      } else {
        return ''
      }
    }
    // 如果是平台,[自定义||非美术原图]需要拼接站点,否则也不拼
    if (isCustomIcon || !isOriginImg) {
      return `/${this.siteCode}`
    } else {
      return ''
    }
  }

  /** 获得热门信息拼接字符串 */
  private getPlatformHotStr(params: CombineParams) {
    const { isHotListPlatform } = params
    return isHotListPlatform ? '/hot' : ''
  }

  /** 获得图片名称
   * @param patternCode 卡片类型
   */
  private getImgName(params: CombineParams, patternCode: string) {
    const { isOriginImg, isCustomIcon, gameType, platformId } = params
    const currency = useMainStore().userInfos?.currency
    /** 处理单个游戏 单个游戏不考虑拼接币种 */
    const handleIndividual = () => {
      if (isCustomIcon) {
        return 'custom.png'
      } else {
        // 如果是已经改造过的版式,游戏直接使用默认图片(有拼接上logo)
        if (this.reconstructSkin.includes(Skin.skinType as any)) {
          return 'default.png'
        } else {
          // 还未改造的版式,如果是【方型非WG的】卡片,需要拼接logo. 其它的所有卡片(WG的方型卡片,垂直卡片,横向卡片)都不拼装logo,由代码拼装.
          // patternCode==="CL" 表示【方型游戏卡片或方型热门平台(同方型游戏卡片走相同的渲染方式)】
          // platformId !== SelfOpertedId 表示【非WG的卡片】
          // 两个条件合起来就是 【方型非WG的游戏卡片】
          if (patternCode === 'CL' && platformId !== SelfOpertedId) {
            return 'default.png'
          } else {
            return 'default_unLogo.png'
          }
        }
      }
    }
    /** 处理平台游戏 平台游戏需要考虑币种的拼接 */
    const handlePlatform = () => {
      // 如果是[自定义图片]或[未使用美术默认图],则拼接上币种
      if (isCustomIcon) {
        return `custom_${currency}.png`
      } else {
        // 如果是已经改造过的版式,平台的逻辑上不考虑拼接unLogo
        if (this.reconstructSkin.includes(Skin.skinType as any)) {
          if (isOriginImg) {
            return 'default.png'
          } else {
            return `default_${currency}.png`
          }
        }
        // 如果是未改造过的版式,不管是不是美术原图,都要考虑带上unLogo
        else {
          if (isOriginImg) {
            return 'default_unLogo.png'
          } else {
            return `default_unLogo_${currency}.png`
          }
        }
      }
    }
    // 执行名称的生成
    if (gameType === GameType.Individual) {
      return handleIndividual()
    } else {
      return handlePlatform()
    }
  }

  /** 获得游戏id字符串 */
  private getGameIdStr(params: CombineParams) {
    const { gameId, gameType } = params
    // 如果是平台类型卡片,是没有游戏id的
    if (gameType === GameType.Platform) {
      return ''
    }
    return `/${gameId}`
  }

  /** 已重构的版式,目前只有经典,欧美,欧美简约 */
  private reconstructSkin = [
    ThemeSkinType.NORMAL,
    ThemeSkinType.EUROPEAN_AMERICAN,
    ThemeSkinType.STAKE
  ]

  /** 注:该函数不要删除,打印调试数据(仅在调试的时候打开它) */
  // private printDebuggingData(params: CombineParams) {
  //   // 调试时,在此处输入特定的名称,就能打印出相应的数据
  //   const checkNames = ['CQ9 Slots', 'WG Slots']
  //   if (
  //     checkNames.includes(params?.cardData?.gameName) ||
  //     checkNames.includes(params.cardData.platName)
  //   ) {
  //     console.warn(
  //       `printCardData-------------------------55555555`,
  //       JSON.parse(JSON.stringify(params))
  //     )
  //   }
  // }

  /** 生成成图片路径 */
  private generateIconPath(params: CombineParams) {
    const { gameType, gameCategoryId, platformId } = params
    /** 站点code字符串 '/080'或者'' */
    const siteCodeStr = this.getSiteCodeStr(params)
    /** 形状类型代号 EA|HZ等 */
    const patternCode = this.patternCode.run(params)
    /** 平台热门字符串 */
    const platformHotStr = this.getPlatformHotStr(params)
    /** 游戏id字符串 */
    const gameIdStr = this.getGameIdStr(params)
    /** 游戏名称 */
    const gameName = this.getImgName(params, patternCode)
    // 注:仅在调试的时候才开启该注释
    // this.printDebuggingData(params)
    let imgUrl = `${this.ossHost}game_pictures/${gameType}${siteCodeStr}/${patternCode}${platformHotStr}/${platformId}/${gameCategoryId}${gameIdStr}/${gameName}`
    // 此处是在拼装上相应的参数逻辑,方便排查
    imgUrl += '?----------'
    for (const key in params) {
      if (['cardData', 'renderName'].includes(key)) {
        continue
      }
      imgUrl += `${key}_${params[key as keyof typeof params]}------`
    }
    imgUrl += `skinType_${Skin.skinType}`
    return imgUrl
    // 标记日期2024年5月14号 相关的判断判断逻辑比较复杂,所以已经写入到每一个判断内了,不再单独定在下方了
    // // 区别的拼接改造完和未改造完的皮肤版式.该区别在最全版式改造完成后,将会删除
    // if (this.reconstructSkin.includes(Skin.skinType as any)) {
    //   return `${this.ossHost}game_pictures/${gameType}${siteCodeStr}/${patternCode}${platformHotStr}/${platformId}/${gameCategoryId}${gameIdStr}/${gameName}`
    // }
    // // 以下是未改造完的,那些定制版皮肤的拼装逻辑,将来全版式都改造完成以后,以下的代码应该都要删除掉,因此它们关于名称的判断逻辑,不写到前面的的名称分类下统一管理
    // else {
    //   if (isCustomIcon) {
    //     return `${this.ossHost}game_pictures/${gameType}${siteCodeStr}/${patternCode}${platformHotStr}/${platformId}/${gameCategoryId}${gameIdStr}/${gameName}`
    //   } else {
    //     // 是平台卡片,或者是自营游戏类型,就使用无logo的图片
    //     if (
    //       gameType === GameType.Platform ||
    //       gameCategoryId === SelfOpertedId
    //     ) {
    //       return `${this.ossHost}game_pictures/${gameType}${siteCodeStr}/${patternCode}${platformHotStr}/${platformId}/${gameCategoryId}${gameIdStr}/default_unLogo.png`
    //     }
    //     // 是非自营的子游戏,则使用拼装好logo的图片
    //     else {
    //       return `${this.ossHost}game_pictures/${gameType}${siteCodeStr}/${patternCode}${platformHotStr}/${platformId}/${gameCategoryId}${gameIdStr}/default.png`
    //     }
    //   }
    // }
  }
}
