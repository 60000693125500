/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
import {
  ActivityTemplate,
  ApiEventCustomize,
  CustomizeDisplayTypeEnum,
  CustomizeJumpTypeEnum,
  CustomizeOpenDeviceTypeEnum,
  CustomizeOpenTypeEnum
} from '@/api/event/type'
import { Dictionary, Location } from 'vue-router/types/router'
import { RouteName } from '@/router/config'
import { TabKey } from '@/layouts/components/tabBar/types'
import { apiEventGet } from '@/api/event'
import { merge } from 'lodash'
import { useMainStore } from '@/store/index'
import { useTabBarStore } from '@/layouts/components/tabBar/store'
import { windowOpenAfterQuery } from './business-utils/windowOpenAfterQuery'
import Manager from '@/context/manager'
import router from '@/router'
import { isSpecialIOS } from './UserAgent'
import { windowConfig } from './window'

// 创建一个包含当前 URL 参数的对象
export const getURLParameters = (url = window.location.href) =>
  (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
    (a: Record<string, string>, v: string) => (
      (a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a
    ),
    {}
  )

/**
 * 替换地址栏参数
 * @param {key,value}
 */
export const replaceRouteQuery = (
  query: Dictionary<string | (string | null)[]>,
  params: Record<string, string>
) => {
  let href = window.location.href
  const origin = href.split('?')[0]

  const routeObj = merge({}, getURLParameters(href)) as Record<string, string>

  for (const key in params) {
    routeObj[key] = params[key]
  }

  let isFirst = true,
    str = ''
  for (const key in routeObj) {
    str += `${isFirst ? '?' : '&'}${key}=${routeObj[key]}`
    isFirst = false
  }

  href = origin + str
  if (window.history) {
    // 支持History API
    window.history.replaceState(null, '', href)
  }

  merge(query, params)
}

export const toEventItemPage = async (
  jumpMethod: 'push' | 'replace',
  template: string,
  eventId: string,
  query?: Location['query'],
  callback?: () => void
) => {
  const { isWeb } = useMainStore()
  // 自定义活动跳转
  if ((Number(template) as ActivityTemplate) === ActivityTemplate.Customize) {
    let win!: Window | null
    if (isSpecialIOS && !windowConfig.isWgPackage) {
      win = window.open('', '_blank')
    }
    // 获取到当前自定义活动数据
    const { activeData } = await apiEventGet(Number(eventId))
    const { displayType, displayList } = activeData as ApiEventCustomize

    // 自定义活动展示方式 是否为跳转外链
    if (
      displayType === CustomizeDisplayTypeEnum.JumpOutsideLink &&
      displayList?.length
    ) {
      // 当前设备类型
      const deviceType = isWeb
        ? CustomizeOpenDeviceTypeEnum.PC
        : CustomizeOpenDeviceTypeEnum.Mobile

      const { openType, url, locationType } = displayList.find(
        (item) => item.deviceType === deviceType
      )!
      callback && callback()

      // 跳转类型
      if (locationType === CustomizeJumpTypeEnum.OutsideLink) {
        /** 判断是新窗口还是当前页，打开外链接 */
        openType === CustomizeOpenTypeEnum.NewWindow
          ? windowOpenAfterQuery(url, win)
          : (window.location.href = url)
      } else {
        // 根据后台配置，跳转内部其他页面路由
        handleJumpPageRoute(locationType)
      }
      return
    } else {
      win?.close()
    }
  }
  // 其他活动路由跳转
  router[jumpMethod]({
    name: RouteName.EVENT_ITEM,
    params: {
      template: template,
      eventId: eventId
    },
    query
  })
}

/**
 * 跳转内部页面路由公共方法
 */
const handleJumpPageRoute = (jumpType: CustomizeJumpTypeEnum) => {
  const mapper: {
    [key in CustomizeJumpTypeEnum]: (RouteName | TabKey | null)[]
  } = {
    [CustomizeJumpTypeEnum.OutsideLink]: [null, null],
    [CustomizeJumpTypeEnum.Activity]: [RouteName.EVENT, TabKey.ACTIVITY],
    [CustomizeJumpTypeEnum.Task]: [RouteName.TASK, TabKey.TASK],
    [CustomizeJumpTypeEnum.Recharge]: [null, TabKey.CANRECEIVE],
    [CustomizeJumpTypeEnum.Cashback]: [RouteName.CASHBACK, null],
    [CustomizeJumpTypeEnum.Proxy]: [RouteName.PROMOTE, TabKey.PROMOTE],
    [CustomizeJumpTypeEnum.Vip]: [RouteName.VIP, TabKey.VIP]
  }

  const { hasLogined, homeLayoutInfos } = useMainStore()
  const { updateTabItem } = useTabBarStore()
  const [name, key] = mapper[jumpType]

  // 当前路由是否是活动路由
  if (
    jumpType === CustomizeJumpTypeEnum.Activity &&
    router?.currentRoute?.name === RouteName.EVENT
  ) {
    return
  }

  // 打开充值弹窗
  if (jumpType === CustomizeJumpTypeEnum.Recharge) {
    if (Manager.Site.guide2DemoAcountDialog()) {
      return
    }
    Manager.Modal.open('payModal')
  }

  const bottomLayouts = hasLogined
    ? homeLayoutInfos?.loginAfterBottomLayouts
    : homeLayoutInfos?.loginBeforeBottomLayouts

  // 只有底部导航配置了对应功能，切换的时候才会更新底部高亮状态
  const currentItem = bottomLayouts?.find((item) => item.name === key)?.name

  let query = router.currentRoute?.query

  if (currentItem && key) {
    updateTabItem(key)
    query = {
      ...query,
      tabItem: key
    }
  }

  if (name) {
    router.push({
      name,
      query
    })
  }
}
