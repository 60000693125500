export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'Error de sistema',
        NETWORK_ERROR:
          'La conexión de red falló, intente nuevamente después de cambiar la red',
        RSP_DATA_NOT_JSON: 'El formato del valor de respuesta es incorrecto',
        GEETEST_RESTRICTED:
          'Operaciones frecuentes, vuelva a intentarlo en {segundos} segundos'
      },
      tips: { title: 'Consejos' }
    }
  }
}
