import * as MathUtils from '@/utils/MathUtils'
import { Currency } from '@/config/currencies.config'
import { isNumber, isPlainObject, isString, merge } from 'lodash'
import { parse as urlParse } from 'url'
import BigNumber from 'bignumber.js'
import qs from 'qs'

export interface CurrencyOptions {
  code?: string
  template?: string | { pos: string; neg?: string; zero?: string }
  symbol?: string
  separator?: string
  decimal?: string
  precision?: number //保留的小数位,默认2位
  groups?: RegExp
  useRounding?: boolean
  useForceTransform?: boolean
  max?: BigNumber.Value
  min?: BigNumber.Value
  minIntergerDigits?: number //最小整数位数
  withZero?: boolean //是否补零 默认 true
}

/**
 * 用于将一个数字或者字符串剔除特殊字符及重复小数点、零等
 * 转成合法的数字字符串
 */
export const legalNumberStrFormatter = (value: BigNumber.Value): string => {
  //转成字符串
  let strValue = MathUtils.toString(value)
  //以-开头的定义为负号，其它为空
  const symbol = /^-/.test(strValue) ? '-' : ''
  strValue = strValue
    .replace(/([^\d.])/g, '') //只保留数字和小数点
    .replace(/\./, 'd') //将第一个小数点用字母d占位置
    .replace(/\./g, '') //清理所有小数点
    .replace(/d/, '.') //还原第一个匹配到的小数点位置
    .replace(/^\./, '0.') //如果小数点在首位，则替换.xx 为0.xx
    .replace(/^0+(\d+?\.?)/, '$1') //去除整数位重复0
    .replace(/(?:\.0*|(\.\d+?)0+)$/, '$1') //去除小数位重复0
  strValue = strValue || '0'
  return (Number(symbol + strValue) >= 0 ? '#' : '-#').replace('#', strValue)
}

/**
 *
 * @example
 * currencyFormatter(1888.345,{precision:2,symbol:'$',separator:','})
 * //=> $1,888.34
 * currencyFormatter(-1888.345,{precision:2,symbol:'$',separator:','})
 * //=> -$1,888.34
 * currencyFormatter(1888.345,{precision:2,symbol:'₫',separator:',',template:'%v%s'})
 * //=> 1,888.34₫
 * currencyFormatter(-1888.345,{precision:2,symbol:'₫',separator:',',template:'%v%s'})
 * //=> -1,888.34₫
 * //使用code匹配内置的货币配置自动生成precision,symbol,separator,template等
 * currencyFormatter(-1888.345,{code:'CNY'})
 * //=> -¥1,888.34
 * currencyFormatter(-1888.345,{code:'CNY',template:{neg:'%s-%v'}})
 * //=> ¥-1,888.34
 */
export function currencyFormatter(
  value: BigNumber.Value,
  opts?: CurrencyOptions
): string {
  const {
    template,
    symbol,
    separator,
    decimal,
    precision,
    groups,
    useRounding,
    useForceTransform,
    max,
    min,
    minIntergerDigits,
    withZero
  } = Object.assign(
    {},
    currencyFormatter.defaultOpts,
    opts?.code ? currencyFormatter.createOptsByCode(opts.code) : {},
    opts
  )
  value = (useForceTransform ? legalNumberStrFormatter(value) : value) || 0
  value = new BigNumber(value)

  if (useForceTransform) {
    if (isNumber(max) || BigNumber.isBigNumber(max)) {
      value = BigNumber.minimum(max, value)
    }
    if (isNumber(min) || BigNumber.isBigNumber(min)) {
      value = BigNumber.maximum(min, value)
    }
  }

  //处理精度
  const scale = Math.pow(10, precision)
  value = useRounding ? Math.round(Number(value) * scale) / scale : value

  //分开处理整数位和小数位
  const split = MathUtils.toString(value).replace(/^-/, '').split('.')
  let dollars = split[0],
    cents = split[1] || ''
  //截取
  if (precision <= cents.length) {
    cents = cents.slice(0, precision)
  }

  if (withZero) {
    //小数位补0
    while (precision > cents.length) {
      cents += '0'
    }
  }
  //整数位补0
  while (minIntergerDigits && dollars.length < minIntergerDigits) {
    dollars = '0' + dollars
  }

  const templateConfig = currencyFormatter.createTemplateConfig(template)
  const useFormat = (() => {
    if ((value as number) > 0) return templateConfig.pos
    if ((value as number) < 0) return templateConfig.neg
    return templateConfig.zero
  })()

  return useFormat
    .replace('%s', symbol)
    .replace(
      '%v',
      dollars.replace(groups, '$1' + separator) + (cents ? decimal + cents : '')
    )
}

currencyFormatter.defaultOpts = {
  template: '%s%v',
  symbol: '',
  separator: '',
  decimal: '.',
  precision: 2,
  groups: /(\d)(?=(\d{3})+\b)/g,
  useRounding: false,
  useForceTransform: true,
  currenciesConfig: null,
  withZero: true
}

currencyFormatter.createTemplateConfig = function (
  template: CurrencyOptions['template'],
  negativeFirst = true
): { pos: string; neg: string; zero: string } {
  const defaults = currencyFormatter.defaultOpts.template
  // 创建正数/负数/0的格式化模板
  const createConfig = (pos: string, negativeFirst_ = negativeFirst) => {
    pos = pos || defaults
    return {
      pos,
      neg: negativeFirst_
        ? '-' + pos.replace('-', '')
        : pos.replace('-', '').replace('%v', '-%v'),
      zero: pos
    }
  }

  if (isString(template) && template.match('%v')) {
    return createConfig(template)
  } else if (isPlainObject(template) && template instanceof Object) {
    return {
      ...createConfig(defaults),
      ...createConfig(template.pos),
      ...template
    }
  }
  return createConfig(defaults)
}

currencyFormatter.createOptsByCode = function (
  currencyCode: CurrencyOptions['code']
) {
  const currencies = Currency.currenciesFormatConfig()
  const config = Object.values(currencies).find(
    (it) => it.code === currencyCode
  )
  return config
    ? (() => {
        return {
          decimal: config.decimalSeparator,
          separator: config.thousandsSeparator,
          symbol: config.symbol,
          template: currencyFormatter.createTemplateConfig(
            (config.symbolOnLeft ? '%s%b%v' : '%v%b%s').replace(
              '%b',
              config.spaceBetweenAmountAndSymbol ? ' ' : ''
            ),
            config.negativeFirst
          )
        }
      })()
    : {}
}

export const numberOnlyFormatter = (
  v: string | number,
  opts?: { maxLength: number }
) => {
  v = String(v)
  const { maxLength } = Object.assign(
    {
      maxLength: String(v).length
    },
    opts
  )
  return v.replace(/[^\d]/g, '').slice(0, maxLength)
}

/**
 * url 格式化
 * @example
 * @deprecated  因此FormatterUtils耦合了store中的状态导致其它模块引用此方法会循环引用
 * 故用 Tools.ts下的urlFormatter方法代替
 * urlFormatter('abc/cde',{gg:3,dd:4})
 * // 'abc/cde?gg=3&dd=4'
 * urlFormatter('abc/cde?xx=5',{gg:3,dd:4})
 * // 'abc/cde?xx=5&gg=3&dd=4'
 */
export const urlFormatter = (
  url: string,
  query?: Record<string, unknown>,
  cache = true
): string => {
  const { query: defaultQuery = '', search = '' } = urlParse(url, true)
  const prefix = search ? url.split(search)[0] : url
  const queryMerged = merge(
    qs.parse(defaultQuery as string),
    query,
    !cache ? { _t: +new Date() } : {}
  )
  return prefix + '?' + qs.stringify(queryMerged)
}

/**
 * 文件大小 格式化
 * @example
 * fuleSizeFormatter(4581430)
 */
export const fuleSizeFormatter = (value: number): string => {
  const unitArr = ['B', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']

  const src = parseFloat(value + '')
  const index = Math.floor(Math.log(src) / Math.log(1024))

  let size = src / Math.pow(1024, index)
  size = parseInt(size + '')

  return size + unitArr[index]
}

/**
 * 金额数值格式化
 * @example
 * fuleSizeFormatter(4581430)
 */
export const amountFormatter = (value: number): string => {
  const unitArr = ['K', 'M', 'B', 'T']

  const src = parseFloat(value + '')
  const index = Math.floor(Math.log(src) / Math.log(1000))

  let size = src / Math.pow(1000, index)
  size = parseInt(size + '')

  return size + unitArr[index]
}
