import { merge } from 'lodash'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import moment, { MomentInput } from 'moment'

export type DateType = MomentInput

export interface DateOptions {
  locale?: string
  utcOffset?: number | string
  secondsIn?: boolean
  secondsOut?: boolean
  isStart?: boolean
  isEnd?: boolean
  showToday?: boolean
  showYesterday?: boolean
  /** 获取之前的时间 传7就获取7天前时间 */
  daysAgo?: number
}
class DateUtils {
  static moment = moment
  /**
   * 获取本地时区偏差值
   */
  static get localUtcOffset() {
    return moment().utcOffset()
  }

  // 获取本地时区偏差小时值
  static get utcOffsetOfHour() {
    const offsetOfHour = this.localUtcOffset / 60
    return (offsetOfHour > 0 ? '+' : '') + offsetOfHour
  }

  static create(date: DateType = new Date(), seconds = false) {
    if (typeof date === 'number') {
      date = seconds ? moment.unix(date) : date
    }
    return moment(date)
  }

  /**
   * 内部方法换算时默认使用的时区偏差值
   * 单位分钟
   */
  static get utcOffset() {
    return this.localUtcOffset
  }

  /**
   * 格式化偏差值为 分钟 数
   * "+08:00" 8 480均输出 480
   */
  static offsetFormat(utcOffset: number | string) {
    if (typeof utcOffset === 'string') {
      const matches = (utcOffset || '').match(/Z|[+-]\d\d(?::?\d\d)?/gi)
      if (matches === null) {
        throw Error('Parameter parse error !')
      }
      const chunk = matches[matches.length - 1] || []
      const parts = (chunk + '').match(/([\+\-]|\d\d)/gi) || ['-', 0, 0]
      const minutes = +(Number(parts[1]) * 60) + parseInt(String(parts[2]))
      if (minutes === 0) return 0
      return String(parts[0]) === '+' ? minutes : -minutes
    } else {
      if (Math.abs(utcOffset) < 16) utcOffset *= 60
    }

    return utcOffset
  }

  static calcOffSetFromLocal(
    utcOffset: DateOptions['utcOffset'] = this.utcOffset
  ) {
    return (this.offsetFormat(utcOffset) - this.localUtcOffset) * 60 * 1000
  }

  /**
   * 传任何moment支持的参数类型，根据options 转成时间戳
   * utcOffset指传入的时间的时区
   * @param  date 日期对象或者时间戳
   * @param options

   */
  static timestamp(date: DateType = new Date(), options?: DateOptions) {
    const { utcOffset, secondsIn, secondsOut } = merge(
      { utcOffset: this.utcOffset, secondsIn: false, secondsOut: true },
      options
    )
    date =
      secondsIn && typeof date === 'number' ? moment.unix(date) : moment(date)
    date = date.utcOffset(utcOffset)
    if (options?.daysAgo) {
      date = date.subtract(options?.daysAgo, 'days')
    }
    if (options?.isStart) date = date.startOf('day')
    if (options?.isEnd) date = date.endOf('day')
    return secondsOut ? date.unix() : +date
  }

  /**
   * @description: https://day.js.org/docs/en/display/format
   * @param date 日期对象或者时间戳
   * @param options utcOffset指输出的时间的时区
   */
  static format(
    date: DateType = new Date(),
    fmt = 'YYYY-MM-DD  HH:mm:ss',
    options?: DateOptions
  ) {
    const { locale, utcOffset, secondsIn, showToday, showYesterday } = merge(
      {
        locale: 'zh-cn',
        utcOffset: this.utcOffset,
        secondsIn: true,
        showToday: false,
        showYesterday: false
      },
      options
    )
    date =
      secondsIn && typeof date === 'number' ? moment.unix(date) : moment(date)
    date = date.utc().utcOffset(utcOffset)

    const { t } = useI18n()
    const today = moment().startOf('day')
    const yesterday = moment().subtract(1, 'day').startOf('day')

    if (showToday && date.isSameOrAfter(today)) {
      return `${t('lobby.center.components.dateRadio.today')} ${date
        .locale(locale)
        .format('HH:mm:ss')}`
    } else if (showYesterday && date.isSameOrAfter(yesterday)) {
      return `${t('lobby.center.components.dateRadio.yesterday')} ${date
        .locale(locale)
        .format('HH:mm:ss')}`
    } else {
      return date.locale(locale).format(fmt)
    }
  }

  /**
   * @description 获取指定时间的零点时间的时间戳[天]
   * @param date
   * @returns
   */
  static getZeroTimestamp(diff = 0, date: DateType = new Date()) {
    return moment(date).add(diff, 'd').startOf('day').unix()
  }

  /**获取某个时间的毫秒数 */
  static getTimeMillisecond(diff = 0, date: DateType = new Date()) {
    return moment(date).add(diff, 'd').startOf('day').valueOf()
  }

  /**
   * @description 获取指定时间的零点时间的时间戳[月]
   * @param date
   * @returns
   */
  static getZeroTimestampInMonth(diff = 0, date: DateType = new Date()) {
    return moment(date).add(diff, 'month').startOf('month').unix()
  }
  // 获取活动开始或结束时间
  static getFormatTime = (
    duration: number,
    FMT = 'HH:mm',
    useTimeZone = true
  ) => {
    const { siteInfos } = useMainStore()
    let todayStart = DateUtils.moment().startOf('days')
    if (siteInfos?.timeZone && useTimeZone) {
      // 获得服务的时区偏移(单位：分钟)
      const serverUtcOffset = moment().utcOffset(siteInfos.timeZone).utcOffset()
      todayStart = todayStart.add(this.utcOffset - serverUtcOffset, 'm')
    }
    return todayStart.add(duration, 's').format(FMT)
  }

  /**
   * 根据utc时间获取本地时间
   * @param time
   * @param fmt
   * @returns
   */
  static utcTimeToLocal(time: DateType, fmt = 'YYYY-MM-DD HH:mm:ss') {
    return moment.utc(time).local().format(fmt)
  }

  /**
   * 时间戳转换为 天 h:m:s
   *
   * */
  static convertTimestamp(timestamp: number) {
    const seconds = Math.floor(timestamp / 1000)
    const days = Math.floor(seconds / 86400)
    const hours = Math.floor((seconds % 86400) / 3600)
    const minutes = Math.floor(((seconds % 86400) % 3600) / 60)
    const remainingSeconds = ((seconds % 86400) % 3600) % 60

    return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: remainingSeconds
    }
  }

  /**
   * 根据utc时间获取本地时间
   * @param time
   * @returns
   */
  static getTimeconfig() {
    const weekList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const { siteInfos } = useMainStore()
    const utcOffset = siteInfos?.timeZone?.split(' ')[1]
    const momentTime = moment()
      .utcOffset(utcOffset as string)
      .toLocaleString()
    const week = weekList[moment(momentTime).weekday()]
    const weekIdx = moment(momentTime).weekday()
    const year = DateUtils.format(momentTime, 'YYYY/MM/DD', { utcOffset })
    const seconds = DateUtils.format(momentTime, 'HH:mm:ss', { utcOffset })
    return {
      year,
      week,
      weekIdx,
      seconds,
      utcOffset,
      utcOffsetOfHour: this.utcOffsetOfHour
    }
  }
}

export default DateUtils
