import { getCurrentDevice } from '@/utils/Device'
import { windowConfig } from './window'

const ua = navigator.userAgent

export const isFacebook = /FB_|FBAN|FBIOS|FBAV/i.test(ua)

export const isInstagram = /instagram/i.test(ua)

export const isWhatsapp = /whatsapp/i.test(ua)

export const isTelegram = /telegram/i.test(ua)

export const isMobile = /mobile/i.test(ua)

export const isIphone = /iphone/i.test(ua)

export const isSafari = /Safari\/([0-9\.]+)/.test(ua)

export const isLine = /line/i.test(ua)

export const isUcBrowser = /UCBrowser/i.test(ua)

export const isBrowserOpenRequired =
  isMobile && (isFacebook || isInstagram || isWhatsapp || isTelegram)

/** 是否为第三方app */
export const isThirdPartyApp =
  isLine || isFacebook || isInstagram || isUcBrowser

/** 是否为Android的第三方app */
export const isAndroidThirdPartyApp =
  isThirdPartyApp && getCurrentDevice().android()

/** 检查设备是否为iOS，并且不是Safari上或者是第三方app(马甲包不算) */
export const isSpecialIOS =
  getCurrentDevice().ios() &&
  (!isSafari || isThirdPartyApp) &&
  !windowConfig.isWgPackage
