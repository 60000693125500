import type CheckView from '@/components/check-view'
import type MyImg from '@/components/img'
interface Options {
  observerOptions: IntersectionObserverInit | undefined
}

type VmInstance = CheckView | MyImg

type ListenerQueue = {
  vm: VmInstance
  options: {
    callback: () => void
    /**
     * leaveCallback & keepObserver
     * ToDo:
     * 开启后滚动时离开视口后会调用leaveCallback回调
     */
    leaveCallback?: () => void
    keepObserver?: boolean
  }
  loaded: boolean
  show: boolean
}

export default class Lazy {
  listenerQueue: ListenerQueue[] = []
  observer: IntersectionObserver | null = null
  options: Options = {
    observerOptions: {
      rootMargin: '0px',
      threshold: 0
    }
  }
  constructor({ observerOptions }: Partial<Options>) {
    this.options = {
      observerOptions
    }
    this.init()
  }
  private init() {
    this.initIntersectionObserver()
  }

  private isIntersecting(rect1: DOMRect, rect2: DOMRect) {
    const calcPoint = (rect: DOMRect) => {
      return {
        x1: rect.x,
        y1: rect.y,
        x2: rect.x + rect.width,
        y2: rect.y + rect.height
      }
    }
    const point1 = calcPoint(rect1)
    const point2 = calcPoint(rect2)
    if (
      point1.x2 < point2.x1 ||
      point1.x1 > point2.x2 ||
      point1.y2 < point2.y1 ||
      point1.y1 > point2.y2
    ) {
      return false
    }
    return true
  }

  private initIntersectionObserver() {
    if (!window.IntersectionObserver) return
    const defaultRootBounds = new DOMRect(
      0,
      0,
      window.innerWidth,
      window.innerHeight
    )
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(({ boundingClientRect, rootBounds, target }) => {
        if (
          this.isIntersecting(
            boundingClientRect,
            rootBounds || defaultRootBounds
          )
        ) {
          this.listenerQueue.forEach((listener) => {
            if (listener.vm.$el === target) {
              if (listener.loaded && !listener.options.keepObserver) {
                return this.removeComponent(listener.vm)
              }

              listener.loaded = true
              listener.show = true
              listener.options.callback()
            }
          })
        } else {
          this.listenerQueue.forEach((listener) => {
            if (listener.vm.$el === target) {
              if (listener.loaded && listener.options.keepObserver) {
                listener.show = false
                listener.options?.leaveCallback?.()
              }
            }
          })
        }
      })
    }, this.options.observerOptions)

    if (this.listenerQueue.length) {
      this.listenerQueue.forEach((listener) => {
        this.observer?.observe(listener.vm.$el)
      })
    }
  }

  public addLazyBox(vm: VmInstance, options: ListenerQueue['options']) {
    this.removeComponent(vm)
    this.listenerQueue.push({
      vm,
      options: {
        callback: options.callback,
        keepObserver: options.keepObserver ?? false
      },
      loaded: false,
      show: false
    })
    this.observer?.observe(vm.$el)
  }
  removeComponent(vm: VmInstance) {
    if (!vm) return
    const index = this.listenerQueue.findIndex((it) => it.vm === vm)
    if (index > -1) {
      this.listenerQueue.splice(index, 1)
      this.observer?.unobserve(vm.$el)
    }
  }
}
