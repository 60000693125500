import { Entity, Model, from, nullable, parse, type } from 'typox'
import { gameOssDomin } from './util/util'
import { numberToBool } from '@/service/extensions'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any
/** 主播热门的接口 */
export interface AnchorHotInter {
  /** web端使用的唯一id */
  webId: string
  /** 平台 id */
  platformId: number
  /** 游戏类别id */
  gameCategoryId: number
  /** 游戏名称 */
  gameName: string
  /** 游戏打开方式 0-内嵌, 1-外链 */
  isExternalLink: boolean
  /** 外部链接 */
  externalLink: string
  /** 游戏图标 */
  gameIcon: string
  /** 是否为主播热门 */
  isAnchorHot: true
  /** 自定义图片 */
  customLargeIcon: boolean
}

@Entity()
export default class AnchorHot extends Model implements AnchorHotInter {
  /** 类型守卫 */
  public static isThisType(data: Any): data is AnchorHot {
    return data.isAnchorHot
  }
  /** 生成唯一Id */
  public static generateWebId(data: AnchorHot) {
    const { gameCategoryId, platformId } = data
    return `${gameCategoryId}_${platformId}`
  }
  /** web端使用的唯一id */
  webId = ''

  /** 平台 id */
  @from('a')
  @type(Number)
  platformId = 0

  /** 游戏类别id */
  @from('b')
  @type(Number)
  gameCategoryId = 0

  /** 游戏名称 */
  @from('c')
  @type(String)
  gameName = ''

  /** 游戏打开方式 0-内嵌, 1-外链 */
  @from('d')
  @parse(numberToBool)
  @type(Boolean)
  @nullable()
  isExternalLink = false

  /** 外部链接 */
  @from('e')
  @type(String)
  @nullable()
  externalLink = ''

  /** 游戏图标 */
  @from('f')
  @parse(gameOssDomin)
  @type(String)
  @nullable()
  gameIcon = ''

  /** 是否为主播热门 */
  isAnchorHot: true = true

  /** 自定义图片 */
  customLargeIcon = false
}
