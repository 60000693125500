type RGBColor = {
  r: number
  g: number
  b: number
}

type HSVColor = {
  h: number
  s: number
  v: number
}

function hsvToRgb({ h, s, v }: HSVColor): RGBColor {
  h = Math.min(h % 360, 360)
  s = Math.min(s, 1)
  s = Math.max(0, s)
  v = Math.min(v, 1)
  v = Math.max(0, v)
  h = h / 360 // [0, 1]
  h = h < 1 ? h * 6 : 0 // [0, 6)
  const mod = Math.floor(h) // {0, 1, 2, 3, 4, 5}
  const f = h - mod
  const p = v * (1 - s)
  const q = v * (1 - f * s)
  const t = v * (1 - (1 - f) * s)
  const r = [v, q, p, p, t, v][mod]
  const g = [t, v, v, q, p, p][mod]
  const b = [p, p, t, v, v, q][mod]

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255)
  }
}

function rgbToHsv({ r, g, b }: RGBColor): HSVColor {
  r /= 255
  g /= 255
  b /= 255
  const min = Math.min(r, g, b)
  const max = Math.max(r, g, b)
  let h = Math.round(
    max === min
      ? 0
      : max === r
      ? 60 * ((g - b) / (max - min))
      : max === g
      ? 60 * (2 + (b - r) / (max - min))
      : max === b
      ? 60 * (4 + (r - g) / (max - min))
      : 0
  )
  if (h < 0) h += 360
  const s = max === 0 ? 0 : (max - min) / max
  const v = max

  return {
    h,
    s,
    v
  }
}

function rgbToHexString({ r, g, b }: RGBColor): string {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`
}

function hexStringToRgb(hex: string): RGBColor {
  // 移除十六进制颜色字符串前的"#"号，然后根据长度判断是否为简写形式
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  if (!result) {
    throw new Error('Invalid HEX color.')
  }

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  }
}

function hexStringToHsv(hex: string): HSVColor {
  const rgb: RGBColor = hexStringToRgb(hex)
  return rgbToHsv(rgb)
}

function hsvToHexString(hsv: HSVColor): string {
  const rgb: RGBColor = hsvToRgb(hsv)
  return rgbToHexString(rgb)
}

export default class ColorUtils {
  /**
   * AntDesign内部颜色阶梯变化自己实现,以减少@ant-design/colors/lib/generate 中 对 tinyColor库的引用
   */
  static generate(color: string): string[] {
    const hueStep = 2 // 色相阶梯
    const saturationStep = 16 // 饱和度阶梯，浅色部分
    const saturationStep2 = 5 // 饱和度阶梯，深色部分
    const brightnessStep1 = 5 // 亮度阶梯，浅色部分
    const brightnessStep2 = 15 // 亮度阶梯，深色部分
    const lightColorCount = 5 // 浅色数量，主色上
    const darkColorCount = 4 // 深色数量，主色下

    const getHue = (hsv: HSVColor, i: number, light?: boolean): number => {
      let hue: number
      // 根据色相不同，色相转向不同
      if (Math.round(hsv.h) >= 60 && Math.round(hsv.h) <= 240) {
        hue = light
          ? Math.round(hsv.h) - hueStep * i
          : Math.round(hsv.h) + hueStep * i
      } else {
        hue = light
          ? Math.round(hsv.h) + hueStep * i
          : Math.round(hsv.h) - hueStep * i
      }
      if (hue < 0) {
        hue += 360
      } else if (hue >= 360) {
        hue -= 360
      }
      return hue
    }

    const getSaturation = (
      hsv: HSVColor,
      i: number,
      light?: boolean
    ): number => {
      // grey color don't change saturation
      if (hsv.h === 0 && hsv.s === 0) {
        return hsv.s
      }
      let saturation: number
      if (light) {
        saturation = Math.round(hsv.s * 100) - saturationStep * i
      } else if (i === darkColorCount) {
        saturation = Math.round(hsv.s * 100) + saturationStep
      } else {
        saturation = Math.round(hsv.s * 100) + saturationStep2 * i
      }
      // 边界值修正
      if (saturation > 100) {
        saturation = 100
      }
      // 第一格的 s 限制在 6-10 之间
      if (light && i === lightColorCount && saturation > 10) {
        saturation = 10
      }
      if (saturation < 6) {
        saturation = 6
      }
      return saturation
    }

    const getValue = (hsv: HSVColor, i: number, light?: boolean): number => {
      if (light) {
        return Math.round(hsv.v * 100) + brightnessStep1 * i
      }
      return Math.round(hsv.v * 100) - brightnessStep2 * i
    }

    const patterns = [] as string[]

    try {
      const createPayload = (hsv: HSVColor, i: number, light?: boolean) => {
        return {
          h: getHue(hsv, i, light),
          s: getSaturation(hsv, i, light) / 100,
          v: getValue(hsv, i, light) / 100
        }
      }
      const hsv = hexStringToHsv(color)
      for (let i = lightColorCount; i > 0; i -= 1) {
        const payload = createPayload(hsv, i, true)
        const colorString = hsvToHexString(payload)
        patterns.push(colorString)
      }
      patterns.push(color)
      for (let i = 1; i <= darkColorCount; i += 1) {
        const payload = createPayload(hsv, i)
        const colorString = hsvToHexString(payload)
        patterns.push(colorString)
      }
    } catch (error) {
      for (let i = lightColorCount; i > 0; i -= 1) {
        patterns.push(color)
      }
      patterns.push(color)
      for (let i = 1; i <= darkColorCount; i += 1) {
        patterns.push(color)
      }
    }
    return patterns
  }
  static pad2(num: number) {
    let t = num.toString(16)
    if (t.length === 1) t = '0' + t
    return t
  }

  static lighten(colorStr: string, weight: number) {
    return ColorUtils.mix('fff', colorStr, weight)
  }

  static darken(colorStr: string, weight: number) {
    return ColorUtils.mix('000', colorStr, weight)
  }

  static mix(
    color1: string,
    color2: string,
    weight1?: number,
    alpha1?: number,
    alpha2?: number
  ) {
    color1 = ColorUtils.dropPrefix(color1)
    color2 = ColorUtils.dropPrefix(color2)
    if (weight1 === undefined) weight1 = 0.5
    if (alpha1 === undefined) alpha1 = 1
    if (alpha2 === undefined) alpha2 = 1

    const w = 2 * weight1 - 1
    const alphaDelta = alpha1 - alpha2
    const w1 =
      ((w * alphaDelta === -1 ? w : (w + alphaDelta) / (1 + w * alphaDelta)) +
        1) /
      2
    const w2 = 1 - w1

    const rgb1 = ColorUtils.toNum3(color1)
    const rgb2 = ColorUtils.toNum3(color2)
    const r = Math.round(w1 * rgb1[0] + w2 * rgb2[0])
    const g = Math.round(w1 * rgb1[1] + w2 * rgb2[1])
    const b = Math.round(w1 * rgb1[2] + w2 * rgb2[2])
    return '#' + ColorUtils.pad2(r) + ColorUtils.pad2(g) + ColorUtils.pad2(b)
  }

  static toNum3(colorStr: string) {
    colorStr = ColorUtils.dropPrefix(colorStr)
    if (colorStr.length === 3) {
      colorStr =
        colorStr[0] +
        colorStr[0] +
        colorStr[1] +
        colorStr[1] +
        colorStr[2] +
        colorStr[2]
    }
    const r = parseInt(colorStr.slice(0, 2), 16)
    const g = parseInt(colorStr.slice(2, 4), 16)
    const b = parseInt(colorStr.slice(4, 6), 16)
    return [r, g, b]
  }

  static dropPrefix(colorStr: string) {
    return colorStr.replace('#', '')
  }

  static getAntdSerials(color: string) {
    //即less的tint
    const lightens = new Array(9).fill(undefined).map((t, i) => {
      return ColorUtils.lighten(color, i / 10)
    })
    const colorPalettes = ColorUtils.generate(color)
    const rgb = ColorUtils.toNum3(color.replace('#', '')).join(', ')
    return lightens.concat(colorPalettes).concat(rgb)
  }
}
