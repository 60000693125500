class TimeUtils {
  toDouble(num: number) {
    if (num < 10) {
      return '0' + num
    } else {
      return '' + num
    }
  }

  parse(count: number) {
    if (count > 3600) {
      const hour = Math.floor(count / 60 / 60)
      const minute = Math.floor((count / 60) % 60)
      const second = Math.floor(count % 60)
      return `${this.toDouble(hour)}:${this.toDouble(minute)}:${this.toDouble(
        second
      )}`
    } else {
      const minute = Math.floor(count / 60)
      const second = Math.floor(count % 60)
      return `${this.toDouble(minute)}:${this.toDouble(second)}`
    }
  }

  showTimeIncrease(cb: (format: string) => unknown, bis = 0, speed = 1000) {
    cb(this.parse(bis))
    return window.setInterval(() => {
      cb(this.parse(bis))
      bis = bis + speed / 1000
    }, speed)
  }

  remainingTime(
    cb: (format: string) => unknown,
    bis: number,
    end = 0,
    speed = 1000
  ) {
    cb(this.parse(bis))
    const timer = window.setInterval(() => {
      cb(this.parse(bis))
      bis = bis - speed / 1000
      if (bis < end) {
        window.clearInterval(timer)
      }
    }, speed)
    return timer
  }
}

export default new TimeUtils()
