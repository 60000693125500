export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'Erreur système',
        NETWORK_ERROR:
          'La connexion réseau a échoué, veuillez réessayer après le changement de réseau',
        RSP_DATA_NOT_JSON: 'Le format de la valeur de réponse est incorrect',
        GEETEST_RESTRICTED:
          'Opérations fréquentes, veuillez réessayer dans {seconds} secondes'
      },
      tips: { title: 'Tips' }
    }
  }
}
