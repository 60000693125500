import { GlobalConst } from '@/context'
import { Language } from '.'
import { LocaleMessageObject } from 'vue-i18n'
import { loadScripts } from '@/utils/Loader'
/** 服务器返回的i18n数据格式  */
type I18nMsgRes = {
  // 主键id
  transId: number
  // 多语言的key
  transName: string
  // 多语言的value
  transContent: string
}

export const createServerMessageFactory = (lan: Language) => async () => {
  const serverLan = GlobalConst.ServiceLanguageMap[lan] || ''
  /**
   * 使用 import 在头部引用有循环引用的问题
   */
  const { apiGetI18nMessage } = require('@/api/common')
  const res = await apiGetI18nMessage(serverLan).catch(() => ({
    data: {
      data: []
    }
  }))
  const responseData: I18nMsgRes[] = res.data?.data || []
  const ret = responseData.reduce((pre, cur) => {
    const { transName, transContent } = cur
    pre[transName] = transContent
    return pre
  }, {} as Record<string, string>)
  // 返回时,再包装一层default
  return { default: ret }
}

export const createIndexDBMessageFactory =
  (lan: Language, storage: LocalForage) => async () => {
    const ret = storage.getItem(lan).catch(() => ({}))
    // 返回时,再包装一层default
    return { default: ret as unknown as LocaleMessageObject }
  }

declare global {
  interface Window {
    moment: unknown
  }
}

export const createMomentLocaleFactory = (lan: string) => async () => {
  /**
   * 若未外置提取，则需要按需node_modules中的包
   */
  if (!window.moment) {
    const map = {
      'zh-cn': () => import(`moment/locale/zh-cn`),
      'zh-tw': () => import(`moment/locale/zh-tw`),
      th: () => import(`moment/locale/th`),
      vi: () => import(`moment/locale/vi`),
      ko: () => import(`moment/locale/ko`),
      ja: () => import(`moment/locale/ja`),
      pt: () => import(`moment/locale/pt`),
      es: () => import(`moment/locale/es`),
      de: () => import(`moment/locale/de`),
      fr: () => import(`moment/locale/fr`),
      it: () => import(`moment/locale/it`),
      ru: () => import(`moment/locale/ru`),
      my: () => import(`moment/locale/my`),
      id: () => import(`moment/locale/id`),
      hi: () => import(`moment/locale/hi`),
      'tl-ph': () => import(`moment/locale/tl-ph`),
      km: () => import(`moment/locale/km`),
      tr: () => import(`moment/locale/tr`),
      bn: () => import(`moment/locale/bn`),
      pa: () => import(`moment/locale/pa-in`)
    }
    map[lan as keyof typeof map]?.()
  } else {
    /**
     * 生产模式moment在window上，直接调用cdn资源即可
     * 不等待后续下就可
     */
    loadScripts({
      src: `https://unpkg.com/moment@2.21.0/locale/${lan}.js`
    }).catch(() => {
      loadScripts({
        src: `/libs/moment@2.21.0/locale/${lan}.js`
      })
    })
  }
}
