import { Entity, Model, from, type } from 'typox'

@Entity()
export default class _FavoriteItem extends Model {
  constructor(source?: unknown) {
    super()
    this.merge(source)
  }

  /** 游戏id */
  @from('gameId')
  @type(Number)
  game = 0

  /** 平台id */
  @from('platformId')
  @type(Number)
  platform = 0
}
