export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'ಸಿಸ್ಟಮ್ ದೋಷ',
        NETWORK_ERROR:
          'ನೆಟ್‌ವರ್ಕ್ ಸಂಪರ್ಕ ವಿಫಲವಾಗಿದೆ, ದಯವಿಟ್ಟು ನೆಟ್‌ವರ್ಕ್ ಅನ್ನು ಬದಲಾಯಿಸಿದ ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ',
        RSP_DATA_NOT_JSON: 'ಪ್ರತಿಕ್ರಿಯೆ ಮೌಲ್ಯದ ಸ್ವರೂಪವು ತಪ್ಪಾಗಿದೆ',
        GEETEST_RESTRICTED:
          'ಆಗಾಗ್ಗೆ ಕಾರ್ಯಾಚರಣೆಗಳು, ದಯವಿಟ್ಟು {seconds}ಸೆಕೆಂಡ್‌ಗಳಲ್ಲಿ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ'
      },
      tips: { title: 'ಸಲಹೆಗಳು' }
    }
  }
}
