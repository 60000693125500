import { PingResult } from '@/api/finance/type'
import { get, delay as lodashDelay, merge } from 'lodash'
import { promisify as nodePromisify } from 'util'
import { useMainStore } from '@/store/index'
import ClipboardJS from 'clipboard'
import axios from 'axios'
import qs from 'qs'
import url from 'url'

/**
 * url 格式化 代替FormatterUtils.ts中的同名方法
 * @example
 * urlFormatter('abc/cde',{gg:3,dd:4})
 * // 'abc/cde?gg=3&dd=4'
 * urlFormatter('abc/cde?xx=5',{gg:3,dd:4})
 * // 'abc/cde?xx=5&gg=3&dd=4'
 */
export const urlFormatter = (
  urlString: string,
  query?: Record<string, unknown>,
  cache = true
): string => {
  const originUrlParse = url.parse(urlString, true)
  const queryMerged = merge(
    originUrlParse.query || {},
    query,
    !cache ? { _t: +new Date() } : {}
  )
  originUrlParse.query = queryMerged
  originUrlParse.search = `?${qs.stringify(queryMerged)}`

  return url.format(originUrlParse)
}

export function getStorage(key = 'app', storage = localStorage) {
  try {
    const string = storage.getItem(key)
    return string ? JSON.parse(string) : {}
  } catch (error) {
    return {}
  }
}

export function setStorage(item = {}, key = 'app', storage = localStorage) {
  try {
    const oldValue = getStorage(key, storage)
    const newValue = merge({}, oldValue, item)
    storage.setItem(key, JSON.stringify(newValue))
  } catch (error) {}
}

export function delay(wait: number) {
  if (wait <= 0) return
  return new Promise((reslove) => {
    lodashDelay(reslove, wait)
  })
}

export const promisify = nodePromisify

export const copyText = (text: string) => {
  ClipboardJS.copy(text)
}

/**
 * @description 读取剪切板内容
 */
export async function readClipboard() {
  return navigator.clipboard
    .readText()
    .then((text) => text)
    .catch((err) => Promise.reject(err))
}

export const ric =
  window.requestIdleCallback ||
  ((fn) => {
    setTimeout(fn, 0)
  })

export const raf =
  window.requestAnimationFrame ||
  ((fn) => {
    setTimeout(fn, 1000 / 60)
  })

export const caf =
  window.cancelAnimationFrame ||
  ((fn) => {
    clearTimeout(fn)
  })

export const isUrl = (url: string) => {
  return /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/.test(
    url
  )
}

/**
 * 分片执行任务列表，避免一次性执行造成 JS 阻塞
 * 优先使用 requestIdleCallback，不支持则使用 setTimeout
 * @param {Array<function>} tasks 任务列表
 */

export function fiberExecute(
  tasks: unknown[],
  mode: 'requestIdleCallback' | 'requestAnimationFrame' = 'requestIdleCallback'
) {
  const _tasks = [...tasks]
  const step = mode === 'requestAnimationFrame' ? raf : ric
  const fun = () => {
    const task = _tasks.shift()
    if (task) {
      typeof task === 'function' && task()
      if (_tasks.length) {
        step(fun)
      }
    }
  }
  step(fun)
}

/**
 *
 * @param opts 创建异步队列的函数
 * @returns
 */
export function createAsyncQueue(opts = { dedupe: false }) {
  const { dedupe } = opts
  let queue: (() => Promise<unknown>)[] = []
  let running!: Promise<unknown[]> | undefined
  let currentItem!: (() => Promise<unknown>) | undefined
  let next!: () => void
  const result: unknown[] = []
  const push = (...tasks: (() => Promise<unknown>)[]) => {
    tasks.forEach(async (task) => {
      if (dedupe) queue = []
      queue.push(task)
      if (!running) running = start()
      try {
        return await running
      } finally {
        running = undefined
      }
    })
    return context
  }

  const context = {
    /**
     * 当前的队列
     */
    queue,
    /**
     * 给队列添加
     */
    push,
    /**
     * 跳过当前项，执行下一项
     */
    next,
    /**
     * 重复当前项
     */
    repeat: () => {
      if (currentItem) {
        queue.unshift(currentItem)
        next()
        result.pop()
      }
    },
    /**
     * 开始执行
     */
    flush: () => running || Promise.resolve([])
  }

  const start = async () => {
    while (queue.length) {
      const item = queue.shift()
      currentItem = item
      item &&
        result.push(
          await new Promise((reslove) => {
            next = () => {
              reslove('')
            }
            item().then(() => {
              next()
            })
          })
        )
    }
    return result
  }

  return context
}

/**
 * 获取浏览器参数
 * @param name 关键字
 * @param url
 */
export const getQueryString = (
  name: string,
  url = window.location.href
): string | null => {
  const reg = new RegExp('(/?|/|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = url.substring(1).match(reg)
  if (r != null) {
    return decodeURIComponent(r[2])
  }
  return null
}

/**
 * 获取屏幕目标区域可用高度
 * @param otherHeight 屏幕内非目标区域并已知高度的总和，可为负数
 * @param unKnowHeightDom 屏幕内非目标区域未知高度的dom，数组，可传多个
 */
export const getTargetUsebaleHeight = (
  otherHeight = 0,
  unKnowHeightDom?: Array<HTMLElement>
): number => {
  const rootFontSize = getComputedStyle(
    document.documentElement
  )?.fontSize.replace('px', '')
  const relPx = (Number(rootFontSize) / 100) * otherHeight
  let unKnowDomHeight = 0
  if (unKnowHeightDom) {
    unKnowHeightDom.forEach((element) => {
      unKnowDomHeight += element.clientHeight
    })
  }
  // 视口高度 - 其他容器高度 - 未知高度的元素
  return document.body.offsetHeight + relPx - unKnowDomHeight
}

/**
 * 处理气泡
 */
export const getTooltipCount = (count: number) => {
  return (count || 0) > 99 ? '99+' : count
}

// fetch（超时版）
export const fetchUrl = (url: string, timeout?: number): Promise<Response> => {
  const controller = new AbortController()
  const signal = controller.signal

  const timeoutPromise = (timeout: number): Promise<Response> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          new Response('timeout', { status: 504, statusText: 'timeout ' })
        )
        controller.abort()
      }, timeout)
    })
  }
  const requestPromise = (url: string) => {
    return fetch(url, {
      signal: signal
    })
  }
  return Promise.race([timeoutPromise(timeout ?? 300), requestPromise(url)])
}

// 对指定URL的ping测试 返回ping的时间
export function ping(url: string): Promise<PingResult> {
  return new Promise((resolve) => {
    const startTime = new Date().getTime()
    // 域名需要添加ping接口，测试是否能调用通过才算ping通
    url = `${url}/ping`

    axios
      .get(url, {
        timeout: 5000
      })
      .then((response) => {
        if (response.status === 200) {
          const endTime = new Date().getTime()
          const pingTime = endTime - startTime
          const resultUrl = url.replace('/ping', '')
          resolve({ url: resultUrl, pingTime })
        }
      })
      .catch(() => {
        const resultUrl = url.replace('/ping', '')
        resolve({ url: resultUrl, pingTime: 9999 })
      })
  })
}

/**
 * @param promiseFn
 * @param times 总次数 如 3 就是 正常1次 重试2次
 * @returns
 */
export const retry = function <T>(
  promiseFn: (count: number) => Promise<T>,
  times = 2
): Promise<T> {
  return new Promise(async (resolve, reject) => {
    const total = times
    while (times--) {
      try {
        const ret = await promiseFn(total - times)
        resolve(ret)
        break
      } catch (error) {
        if (!times) reject(error)
      }
    }
  })
}

/**
 *
 * @param list 遍历的集合
 * @param promiseFn 请求体
 * @param minTimes 最小请求次数
 * @returns
 */
export const retryMap = function <T, I>(
  list: I[],
  promiseFn: (item: I) => Promise<T>,
  minTimes = 3
) {
  const times = Math.max(list.length, minTimes)
  return retry<T>((count) => {
    const item = list[count - 1] || list[list.length - 1]
    return promiseFn(item)
  }, times)
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cssRuleSelector = (selector: (cssRule: any) => boolean) => {
  return Array.prototype.filter.call(
    Array.prototype.concat.apply(
      [],
      Array.prototype.map.call(document.styleSheets, function (x) {
        return Array.prototype.slice.call(x.cssRules)
      })
    ),
    (x) => {
      return selector(x)
    }
  )
}

export const disabledModal = () =>
  getQueryString('mock.disabledModal') === 'true'

export const disabledSD = () => getQueryString('mock.disabledSD') === 'true'

export function formatMarquee(marquee: Element) {
  // 获取所有子元素
  const childElements = marquee.querySelectorAll(
    '*'
  ) as NodeListOf<HTMLDivElement>
  // 遍历每个子元素
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  childElements.forEach((element: any) => {
    //style只保留color 其他的都删掉
    element.setAttribute(
      'style',
      element.style.color ? `color: ${element.style.color}` : ''
    )
  })
}

/**
 * @description 网浏览器的地址栏中追加search参数
 * @param url 当前地址
 * @param appendSearchKey 需要追加的search的Key
 * @param appendSearchValue 需要追加的search的值
 */
export const appendSearchParamsByURL = (
  url: string,
  appendSearchKey: string,
  appendSearchValue: string | number
) => {
  const urlObject = new URL(url)
  urlObject.searchParams.append(appendSearchKey, String(appendSearchValue))
  return urlObject
}

export function resCodeMessage(error: unknown = {}) {
  let code = 0
  let msg = ''
  code = get(error, 'data.code', code)
  msg = get(error, 'data.msg', msg)
  return { code, msg }
}

/**
 * @description 替换Url中的domain部分通过新的域名
 * @example
 * url: 'https://baidu.com/a/b'
 * newDomain: 'https://google.com'
 * 结果为 'https://google.com/a/b'
 */
export const replaceUrlByNewDomain = (
  url: string,
  newDomain: string
): string => {
  const urlObject = new URL(url)
  const newUrlObject = new URL(newDomain)
  urlObject.hostname = newUrlObject.hostname
  urlObject.protocol = newUrlObject.protocol
  return urlObject.href
}

/**
 * @description 替换URL地址栏中的search部分，通过新的域名
 * @example
 * url: 'https://baidu.com/?a=1'
 * newDomain: 'https://google.com/?a=1&b=2'
 * 结果为 https://baidu.com/?a=1&a=1&b=2（说明此方法只是做了叠加，没有做唯一性处理）
 */
export const replaceUrlSearchByNewDomain = (
  oldUrl: string,
  newUrl?: string
): string => {
  const urlObject = new URL(oldUrl)
  const newUrlObject = new URL(newUrl ?? window.location.href)
  const newSearch = new URLSearchParams(newUrlObject.search)
  for (const [key, value] of newSearch) {
    urlObject.searchParams.append(key, value)
  }
  return urlObject.href
}

/**
 * @description 获取浏览器地址栏参数,并且转化为对象
 */
export const getSearchParamsAndTransformObjectByURL = (href?: string) => {
  const locationHref = href ?? window.location.href
  const url = new URL(locationHref)
  const search = new URLSearchParams(url.search)
  const searchParamsObject: Record<string, string> = {}
  for (const [key, value] of search) {
    searchParamsObject[key] = value
  }
  return searchParamsObject
}

/**
 * @description 获取注册用户的渠道cid
 */
export const getChannelCid = () => {
  const { userInfos } = useMainStore()
  if (!userInfos) {
    return ''
  }
  const { regPkgId } = userInfos
  return regPkgId
}
