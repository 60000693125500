/* eslint-disable no-console */

type TypeColor = Record<string, string>
type ConsoleMethod = (...args: unknown[]) => void
type ConsoleMethodName = 'log' | 'info' | 'warn' | 'error'

type OriginalMethods = Partial<Record<ConsoleMethodName, ConsoleMethod>>

class EnhancedConsole {
  private originalMethods: OriginalMethods = {}

  constructor(methodNames: ConsoleMethodName[]) {
    this.saveAndClearMethods(methodNames)
  }

  private saveAndClearMethods(methodNames: ConsoleMethodName[]): void {
    const consoleMatch = new URLSearchParams(window.location.search).get(
      'fixed.consoleMatch'
    )
    // 将 consoleMatch 字符串分割成数组，如果 consoleMatch 为空，则默认为空数组
    const matchKeywords = consoleMatch ? consoleMatch.split(',') : []
    methodNames.forEach((methodName) => {
      // 保存原始方法
      this.originalMethods[methodName] = console[methodName].bind(console)

      // 重写方法 如果传了 mock.consoleMatch 参数就按参数匹配
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      console[methodName] = ((...args: any[]) => {
        if (
          matchKeywords.length === 0 ||
          args.some((arg) =>
            matchKeywords.some((keyword) => String(arg).includes(keyword))
          )
        ) {
          this.originalConsole(methodName, ...args)
        }
      }) as ConsoleMethod
    })
  }

  // 使用原始逻辑执行 console 操作
  originalConsole(methodName: ConsoleMethodName, ...args: unknown[]): void {
    const originalMethod = this.originalMethods[methodName]
    if (originalMethod) {
      originalMethod(...args)
    } else {
      console[methodName](...args)
    }
  }
}

export default new (class Logger {
  constructor() {
    try {
      new EnhancedConsole(['log', 'info'])
    } catch (error) {
      /**
       *
       */
    }
  }

  typeColor: TypeColor = {
    primary: '#2d8cf0',
    success: '#19be6b',
    info: '#909399',
    warn: '#ff9900',
    error: '#f03f14',
    default: '#35495E'
  }

  print(type = 'default', text: unknown, back = false) {
    const color = this.typeColor[type] || type || this.typeColor.default
    const method = window.console[type as 'log'] ? type : 'log'
    if (typeof text === 'object') {
      Array.isArray(text) ? console.table(text) : console.dir(text)
      return
    }

    if (back) {
      this.console(method, [
        `%c ${text} `,
        `background:${color}; padding: 2px; border-radius: 4px; color: #fff;`
      ])
    } else {
      this.console(method, [
        `%c ${text} `,
        `border: 1px solid ${color};
              padding: 2px; border-radius: 4px;
              color: ${color};`
      ])
    }
  }

  printBack(type = 'primary', text: unknown) {
    this.print(type, text, true)
  }

  private pretty(type = 'primary', title: string, text: unknown) {
    const method = window.console[type as 'log'] ? type : 'log'
    if (typeof text === 'object') {
      console.group('Console Group', title)
      this.console(method, [
        `%c ${title}`,
        `background:${this.typeColor[type]};border:1px solid ${this.typeColor[type]};
            padding: 1px; border-radius: 4px; color: #fff;`
      ])
      Array.isArray(text) ? console.table(text) : console.dir(text)
      console.groupEnd()
      return
    }
    this.console(method, [
      `%c ${title} %c ${text} %c`,
      `background:${this.typeColor[type]};border:1px solid ${this.typeColor[type]};
          padding: 1px; border-radius: 4px 0 0 4px; color: #fff;`,
      `border:1px solid ${this.typeColor[type]};
          padding: 1px; border-radius: 0 4px 4px 0; color: ${this.typeColor[type]};`,
      'background:transparent'
    ])
  }

  primary(title: string, ...text: unknown[]) {
    text.forEach((t) => this.pretty('primary', title, t))
  }

  success(title: string, ...text: unknown[]) {
    text.forEach((t) => this.pretty('success', title, t))
  }

  warn(title: string, ...text: unknown[]) {
    text.forEach((t) => this.pretty('warn', title, t))
  }

  error(title: string, ...text: unknown[]) {
    text.forEach((t) => this.pretty('error', title, t))
  }

  info(title: string, ...text: unknown[]) {
    text.forEach((t) => this.pretty('info', title, t))
  }

  log(...text: unknown[]) {
    text.forEach((t) => this.pretty('log', 'default', t))
  }

  /**
   * 必须显式调用console.xx方法，
   * 以便构建层统一剔除代码
   */
  private console(method: string, logs: unknown[]) {
    switch (method) {
      case 'info':
        console.info(...logs)
        break
      case 'warn':
        console.warn(...logs)
        break
      case 'error':
        console.error(...logs)
        break
      default:
        console.log(...logs)
        break
    }
  }

  myDebug(title: string, ...text: unknown[]) {
    if (
      window.abcdefg_open ||
      window.location.search.includes('isDebug=true')
    ) {
      this.info(title, ...text)
    }
  }
})()
