import { useMainStore } from '@/store/index'

export interface Options {
  tiktokID?: string
}
export default class TiktokBaesCode {
  public options!: Options

  constructor(options: Options) {
    this.options = Object.assign({}, options)
    this.init()
  }

  public init(): void {
    console.log('initBuried init TiktokBaesCode: ', this.options)
    this.loadJs()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public pixelEventPush(eventName: string, params: object): void {
    const tiktokTrack = (eventNameFormat: { [key: string]: string }) =>
      window.ttq.track(eventNameFormat[eventName] || eventName, {
        ...params,
        content_id: new Date().getTime(),
        content_type: 'product'
      })
    /**  230  CKbet/326 Win2023/702 SEbet/722 DJbet 跑广告特殊走法 */
    const siteCode = useMainStore()?.siteConfig?.siteCode || ''
    if (['272', '230', '326', '702', '722'].includes(siteCode)) {
      const eventNameFormat: { [key: string]: string } = {
        register: 'CompleteRegistration',
        firstrecharge: 'CompletePayment',
        recharge: 'AddToCart'
      }
      tiktokTrack(eventNameFormat)
      return
    }
    if (['5060'].includes(siteCode)) {
      const eventNameFormat: { [key: string]: string } = {
        register: 'CompleteRegistration',
        rechargeClick: 'AddToCart',
        firstrecharge: 'CompletePayment',
        recharge: 'Subscribe'
      }
      tiktokTrack(eventNameFormat)
      return
    }
    if (['5571'].includes(siteCode)) {
      const eventNameFormat: { [key: string]: string } = {
        register: 'CompleteRegistration',
        rechargeClick: 'AddToCart',
        firstrecharge: 'Subscribe',
        recharge: 'CompletePayment'
      }
      tiktokTrack(eventNameFormat)
      return
    }
    /** 230 end */
    const eventNameFormat: { [key: string]: string } = {
      register: 'CompleteRegistration',
      rechargeClick: 'AddToCart',
      firstrecharge: 'CompletePayment',
      recharge: 'CompletePayment'
    }
    tiktokTrack(eventNameFormat)
  }

  private async loadJs() {
    document.querySelector('#tiktok-script')?.remove()
    const script = document.createElement('script')
    script.id = 'tiktok-script'
    script.type = 'text/javascript'
    script.appendChild(
      document.createTextNode(`
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;
        var ttq=w[t]=w[t]||[];
        ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],
        ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
        for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
        ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
        )ttq.setAndDefer(e,ttq.methods[n]);
        return e},
        ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";
        ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
        n=document.createElement("script");
        n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;
        e=document.getElementsByTagName("script")[0];
        e.parentNode.insertBefore(n,e);
        };
        ttq.load('${this.options.tiktokID}');
        ttq.page();
        ttq.track('ViewContent')
        }(window, document, 'ttq');
      `)
    )
    document.head.appendChild(script)
  }
}
