export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '该域名访问异常，请稍后重试',
        SYSTEM_ERROR: '系统错误',
        NETWORK_ERROR: '网络连接失败，请切换网络后再次尝试',
        RSP_DATA_NOT_JSON: '响应值格式错误',
        GEETEST_RESTRICTED: '操作频繁，请{seconds}秒后再试'
      },
      tips: {
        title: '温馨提示'
      }
    }
  }
}
