import { VNode } from 'vue'
import { VueTsx } from '@/vue-property-decorator'

/** 占位符替换成Dom元素 */
export class OccupierReplaceVNode extends VueTsx.Component<unknown> {
  public run(oriStr: string, occupied: string, element: VNode) {
    // 默认直接返回span字符串
    let retArr: VNode[] = [<span>{oriStr}</span>]
    // 如果正则匹配成功,则返回替换后的节点数组
    if (new RegExp(occupied).test(oriStr)) {
      const occupiedReg = new RegExp(`(${occupied})`, 'g')
      const oriStrArr = oriStr.split(occupiedReg).filter(Boolean)
      retArr = oriStrArr.reduce((arr, str) => {
        if (occupied === str) {
          arr.push(element)
        } else {
          arr.push(<span>{str}</span>)
        }
        return arr
      }, [] as VNode[])
    }
    return retArr
  }
}
const instance = new OccupierReplaceVNode()
/** 占位符替换成VNode */
export const occupierReplaceVNode = instance.run.bind(instance)
