export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'စနစ်အမှား',
        NETWORK_ERROR:
          'ကွန်ယက်ချိတ်ဆက်မှုမအောင်မြင်ပါ, ကွန်ယက်ကိုပြောင်းပြီးနောက်တစ်ကြိမ်ထပ်မံကြိုးစားပါ',
        RSP_DATA_NOT_JSON: 'တုံ့ပြန်မှု တန်ဖိုး ဖောမတ် မမှန်ပါ',
        GEETEST_RESTRICTED:
          'မကြာခဏလုပ်ဆောင်မှုများ၊ ကျေးဇူးပြု၍ {seconds} စက္ကန့်အကြာတွင် ထပ်စမ်းကြည့်ပါ။'
      },
      tips: { title: 'အကြံပြုချက်များ' }
    }
  }
}
