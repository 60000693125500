import localforage from 'localforage'
import type { PiniaPluginContext } from 'pinia'

export interface MyStorage {
  overwriteConfig?: Partial<MyStorage>
  withIndexDB?: boolean
  getItem(key: string, storage: MyStorage): string | null
  removeItem(key: string): void
  setItem(key: string, value: string, storage: MyStorage): void
}
export interface PersistStrategy {
  key?: string
  storage?: MyStorage
  paths?: string[]
}
interface Options {
  finalLocal: boolean
}
export class IndexDBStorage {
  public withIndexDB = true
  /**
   * 是否开启从localStorage里兜底，
   * 主要首次从localStorage迁移到indexDB时可开启避免丢失用户缓存
   */
  private options: Options = {
    finalLocal: true
  }
  constructor(state = {}, options?: Options) {
    this.options = Object.assign({}, this.options, options)
    this.state = state
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private state!: Record<string, any>

  public getItem(key: string) {
    return this.state
      ? JSON.stringify(this.state[key])
      : this.options.finalLocal
      ? localStorage.getItem(key)
      : null
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setItem(key: string, value: any) {
    this.state[key] = JSON.parse(value)
  }
  public removeItem(key: string) {
    delete this.state[key]
  }
  public clear() {
    this.state = {}
  }
}

class StorageWithIndexDB {
  private options = {
    keyFormatter: (id: string) => `store:${id}`,
    recordIndexDBKeyInLocalStorage: 'indexDBKey'
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private state: Record<string, any> = {}
  /**
   * indexDB中key存入localStorage时的key
   */
  private get indexDBKeyFromLocalStorageKey() {
    return this.options.recordIndexDBKeyInLocalStorage
  }
  private get createKey() {
    return this.options.keyFormatter
  }
  /**
   * 从 indexDB装载数据
   */
  public async setup(
    options: {
      keyFormatter?: (id: string) => string
      recordIndexDBKeyInLocalStorage?: string
    } = this.options
  ) {
    try {
      this.options = Object.assign({}, this.options, options)
      const keys = await localforage.keys().catch(() => [''])
      const indexDBKey = JSON.parse(
        localStorage.getItem(this.indexDBKeyFromLocalStorageKey) || '[]'
      )
      await Promise.all(
        indexDBKey
          .filter((id: string) => keys.includes(this.createKey(id)))
          .map((id: string) => this.setupItem(id))
      ).catch(() => {
        /**
         *
         */
      })
    } catch (error) {
      /**
       *
       */
    }
  }

  public updateIndexDB(id: string, value: string) {
    const key = this.createKey(id)
    localforage.setItem(key, value).then(() => {
      const indexDBKey = JSON.parse(
        localStorage.getItem(this.indexDBKeyFromLocalStorageKey) || '[]'
      )
      if (!indexDBKey.includes(id)) {
        indexDBKey.push(id)
      }
      localStorage.setItem(
        this.indexDBKeyFromLocalStorageKey,
        JSON.stringify(indexDBKey)
      )
    })
  }

  private getIndexDB(id: string) {
    return localforage.getItem(this.createKey(id))
  }

  public delete(id: string) {
    delete this.state[id]
  }

  private async setupItem(id: string) {
    try {
      const result = await this.getIndexDB(id)
      if (result) {
        this.state[id] = JSON.parse(result as string)
      }
    } catch (error) {}
  }

  public get getStorage() {
    return (id: string) => {
      const store = this.state[id]
      return new IndexDBStorage(store)
    }
  }
}

export const indexDB = new StorageWithIndexDB()

export interface PersistOptions {
  enabled: true
  strategies?: PersistStrategy[]
}

type Store = PiniaPluginContext['store']
type PartialState = Partial<Store['$state']>

declare module 'pinia' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface DefineStoreOptionsBase<S, Store> {
    persist?: PersistOptions
  }
}

const parseStorage = (strategy: PersistStrategy, store: Store) => {
  const withIndexDB = !!strategy.storage?.withIndexDB
  const overwriteConfig = strategy.storage?.overwriteConfig

  /**
   * 原始默认storage
   */
  const originStorage = withIndexDB
    ? indexDB.getStorage(store.$id)
    : strategy.storage || sessionStorage

  const storage = overwriteConfig
    ? (Object.assign(
        {
          getItem(key: string) {
            return originStorage.getItem(key, originStorage)
          },
          setItem(key: string, value: string, storage: MyStorage) {
            return originStorage.setItem(key, value, storage)
          }
        },
        overwriteConfig
      ) as MyStorage)
    : originStorage
  return { storage, originStorage }
}

const mapStorage = (strategy: PersistStrategy, store: Store) => {
  const storeKey = strategy.key || store.$id
  const newState = strategy.paths
    ? strategy.paths.reduce((finalObj, key) => {
        finalObj[key] = store.$state[key]
        return finalObj
      }, {} as PartialState)
    : store.$state

  return {
    key: storeKey,
    value: newState
  }
}

export const updateStorage = (strategy: PersistStrategy, store: Store) => {
  const withIndexDB = !!strategy.storage?.withIndexDB
  const { key, value } = mapStorage(strategy, store)
  const { storage, originStorage } = parseStorage(strategy, store)
  if (!withIndexDB) {
    storage.setItem(key, JSON.stringify(value), originStorage)
  }
}

export default ({ options, store }: PiniaPluginContext) => {
  if (options.persist?.enabled) {
    const defaultStrat: PersistStrategy[] = [
      {
        key: store.$id,
        storage: sessionStorage
      }
    ]

    const strategies = options.persist?.strategies?.length
      ? options.persist?.strategies
      : defaultStrat

    const onChange = () => {
      const indexDBStore: Record<string, unknown> = {}
      for (let index = 0; index < strategies.length; index++) {
        const strategy = strategies[index]
        const withIndexDB = !!strategy.storage?.withIndexDB
        if (withIndexDB) {
          const { key, value } = mapStorage(strategy, store)
          indexDBStore[key] = value
        } else {
          updateStorage(strategy, store)
        }
      }

      const result = JSON.stringify(indexDBStore)

      if (result !== '{}') {
        indexDB.updateIndexDB(store.$id, result)
      }
    }

    strategies.forEach((strategy) => {
      const { storage, originStorage } = parseStorage(strategy, store)
      const storeKey = strategy.key || store.$id
      const storageResult = storage.getItem(storeKey, originStorage as Storage)
      if (storageResult) {
        store.$patch(JSON.parse(storageResult as string))
      }
    })

    onChange()

    indexDB.delete(store.$id)

    store.$subscribe(onChange)
  }
}
