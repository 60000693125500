import { PictureUrlCore } from './pictureUrlCore'
import { PreviewMode } from './previewMode'
import { ResponseData2Params } from './responseData2Params'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any
/** 拼装图片路径 */
export class CombinePictureUrl {
  /** 平台数据解析出图片资源路径 */
  public static parsePlatformItem = (data: Any) => {
    /** 预览模式的地址 */
    const previewUrl = PreviewMode.run()
    // 如果预览模式有返回地址,则直接展示预览模式的数据
    if (previewUrl) {
      return previewUrl
    }
    /** 精简版的平台数据 */
    const simplePlatformItem =
      ResponseData2Params.responseData2simplePlatformCategoriesItem(data)
    /** 将卡片数据转换成图片路径函数需要的入参 */
    const params = ResponseData2Params.gameData2Params(simplePlatformItem)
    return PictureUrlCore.run(params)
  }

  /** 游戏数据解析出图片资源路径 */
  public static parseGameItem = (data: Any) => {
    /** 预览模式的地址 */
    const previewUrl = PreviewMode.run()
    // 如果预览模式有返回地址,则直接展示预览模式的数据
    if (previewUrl) {
      return previewUrl
    }
    /** 精简版的游戏数据 */
    const simpleGameItem = ResponseData2Params.responseData2simpleGameItem(data)
    /** 将卡片数据转换成图片路径函数需要的入参 */
    const params = ResponseData2Params.gameData2Params(simpleGameItem)
    return PictureUrlCore.run(params)
  }
}
