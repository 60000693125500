import { GlobalManager } from '@/context'
import { getCurrentDevice } from './Device'
import { saveAs } from 'file-saver'
import { useI18n } from '@/i18n'
import domToImage from 'dom-to-image'
type Point = {
  x: number
  y: number
}
export default class ImageUtils {
  public static transparentBase64: 'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWNgYGBgAAAABQABh6FO1AAAAABJRU5ErkJggg=='
  /**
   * 根据宽高 及角度 计算 线性渐变的 起始点
   * 左上角为原点，
   * x轴正方向 右
   * y轴正方向 下
   * angle 为css3 线性渐变角度标准（0度 为 y负方向，90度为 x正方向）
   * 即 0 度时 渐变为从下到上，90度时 渐变为从左到右， 180度时 从上到下
   */
  public static calculateGradientCoordinate(
    width: number,
    height: number,
    angle = 180
  ): {
    x0: number
    y0: number
    x1: number
    y1: number
  } {
    const createResult = (start: Point, end: Point) => {
      return {
        x0: start.x,
        y0: start.y,
        x1: end.x,
        y1: end.y
      }
    }

    angle = angle % 360
    while (angle < 0) {
      angle += 360
    }

    /**
     * 转换为常规解析几何模型标准  （x轴正方向为0度，y轴正方向为90度, 角 度作用域为[0,180)）
     */
    const angleDiff = 90
    let theta = angle - angleDiff
    /**
     *
     * angle 是否在定义域内
     */
    const isInAngleDomain = angle >= 0 + angleDiff && angle < 180 + angleDiff
    if (!isInAngleDomain) {
      theta = angle < 90 ? angle + 90 : angle - 270
    }

    let pointStart, pointEnd

    /**
     * theta为90的奇数倍时
     * 直线平行y轴，斜率不存在，直接 得结果
     */
    if ((theta / 90) % 2 === 1) {
      pointStart = {
        x: width / 2,
        y: 0
      }
      pointEnd = {
        x: width / 2,
        y: height
      }
    } else {
      /**
       * 斜率存在，计算出斜率为 k: Math.tan((theta * Math.PI) / 180)
       * 点斜式直线方程为 l: y - b/2 =  k(x - a/2)
       */
      const k = Math.tan((theta * Math.PI) / 180)
      /**
       * |k| >= height / width 时
       * 与 两条 平行x 轴的边相交
       * 与以下两条直接求解交点即可
       * l1: y = 0
       * l2: y = b
       */
      if (Math.abs(k) >= height / width) {
        pointStart = {
          x: width / 2 - height / (k * 2),
          y: 0
        }
        pointEnd = {
          x: width / 2 + height / (k * 2),
          y: height
        }
      } else {
        /**
         * |k| <  height / width 时
         * 与 两条 平行y 轴的边相交
         * 与以下两条直接求解交点即可
         * l1: x = 0
         * l2: x = a
         */
        if (k >= 0) {
          pointStart = {
            x: 0,
            y: height / 2 - (width * k) / 2
          }
          pointEnd = {
            x: width,
            y: height / 2 + (width * k) / 2
          }
        } else {
          pointEnd = {
            x: 0,
            y: height / 2 - (width * k) / 2
          }
          pointStart = {
            x: width,
            y: height / 2 + (width * k) / 2
          }
        }
      }
    }

    return !isInAngleDomain
      ? createResult(pointEnd, pointStart)
      : createResult(pointStart, pointEnd)
  }
  /**
   * 遍历像素点
   */
  public static traversePixel(
    imageData: ImageData,
    callback: (config: {
      pxStartIndex: number
      pxData: {
        r: number
        g: number
        b: number
        a: number
      }
      col: number
      row: number
    }) => void | 'done',
    mode: 'startX' | 'startY' | 'endX' | 'endY' = 'startX'
  ) {
    const { data, width, height } = imageData
    const formatterPx = (
      data_: Uint8ClampedArray,
      row: number,
      col: number,
      width_: number
    ) => {
      // 当前像素块相对于图片的索引位置
      const pxIndex = row * width_ + col
      const pxStartIndex = pxIndex * 4
      const pxData = {
        r: data_[pxStartIndex],
        g: data_[pxStartIndex + 1],
        b: data_[pxStartIndex + 2],
        a: data_[pxStartIndex + 3]
      }
      return {
        pxData,
        pxStartIndex
      }
    }

    const map = {
      /**
       *从左到右，一列一列遍历
       */
      startX: () => {
        for (let col = 0; col < width; col++) {
          for (let row = 0; row < height; row++) {
            const { pxStartIndex, pxData } = formatterPx(data, row, col, width)
            if (
              callback({
                pxStartIndex,
                pxData,
                col,
                row
              }) === 'done'
            ) {
              return
            }
          }
        }
      },
      /**
       * 从左到右，一行一行遍历
       */
      startY: () => {
        for (let row = 0; row < height; row++) {
          for (let col = 0; col < width; col++) {
            const { pxStartIndex, pxData } = formatterPx(data, row, col, width)
            if (
              callback({
                pxStartIndex,
                pxData,
                col,
                row
              }) === 'done'
            ) {
              return
            }
          }
        }
      },
      /**
       * 从右到左，一列一列遍历
       */
      endX: () => {
        for (let col = width - 1; col >= 0; col--) {
          for (let row = 0; row < height; row++) {
            const { pxStartIndex, pxData } = formatterPx(data, row, col, width)
            if (
              callback({
                pxStartIndex,
                pxData,
                col,
                row
              }) === 'done'
            ) {
              return
            }
          }
        }
      },
      /**
       * 从右到左，一行一行遍历
       */
      endY: () => {
        for (let row = height - 1; row >= 0; row--) {
          for (let col = 0; col < width; col++) {
            const { pxStartIndex, pxData } = formatterPx(data, row, col, width)
            if (
              callback({
                pxStartIndex,
                pxData,
                col,
                row
              }) === 'done'
            ) {
              return
            }
          }
        }
      }
    }
    map[mode]()
  }
  /**
   * 获取有色的像素边界,
   * 四个方向同时遍历，性能提升
   */
  public static calcPixelBoundaries(imageData: ImageData) {
    const { width, height } = imageData

    let startX = width,
      startY = height,
      endX = 0,
      endY = 0

    this.traversePixel(
      imageData,
      ({ pxData, col }) => {
        const colorExist = pxData.a !== 0
        if (colorExist) {
          startX = col
          return 'done'
        }
      },
      'startX'
    )
    this.traversePixel(
      imageData,
      ({ pxData, row }) => {
        const colorExist = pxData.a !== 0
        if (colorExist) {
          startY = row
          return 'done'
        }
      },
      'startY'
    )
    this.traversePixel(
      imageData,
      ({ pxData, col }) => {
        const colorExist = pxData.a !== 0
        if (colorExist) {
          endX = col
          return 'done'
        }
      },
      'endX'
    )
    this.traversePixel(
      imageData,
      ({ pxData, row }) => {
        const colorExist = pxData.a !== 0
        if (colorExist) {
          endY = row
          return 'done'
        }
      },
      'endY'
    )
    return [startX, startY, endX, endY]
  }

  /**
   * 保持dom为png
   * @param node dom节点HTMLCanvasElement
   * @param fileName 文件名
   * @param callback 可选，完成成功回调
   */
  public static onSaveImg(
    node: HTMLCanvasElement,
    fileName: string,
    callback?: () => void
  ) {
    const { t } = useI18n()
    domToImage
      .toPng(node, { cacheBust: true, quality: 0.8 })
      .then((value) => {
        if (getCurrentDevice().ios()) {
          saveAs(value as string, `${fileName}.png`)
        } else {
          // 創建一個A標籤
          const a = document.createElement('a')
          a.href = value as string
          a.download = `${fileName}.png`
          // 將A標籤添加到文檔中
          document.body.appendChild(a)
          // 觸發下載事件
          a.click()
          GlobalManager.Modal.message({
            type: 'success',
            content: t('lobby.promote.promote.downloadSuccessTips')
          })
        }
      })
      .finally(callback)
  }
  public static base64ToBlob(base64Data: string) {
    const [type, value] = base64Data.split(','),
      fileType = (type || '').match(/:(.*?);/)?.[1],
      bstr = atob(value)

    let l = bstr.length
    const u8Arr = new Uint8Array(l)
    while (l--) {
      u8Arr[l] = bstr.charCodeAt(l)
    }
    return new Blob([u8Arr], {
      type: fileType
    })
  }
}
