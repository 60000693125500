/**
 * 修饰器，用于修饰类组件方法
 */
import { Debounce as LodashDecoratorsDebounce } from 'lodash-decorators'
import { merge } from 'lodash'

export function Debounce(
  this: unknown,
  ...[wait = 300, options = {}]: Parameters<typeof LodashDecoratorsDebounce>
) {
  /**
   * 默认为即刻响应
   */
  options = merge(
    {
      leading: true,
      trailing: false
    },
    options
  )
  return LodashDecoratorsDebounce.bind(this)(wait, options)
}

export { Memoize, Bind, Throttle, Mixin } from 'lodash-decorators'
