import { cloneDeep } from 'lodash'
import { loadImage2Blob } from '@/utils/Loader'
import Player from 'apng-js/types/library/player'
import parseAPNG, { APNG } from 'apng-js'
export type Item = {
  url: string
  apng: APNG
  player: Player
  canvas: HTMLCanvasElement
}

export type Options = {
  /**
   * 缩放值，传入后会忽略width 和 height
   */
  scale?: number
  /**
   * 宽度
   */
  width?: number
  /**
   * 高度
   */
  height?: number
  /**
   * 控制播放次数
   */
  numPlays?: number
  /**
   * 是否自动播放
   */
  autoPlay?: boolean
}

export default class ApngCanvas {
  private pools = new Map<string, Item>()
  private apngPools = new Map<string, APNG>()

  /**
   * TO DO:
   * 处理并发时下载多次
   */
  private async getApngInfo(pngUrl: string) {
    if (this.apngPools.has(pngUrl)) {
      return cloneDeep(this.apngPools.get(pngUrl) as APNG)
    }
    const blob = await loadImage2Blob(pngUrl)
    const buffer = await blob.arrayBuffer()
    return parseAPNG(buffer)
  }

  /**
   *
   * @param pngUrl 资源地址，一般为import xxx from 'xxx.png' 拿到的值
   * @param options 配置项
   * @param uuid 每个uuid是一个动画实例
   */
  public async create(
    pngUrl: string,
    options: Options = {},
    uuid: string = pngUrl
  ) {
    try {
      const { numPlays, width, height, scale, autoPlay } = Object.assign(
        {
          autoPlay: true,
          numPlays: 0
        },
        options
      )
      if (this.pools.has(uuid)) {
        return this.pools.get(uuid)
      }
      const url = pngUrl
      const apng = await this.getApngInfo(pngUrl)
      if (apng instanceof Error) return Promise.reject(apng)
      this.apngPools.set(pngUrl, apng)
      apng.numPlays = numPlays ?? apng.numPlays
      await apng.createImages()
      const canvas = document.createElement('canvas')
      canvas.width = width || apng.width
      canvas.height = height || apng.height
      if (scale) {
        canvas.width = scale * apng.width
        canvas.height = scale * apng.height
      }
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
      ctx.scale(canvas.width / apng.width, canvas.height / apng.height)
      const player = await apng.getPlayer(ctx, autoPlay)
      this.pools.set(uuid, {
        url,
        apng,
        player,
        canvas
      })
      return this.pools.get(uuid)
    } catch (error) {
      return Promise.reject()
    }
  }
  public play(uuid: string) {
    this.pools.get(uuid)?.player.play()
  }
  public stop(uuid: string) {
    this.pools.get(uuid)?.player.stop()
  }
  public pause(uuid: string) {
    this.pools.get(uuid)?.player.pause()
  }

  public destroy(uuid: string) {
    this.pools.delete(uuid)
  }

  public setRate(uuid: string, rate = 1) {
    const player = this.pools.get(uuid)?.player
    player && (player.playbackRate = rate)
  }
}
