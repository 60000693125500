import { Base, Component, Emit, Prop, Watch } from '@/vue-property-decorator'
import { getCurrentDevice } from '@/utils/Device'
import { useI18n } from '@/i18n'
import Animation from '@/utils/Animation'
import DateUtils from '@/utils/DateUtils'
import TimeUtils from '@/utils/TimeUtils'
import style from './style.module.scss'

interface Props {
  duration: number
  hasTextBorder: boolean
  hasDay?: boolean
  formatStr?: string
  formatFunc?: (cdv: number) => string
  showAllHours?: boolean // 显示所有小时数，针对超过24h的情况，如： 32:43:02
}

@Component<CountDown>({ name: 'CountDown' })
export default class CountDown extends Base<unknown, Props> {
  private countDownAnimation!: Animation | null

  @Prop({ default: 0, required: true })
  duration!: Props['duration']

  @Prop({ default: false })
  hasTextBorder!: Props['hasTextBorder']

  @Prop()
  showAllHours?: Props['showAllHours']

  @Prop({ default: false })
  hasDay!: Props['hasDay']
  @Prop({ default: 'HH:mm:ss' })
  formatStr!: Props['formatStr']
  @Prop({ default: null })
  formatFunc!: Props['formatFunc']
  /**
   * -1 时代表未进入倒计时状态
   */
  private countDownValue = -1

  get formatTime() {
    if (this.countDownValue < 0) {
      return ''
    }

    if (this.formatFunc) {
      return this.formatFunc(this.countDownValue)
    }

    if (this.showAllHours) {
      return TimeUtils.parse(this.countDownValue)
    }

    return DateUtils.moment()
      .startOf('days')
      .add(this.countDownValue, 's')
      .format(this.formatStr)
  }

  get formatDay() {
    return this.countDownValue !== -1
      ? Math.floor(this.countDownValue / 86400)
      : 0
  }

  @Emit('over')
  countDownOver() {
    return
  }

  @Emit('time')
  countDownTime(isFormat = true) {
    return isFormat
      ? DateUtils.getFormatTime(this.countDownValue, this.formatStr)
      : this.countDownValue
  }

  @Watch('countDownValue')
  WatchCountDown() {
    this.countDownTime()
  }

  @Watch('duration')
  WatchDuration(n: number, o: number) {
    if (n !== o) {
      this.doCountDown()
    }
  }

  public reset() {
    this.countDownAnimation?.kill()
    this.countDownValue = -1
    this.countDownAnimation = null
    this.countDownOver()
  }

  public doCountDown() {
    this.countDownValue = this.duration
    this.countDownAnimation?.kill()
    this.countDownAnimation = new Animation({
      time: this.duration
    })
      .to({ time: 0 }, this.duration * 1000, 'none')
      .on(Animation.EventType.UPDATE, (result) => {
        this.countDownValue = parseInt(result.time)
      })
      .on(Animation.EventType.COMPLETE, () => {
        this.reset()
      })
  }

  mounted() {
    this.doCountDown()
  }

  beforeDestroy() {
    this.countDownAnimation?.kill()
  }

  private renderCountDown() {
    const { t } = useI18n()
    return (
      <span class={[style.countSpan, 'countSpan']}>
        {this.hasDay &&
          this.formatDay > 0 &&
          t('lobby.event.invest.columns.rewardDays.value', {
            days: this.formatDay
          })}
        {this.countDownValue !== -1 && this.formatTime}
      </span>
    )
  }

  render() {
    const device = getCurrentDevice()
    return this.hasTextBorder && !device.ios() ? (
      <span class={[style.count, 'count']} data-text={this.formatTime}>
        {this.renderCountDown()}
      </span>
    ) : (
      <span class={[style.countText, 'countText']}>
        {this.renderCountDown()}
      </span>
    )
  }
}
