import siteConfig from '@/config/site.config'

/** 对后台预览模式做特殊处理 */
export class PreviewMode {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
  private static instance: PreviewMode
  private static getInstance() {
    if (!this.instance) {
      this.instance = new PreviewMode()
    }
    return this.instance
  }

  /** 执行预览模式的判断,如果是预览模式,则返回默认预览图片地址,否则返回空 */
  public static run(): string | undefined {
    return this.getInstance().previewGameIconUrl()
  }

  /** 获得url参数 */
  private getURLParameters(url = window.location.href) {
    return (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
      (a: Record<string, string>, v: string) => (
        (a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a
      ),
      {}
    )
  }

  /** 生成预览模式的图片url地址 */
  private generateImgUrl(name: string) {
    return `https://${siteConfig.ossName}.${siteConfig?.ossType}/siteadmin/skin/lobby_asset/common/web/home/${name}?manualVersion=1`
  }

  /** 后台子游戏预览模式下使用美术写死的资源路径 */
  private previewGameIconUrl() {
    const { previewonly, gameIconType } = this.getURLParameters()
    // 根据url参数,判断是否为预览模式
    if (previewonly === 'true') {
      if (gameIconType === '1') {
        return this.generateImgUrl('img_def_yx_sb.png')
      }

      if (gameIconType === '2') {
        return this.generateImgUrl('img_def_yx_fx.png')
      }
    }
  }
}
