/**
 * 财务专用业务库
 */

import { Currency } from '@/config/currencies.config'
import { CurrencyEnum } from '@/api/common/type'
import {
  CurrencyOptions,
  currencyFormatter as defaultCurrencyFormatter
} from '@/utils/FormatterUtils'
import { div, times, toString } from '../MathUtils'
import { useMainStore } from '@/store/index'
import BigNumber from 'bignumber.js'
import crypto from '@/context/crypto'

const MAX_DECIMAL_LEN = 15
const MAX_INT_LEN = 12

export default class FinanceUtils {
  static times(...args: BigNumber.Value[]) {
    return toString(times(...args))
  }
  static div(...args: BigNumber.Value[]) {
    return toString(div(...args))
  }

  /**
   * 删除尾数的0
   * @param str
   * @returns
   */
  static deleteZero(str: string): string {
    const len = str.length
    const last = len - 1
    if (len === 0) {
      return str
    }
    if (!str.includes('.')) {
      return str
    }
    if (str[last] === '0' || str[last] === '.') {
      return this.deleteZero(str.slice(0, -1))
    }
    return str
  }
  /**
   * 强制增加 尾数的 .00
   * @param str
   * @returns
   */
  static autoAddTwoZero(str: string): string {
    if (str == undefined || str == null) return str
    let result = str?.toString()
    let rs = result.indexOf('.')
    if (rs < 0) {
      rs = result.length
      result += '.'
    }
    while (result.length <= rs + 2) {
      result += '0'
    }
    return currencyFormatter(result, { symbol: '' }, true)
  }
  /**
   * 处理金额
   * 1、虚拟币金额可输入小数点后10位；

     2、法币金额只保留小数点后2位；

     如果是零开始的小数,同样保留10位小数
   */
  static formatMoney(
    val: string,
    code: string,
    deleteZero = true,
    reserveDecimal = 10
  ) {
    const store = useMainStore()

    const currencyInfo = store.getCurrencyInfoByCode(code)
    let isCrypto = currencyInfo?.currencyType === CurrencyEnum.CRYPTO
    if (val.startsWith('0.')) {
      isCrypto = true
    }
    const regexp1 = /^\D*(\d*(?:\.\d{0,2})?).*$/g
    const regexp2 = new RegExp(
      '^\\D*(\\d*(?:\\.\\d{0,' + reserveDecimal + '})?).*$',
      'g'
    )
    const result = isCrypto
      ? val.replace(regexp2, '$1')
      : val.replace(regexp1, '$1')
    return deleteZero ? this.deleteZero(result) : result
  }

  /**
   * 汇率保留八位小数
   * @param val
   * @returns
   */
  static formatExchange(val: string, reserveDecimal = 8) {
    const regexp = new RegExp(
      '^\\D*(\\d*(?:\\.\\d{0,' + reserveDecimal + '})?).*$',
      'g'
    )
    return this.deleteZero(val.replace(regexp, '$1'))
  }

  /**
   * 上分模式输入保留两位小数
   * @param val
   * @returns
   */
  static formGameRateMoney(val: string) {
    const regexp = /^\D*(\d*(?:\.\d{0,2})?).*$/g
    return val.replace(regexp, '$1')
  }

  /**
   * 获取游戏比例字符串
   * rate比率只有整数,但是后端可能存储1/7出现无限循环小数,所以前端1/rate时,需要四舍五入
   * @param sign
   * @param rate
   * @returns
   */
  static getGameRateProportion(sign: string, rate = 1, revert = false) {
    // VND特殊处理
    const SPEC_SIGN = '₫'
    let str = ''
    if (rate <= 1) {
      str = `${sign}1=${Math.round(1 / rate)}`

      if (sign === SPEC_SIGN) {
        str = `1${sign}=${Math.round(1 / rate)}`
      }
    } else {
      str = `${sign}${rate}=1`

      if (sign === SPEC_SIGN) {
        str = `${rate}${sign}=1`
      }
    }

    if (revert) {
      str = str.split('=').reverse().join('=')
    }

    return str
  }

  /**
   * 获取游戏比例汇算结果字符串
   * @param sign
   * @param rate
   * @param revert
   * @returns
   */
  static getCurrencyFormat(sign: string, result: string) {
    // VND特殊处理
    const SPEC_SIGN = '₫'
    if (sign === SPEC_SIGN) {
      return `${result}${sign}`
    }

    return `${sign}${result}`
  }

  /**
   * 输入框长度限制
   */
  static checkInputMoneyLimit(value: string) {
    const len = value.length
    if (value.includes('.')) {
      return len <= MAX_DECIMAL_LEN
    } else {
      return len <= MAX_INT_LEN
    }
  }

  /**
   * 根据输入值获取输入框长度临界值截取数据
   * @param value
   * @returns
   */
  static getInputMoneyLimit(value?: string | null) {
    if (!value) {
      return value
    }

    if (value.includes('.')) {
      return value.slice(0, MAX_DECIMAL_LEN)
    } else {
      return value.slice(0, MAX_INT_LEN)
    }
  }

  /**
   * 加密 必须按照此顺序拼接"username,amount,paytype,payplatformid,time,payplatformids,id,platformId,gameId,currencyCode,mobilePhone,email"
   * @param params
   * @param key
   * @param isMerge
   * @returns
   */
  static genOrderSign(
    params: Record<string, string | number>,
    key: string,
    isMerge: boolean
  ) {
    let str = ''
    if (params.username) {
      str += 'username=' + params.username
    }
    if (params.amount) {
      str += '&amount=' + params.amount
    }
    if (params.paytype != null || params.paytype != undefined) {
      //新的自定义通道id为 0 会被当成false过滤掉
      str += '&paytype=' + params.paytype
    }
    if (!isMerge) {
      if (params.payplatformid) {
        str += '&payplatformid=' + params.payplatformid
      }
    }
    if (params.time) {
      str += '&time=' + params.time
    }

    if (isMerge) {
      if (params.payplatformids) {
        str += '&payplatformids=' + params.payplatformids
      }
      if (params.id) {
        str += '&id=' + params.id
      }
    }

    if (params.currencyCode) {
      str += '&currencyCode=' + params.currencyCode
    }

    if (params.mobilePhone) {
      str += '&mobilePhone=' + params.mobilePhone
    }

    if (params.email) {
      str += '&email=' + params.email
    }

    if (params.cardNumber) {
      str += '&cardNumber=' + params.cardNumber
    }

    if (params.cardPassword) {
      str += '&cardPassword=' + params.cardPassword
    }

    if (params.networkMerch) {
      str += '&networkMerch=' + params.networkMerch
    }

    if (params.bankCode) {
      str += '&bankCode=' + params.bankCode
    }

    if (params.bankName) {
      str += '&bankName=' + params.bankName
    }
    if (params.merchCode) {
      str += '&merchCode=' + params.merchCode
    }
    // 生产环境503,126,128 站点key先写死
    const siteCode = useMainStore()?.siteConfig?.siteCode || ''
    if (['503', '126', '128'].includes(siteCode)) {
      str += '&key=fc361cdb770aebc2126cc0dac989c896'
    } else {
      str += '&key=' + key
    }
    return crypto.md5(str)
  }

  static getWalletSign(params: Record<string, string | number>, key: string) {
    const keys = Object.keys(params).sort()
    let str = keys.map((key) => `${key}=${params[key]}`).join('&')
    str += '&key=' + key
    return crypto.md5(str)
  }
}

/**
 * 糅合业务的币种转换，可控制一些特定币种走本土化配置,
 * opts支持根据币种返回不同配置
 * @example
 */
export function currencyFormat(
  value: BigNumber.Value,
  opts?: CurrencyOptions | ((currency: string) => CurrencyOptions)
): string {
  const { userInfos } = useMainStore()
  const currency = userInfos?.currency || ''
  const localizationConfig = defaultCurrencyFormatter.createOptsByCode(
    currency as CurrencyOptions['code']
  )
  const needLocalization = ['BRL'].includes(currency)
  return defaultCurrencyFormatter(
    value,
    Object.assign(
      {},
      needLocalization ? localizationConfig : {},
      typeof opts === 'function' ? opts(currency) : opts
    )
  )
}

/**
 * 巴西币种强制转换 显示转换
 * @example
 * currencyFormatter(1888.345,{precision:2,symbol:'$',separator:','})
 * //=> $1,888.34
 * currencyFormatter(-1888.345,{precision:2,symbol:'$',separator:','})
 * //=> -$1,888.34
 * currencyFormatter(1888.345,{precision:2,symbol:'₫',separator:',',template:'%v%s'})
 * //=> 1,888.34₫
 * currencyFormatter(-1888.345,{precision:2,symbol:'₫',separator:',',template:'%v%s'})
 * //=> -1,888.34₫
 * //使用code匹配内置的货币配置自动生成precision,symbol,separator,template等
 * currencyFormatter(-1888.345,{code:'CNY'})
 * //=> -¥1,888.34
 * currencyFormatter(-1888.345,{code:'CNY',template:{neg:'%s-%v'}})
 * //=> ¥-1,888.34
 * isHighWeightRatio 高权重比，把opts覆盖到巴西币种配置里
 * @deprecated 逐步用currencyFormat代替，注意业务层等价
 */
export function currencyFormatter(
  value: BigNumber.Value,
  opts?: CurrencyOptions,
  isHighWeightRatio?: boolean
): string {
  let configOpts = {} as CurrencyOptions
  const { userInfos } = useMainStore()
  if (userInfos && userInfos.currency && userInfos.currency === 'BRL') {
    // 提币币种为巴西币种的时候，将巴西币种的配置code传入
    const code = userInfos.currency
    configOpts = defaultCurrencyFormatter.createOptsByCode(
      code as CurrencyOptions['code']
    )
  }
  return defaultCurrencyFormatter(
    value,
    isHighWeightRatio
      ? Object.assign({}, configOpts, opts)
      : Object.assign({}, opts, configOpts)
  )
}
/**
 * VIP界面全币种格式化
 */
export function vipCurrencyFormatter(
  value: BigNumber.Value,
  precision = 0
): string {
  if (!value && value !== 0) {
    return ''
  }
  const { userInfos } = useMainStore()
  let configOpts = {}
  if (userInfos?.currency === Currency.currenciesFormatConfig().BRL.code) {
    // 这里写死巴西币种配置
    configOpts = {
      decimal: ',',
      separator: '.',
      symbol: 'R$',
      template: { pos: '%s %v', neg: '-%s %v', zero: '%s %v' }
    }
  } else {
    // 这里默认写死CNY币种配置
    configOpts = {
      decimal: '.',
      separator: ',',
      symbol: '¥',
      template: { pos: '%s%v', neg: '-%s%v', zero: '%s%v' }
    }
  }
  return defaultCurrencyFormatter(
    value,
    Object.assign({}, configOpts, { symbol: '', precision })
  )
}

/**
 * 使用用户配置的币种来控制小数点以及展示方式，比如 1000 => 1,000.00 巴西币种则是 1000 => 1.000,00
 */
export function brazilianCurrencyFormatter(
  value: BigNumber.Value,
  opts?: CurrencyOptions
): string {
  if (!value && value !== 0) {
    return ''
  }
  const { userInfos } = useMainStore()
  const result = defaultCurrencyFormatter(
    value,
    Object.assign({}, opts, {
      code: userInfos?.currency,
      template: '%v'
    })
  )
  return result
}

/**
 * 格式化字符串，按照指定间隔插入指定内容。
 * @param {string} str - 需要格式化的字符串。
 * @param {number} [interval=4] - 每次插入的间隔字符数量，默认为4。
 * @param {string} [insertion=' '] - 需要插入的内容，默认为一个空格。
 * @example insertContent('1234567890abcdef', 3, '-') 返回 '123-456-789-0ab-cde-f'
 */
export function insertContent(
  str: string,
  interval = 4,
  insertion = ' '
): string {
  const cleanedStr = str.replace(new RegExp(`\\${insertion}`, 'g'), '')
  const regex = new RegExp(`(.{${interval}})`, 'g')
  return cleanedStr.replace(regex, `$1${insertion}`)
}
/**
 * 字符串分割
 * 使用方式1：stringFormatSplit('123456789', '2.3/2-2) => 12.345/67-89
 * 使用方式2：stringFormatSplit('123456789', '12345678', '2.3/2-2) => 12.345/67-89 （输入时格式化用到）
 * @param value 要格式化的字符串
 * @param oldValue 上一次的值 主要用做操作输入框删除逻辑
 * @param format 格式 3.3.2-5/2 => abcd.xxx.xx-xxxxx/xx
 * @returns
 */
export function stringFormatSplit(
  value: string,
  oldValueOrFormat?: string,
  format = ''
) {
  // 支持只传两个参数 默认是value or format
  let oldValue = ''
  if (arguments.length === 2) {
    format = oldValueOrFormat ?? ''
  } else if (arguments.length === 3) {
    oldValue = oldValueOrFormat ?? ''
  }

  const expression = format.split(/(\d+|\D)/).filter((x) => x)
  const connector = expression.filter((x) => isNaN(Number(x)))
  const filteredValue = value
    .split('')
    .filter((char) => !connector.includes(char))
    .join('')

  // 处理删除 如果最后一位是连接符需删除两位
  if (
    oldValue &&
    value.length < oldValue.length &&
    connector.includes(oldValue.slice(-1))
  ) {
    return value.slice(0, -1)
  }

  let formattedValue = ''
  let index = 0
  // 计算当前已拼接长度 在合适的时机添加连接符
  let carryLength = 0

  for (let i = 0; i < expression.length; i++) {
    if (!filteredValue[index]) {
      break
    }
    // 拼接字符
    if (!isNaN(Number(expression[i]))) {
      const sliceLength = index + Number(expression[i])
      formattedValue += filteredValue.slice(index, sliceLength)
      index = sliceLength
      carryLength += Number(expression[i])
    }
    // 提前添加连接符
    if (
      carryLength === formattedValue.length &&
      connector.indexOf(expression[i + 1]) !== -1
    ) {
      formattedValue += expression[i + 1]
      carryLength += 1
    }
  }

  return formattedValue
}
