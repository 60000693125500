import { Base, Component } from '@/vue-property-decorator'
import { KindType, PackKindType, androidDownloadType } from '@/api/common/type'
import {
  UrlsItem,
  useDownloadAppStore
} from '@/layouts/components/downloadApp/store'
import { currencyFormatter } from '@/utils/FormatterUtils'
import { random } from 'lodash'
import { replaceUrlByNewDomain } from '@/utils/Tool'
import { sendPointerDomain, windowOpen } from '@/utils/business-utils'
import { useMainStore } from '@/store/index'
import isMobile from '@/utils/isMobile'
@Component<BaseMixins>({ name: 'BaseMixins' })
export default class BaseMixins extends Base {
  /**格式化获取新人APP下载奖励 */
  protected get formatDownloadAppReward() {
    const { downloadAppReward, userInfos } = useMainStore()
    const formatedDownloadAppReward = currencyFormatter(
      downloadAppReward || 0,
      {
        code: userInfos?.currency,
        precision: 2,
        symbol: ''
      }
    )
    return formatedDownloadAppReward
  }

  /**
   * 获取加速域名的列表
   */
  private get getAccDomainList(): Array<string> {
    const { siteURLInfos } = useMainStore()
    return siteURLInfos?.oss_domain ?? []
  }

  /**
   * @description 替换安卓下载地址的站点域名为加速域名
   */
  private replaceSiteDomainByAccDomain(downloadUrl: string) {
    const accDomainList = this.getAccDomainList
    // 如果没有加速域名的场景
    if (!accDomainList.length) {
      return downloadUrl
    }
    const randomAccDodamin = accDomainList[random(1, accDomainList.length) - 1]
    const replacedUrl = replaceUrlByNewDomain(downloadUrl, randomAccDodamin)

    return replacedUrl
  }

  /**点击下载按钮*/
  protected async handlerDownloadBtn(item: UrlsItem, count: number) {
    const isIOS = isMobile().apple.device
    const { downloadPath, customizePath, value } = item
    const notClickedUrls: string[] = []
    if (isIOS) {
      notClickedUrls.push(value as string)
    } else {
      if (downloadPath) {
        const replacedUrl = this.replaceSiteDomainByAccDomain(downloadPath)
        notClickedUrls.push(replacedUrl)
      }
      if (customizePath) {
        notClickedUrls.push(customizePath)
      }
    }
    useDownloadAppStore().reportDownloadUrlClick(item)
    let packKind = PackKindType.Normal
    if (item.type === androidDownloadType.SPEED) {
      packKind = PackKindType.Quickly
    }
    sendPointerDomain(KindType.ClickDownLoad, packKind)
    //2个链接循环尝试
    windowOpen(notClickedUrls[count % notClickedUrls.length])
  }

  created() {
    const { getNewComerBenefit } = useDownloadAppStore()
    getNewComerBenefit()
  }

  render() {
    return <template></template>
  }
}
