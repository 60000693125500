import Vue, { PluginObject } from 'vue'

const namespace = 'inner-text-to-title'

const Plugin: PluginObject<unknown> = {
  install: (_Vue): void => {
    _Vue.directive(namespace, {
      bind(el) {
        Vue.nextTick(() => {
          el.setAttribute('title', el.innerText)
        })
      },

      update(el) {
        el.setAttribute('title', el.innerText)
      },

      unbind(el) {
        if (el.hasAttribute('title')) {
          el.removeAttribute('title')
        }
      }
    })
  }
}

export default Plugin
