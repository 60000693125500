import { TabBarState } from './type'
import { TabKey } from '../types'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import Skin from '@/controller/Skin'

export const useTabBarStore = defineStore('tabBar', {
  state: (): TabBarState => ({
    currentTabItem: TabKey.HOME
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: createMyPersistedStorage(null, sessionStorage),
        paths: ['currentTabItem']
      }
    ]
  },

  actions: {
    updateTabItem(data: TabBarState['currentTabItem']) {
      this.currentTabItem = data
    }
  },
  getters: {
    isSeperateTabSkin() {
      const skin = Skin.currentTheme()
      return skin.isTheme26
    }
  }
})
