import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { useMainStore } from '@/store/index'
import Scrollbar from 'perfect-scrollbar'
import Vue, { DirectiveOptions, PluginObject } from 'vue'

const namespace = 'perfect-scrollbar'
interface ScrollBarHTMLElement extends HTMLElement {
  $scrollbar?: Scrollbar
}

const createPerfectScrollbar = (
  options?: Scrollbar.Options
): DirectiveOptions => {
  const context: DirectiveOptions = {
    unbind(el) {
      const _el = el as ScrollBarHTMLElement
      if (_el.$scrollbar) {
        _el.$scrollbar.destroy()
        delete _el.$scrollbar
      }
    },
    bind(el, binding) {
      /**
       * 传null不开启
       */
      if (typeof binding.value === 'undefined' || binding.value) {
        const _el = el as ScrollBarHTMLElement
        // 这个插件在移动端拖动有问题,如果是移动端不加载且移除ScrollBar
        if (!useMainStore().isWeb) {
          if (_el.$scrollbar) {
            _el.$scrollbar.destroy()
            delete _el.$scrollbar
          }
          return
        }
        if (typeof window === 'undefined' || _el.$scrollbar) return
        Vue.nextTick(() => {
          const elValue = binding?.value?.el
          const targetEl = typeof elValue === 'function' ? elValue(el) : el
          if (targetEl) {
            targetEl.style.position = 'relative'
            _el.$scrollbar = new Scrollbar(
              targetEl,
              Object.assign({}, options, binding.value)
            )
          }
        })
      }
    },
    componentUpdated(el, binding, ...rest) {
      if (!useMainStore().isWeb) return

      if (typeof binding.value === 'undefined' || binding.value) {
        const _el = el as ScrollBarHTMLElement
        if (_el.$scrollbar) {
          context?.unbind?.(el, binding, ...rest)
        }
        context?.bind?.(el, binding, ...rest)
      } else {
        //从某个值变成null后可以解绑
        context?.unbind?.(el, binding, ...rest)
      }
    }
  }
  return context
}

const Plugin: PluginObject<Scrollbar.Options> = {
  install: (_Vue, options?: Scrollbar.Options): void => {
    _Vue.directive(namespace, createPerfectScrollbar(options))
  }
}

export default Plugin
