export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'システムエラー',
        NETWORK_ERROR:
          'ネットワーク接続が失敗しました。ネットワークを切り替えた後に再試行してください',
        RSP_DATA_NOT_JSON: '応答値のフォーマットが間違っています',
        GEETEST_RESTRICTED:
          '頻繁な操作です.{seconds} 秒後にもう一度お試しください'
      },
      tips: { title: 'ヒント' }
    }
  }
}
