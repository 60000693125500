import { GameCategoriesInfo } from '../entity/gameCategorie'
import { GameItemInter } from '../entity/gameItem'
import { GamePlatformCatgory } from '@/views/game/consts/categorys'
import { GameRepository } from './game'
import { debounce } from 'lodash'
/** 平台常用信息 */
export type PlatformInformation = {
  /** 平台id */
  platformId: number
  /** 平台logo地址 */
  platformLogo: string
  /** 平台宣传图地址 */
  platformIcon: string
  /** 自定义图片 */
  isCustomIcon: boolean
  /** 平台自定义热门图 */
  customHotIcon: string
  /** 是否三合一图(图片/logo/背景色) */
  isCombinate: boolean
  /** 平台名称 */
  platformName: string
  /** 游戏内嵌还是外链打开 */
  isExternalLink: boolean
  /** 最小准入余额 */
  minimumEntryBalance: number
  /** 维护状态  */
  maintainStatus: boolean
}
/** 补充平台信息 */
export class SupplyPlatformInfo {
  /** 当前mapper的状态,[initial:初始状态还未进行过接口请求][normal:接口请求获取数据正常,后面不再请求接口][error:接口报了异常,重试请求] */
  private curMapperStatus: 'initial' | 'normal' | 'error' = 'initial'
  /** 平台大类信息接口异常次数(限制最多三次,后面就不要再请求该接口了,避免影响到其它类型的加载) */
  private retryErrorTimes = 0
  /** [类别id+平台id]与该对象一些属性的映射 */
  private platformIdObjMapper: Record<string, PlatformInformation> = {}

  /** 为游戏信息填充平台信息(方法将改变原对象) */
  public async run(
    data: GameItemInter | Array<GameItemInter>,
    specialGame: boolean
  ) {
    // 如果大类接口之前已经有报错过了.则再尝试请求它,刷新mapper
    if (this.curMapperStatus === 'error' && this.retryErrorTimes < 3) {
      this.initPlatformIdInfoMapper()
    }
    if (this.curMapperStatus === 'initial') {
      await this.initPlatformIdInfoMapper()
    }
    if (Array.isArray(data)) {
      return this.supplementArray(data, specialGame)
    } else {
      return this.supplementItem(data, specialGame)
    }
  }

  /** 重置补充平台信息的状态
   * 重置之后,再调用该接口进行赋值时,会重新刷新 platformIdObjMapper
   */
  public resetStatus() {
    this.curMapperStatus = 'initial'
    this.platformIdObjMapper = {}
    // 清空resolve列表的行为,应该在resolve执行完成后立即清空.
    // 只是为了不和snor处理冲突,暂时单独写在此处,以后再调整
    this.initMapperAwaitPromise = []
    // 清零重试列表的次数的行为,应该在resolve执行完成后立即清空.
    // 只是为了不和snor处理冲突,暂时单独写在此处,以后再调整
    this.retryErrorTimes = 0
  }

  /** 初始化mapper的promise等待队列 */
  private initMapperAwaitPromise: Array<() => void> = []
  /** 初始化(刷新)平台id与信息的映射
   * 此函数中使用到了promise的是为了在 initPlatformIdInfoMapper 被重复触发的情况下,
   * 所有调用此函数的行为都需要等待,但其核心逻辑只需要走一次,不需要重复的进行赋值操作.
   * 类似于接口请求合并.
   */
  private async initPlatformIdInfoMapper() {
    const promise = new Promise(async (resolve) => {
      this.initMapperAwaitPromise.push(resolve as () => void)
    })
    // 核心,加载mapper的自执行函数,在此处生成平台id与平台基础信息的映射
    ;(async () => {
      let gameCategoriesInfo: GameCategoriesInfo[] = []
      // 加入异常捕获,若是大类的接口挂掉了,不要影响其它接口的正常使用.
      try {
        // 异步接口取值
        gameCategoriesInfo = await GameRepository.getGameCategorieList()
        this.curMapperStatus = 'normal'
      } catch (error) {
        this.retryErrorTimes += 1
        this.curMapperStatus = 'error'
      }
      // 根据异步接口取到的值,对mapper进行赋值,赋值之后再对所有promise阻塞进行放行
      this.assignPlatformIdInfoMapper(gameCategoriesInfo, () => {
        // mapper生成后,所有等待状态的promise放行
        this.initMapperAwaitPromise.forEach((rlv) => rlv())
      })
    })()
    return promise
  }

  /** 为mapper进行赋值操作(防抖函数+执行结束的回调) */
  private assignPlatformIdInfoMapper = debounce(
    (gameCategoriesInfo: GameCategoriesInfo[], callback: () => void) => {
      // 整理mapper
      this.platformIdObjMapper = gameCategoriesInfo.reduce((pre, cur) => {
        const plaArr = cur.list
        plaArr.forEach((plaItem) => {
          const {
            gameCategoryId,
            platformId,
            platformLogo,
            platformIcon,
            customHotIcon,
            platformName,
            isExternalLink,
            isCustomIcon,
            isCombinate,
            minimumEntryBalance,
            maintainStatus
          } = plaItem
          pre[`${gameCategoryId}_${platformId}`] = {
            platformId,
            platformLogo,
            platformIcon,
            customHotIcon,
            platformName,
            isExternalLink,
            isCustomIcon,
            isCombinate,
            minimumEntryBalance,
            maintainStatus
          }
        })
        return pre
      }, {} as Record<string, PlatformInformation>)
      callback()
    },
    0
  )

  /** 为单项游戏信息填充(方法将改变原对象) */
  private supplementItem(
    data: GameItemInter,
    specialGame: boolean
  ): GameItemInter {
    const { gameCategoryId, platformId } = data
    const platformInfo =
      this.platformIdObjMapper[`${gameCategoryId}_${platformId}`]
    if (platformInfo) {
      data.platformLogo = platformInfo.platformLogo
      data.platformName = platformInfo.platformName
      data.isExternalLink = platformInfo.isExternalLink
      data.minimumEntryBalance = platformInfo.minimumEntryBalance
      // 如果当前平台维护，子游戏用平台的状态
      data.maintainStatus = platformInfo.maintainStatus || data.maintainStatus
      // 特殊处理最近收藏取大类图标以及合图状态
      if (GamePlatformCatgory.includes(data.gameCategoryId) && specialGame) {
        let platformGameIcon = platformInfo?.customHotIcon

        // 优先取热门图，没有根据判断是否自定义取宣传图
        if (!platformGameIcon && !data.isCustomIcon) {
          platformGameIcon = platformInfo.platformIcon
        }

        data.gameIcon = platformGameIcon
        data.isCombinate = platformInfo.isCombinate
        data.isCustomIcon = platformInfo.isCustomIcon
      }
    }
    return data
  }

  /** 为数组游戏信息填充(方法将改变原对象) */
  private supplementArray(
    data: Array<GameItemInter>,
    specialGame: boolean
  ): Array<GameItemInter> {
    return data.map((item) => this.supplementItem(item, specialGame))
  }
}
