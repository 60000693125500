export enum BrowserType {
  /**
   * 其他
   */
  Other = 1,
  /**
   * opera 欧鹏浏览器
   */
  IsOpera = 2,
  /**
   * IE浏览器
   */
  IsIE = 3,
  /**
   * safari 浏览器
   */
  IsSafari = 4,
  /**
   * firefox 火狐浏览器
   */
  IsFirefox = 5,
  /**
   * chrome 浏览器
   */
  IsChrome = 6,
  /**
   * Edge 浏览器
   */
  IsEdge = 7,
  /**
   * hi browser
   */
  IsHiBrowser = 8,
  /**
   * naver 浏览器 韩语
   */
  IsNaver = 9,
  /**
   * DuckDuckGo
   */
  IsDuckDuckGo = 10
}

/**
 * 获取浏览器类型
 * 注意：内部的return的顺序是对结果有一定影响的
 * @returns 浏览器类型
 */
export const getBrowserType = (): BrowserType => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const isOpera = /(opera|opt|opr)\/([0-9.]+)/.test(userAgent)
  // opera 欧鹏 浏览器
  if (isOpera) return BrowserType.IsOpera
  // IE 浏览器
  const isIE =
    /compatible/.test(userAgent) && /msie/.test(userAgent) && !isOpera
  // userAgent.includes('compatible') && userAgent.includes('msie') && !isOpera
  if (isIE) return BrowserType.IsIE
  // edge 浏览器
  const isEdge = /(edge|edgios|edgA|edg)\/([0-9\.]+)/.test(userAgent)
  if (isEdge) return BrowserType.IsEdge
  // firefox 火狐浏览器 三星浏览器 两家目前已深度合作，版本基本一致
  if (
    /(firefox|fxios)\/([0-9\.]+)/.test(userAgent) ||
    /samsungbrowser\/([0-9\.]+)/.test(userAgent)
  )
    return BrowserType.IsFirefox
  // safari 浏览器
  const isSafari =
    /safari\/([0-9\.]+)/.test(userAgent) &&
    !/(chrome|crios)\/([0-9\.]+)/.test(userAgent)
  if (isSafari) return BrowserType.IsSafari
  // chrome 浏览器
  // 安卓端二次封装的手机浏览器会有build/的版本号
  // 例如UC、小米浏览器、via浏览器、naver、X浏览器等
  if (
    /(chrome|crios)\/([0-9\.]+)/.test(userAgent) &&
    /safari\/([0-9\.]+)/.test(userAgent) &&
    !/build\/[A-Za-z0-9]+\.([0-9\.]+)/.test(userAgent) &&
    !isEdge
  )
    return BrowserType.IsChrome
  if (/hibrowser\/v([0-9\.]+)/.test(userAgent)) return BrowserType.IsHiBrowser
  if (/naver\(/.test(userAgent)) return BrowserType.IsNaver
  if (/duckduckgo\/([0-9]+)/.test(userAgent)) return BrowserType.IsDuckDuckGo
  // 其他浏览器
  return BrowserType.Other
}

/**
 * @description h5桌面快捷方式的展示模式
 * 'standalone'表示app的模式，没有地址栏
 */
export const SaveShortcutModeByApp = 'standalone'

/**
 * @description h5桌面快捷方式的展示模式
 * 'minimal-ui'浏览器模式，带有地址栏
 */
export const SaveShortcutModeByBrowser = 'minimal-ui'
