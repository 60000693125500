export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'Hệ thống bị lỗi',
        NETWORK_ERROR:
          'Kết nối mạng không thành công, vui lòng thử lại sau khi chuyển đổi mạng',
        RSP_DATA_NOT_JSON: 'Định dạng giá trị phản hồi không đúng',
        GEETEST_RESTRICTED:
          'Hoạt động thường xuyên, vui lòng thử lại sau {seconds} giây'
      },
      tips: { title: 'Mẹo' }
    }
  }
}
