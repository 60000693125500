import { GlobalService } from '@/context'
import { OsType } from '@/api/common/type'
import { useMainStore } from '@/store/index'
import { windowConfig } from '@/utils/window'

/**
 * @description 获取当前设备类型
 */
export const useDeviceType = () => {
  const { isWeb } = useMainStore()
  const userAgent = window.navigator.userAgent.toLowerCase()
  // let isAndroid = userAgent.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
  const isIOS = !!userAgent.match(/\(i[^;]+;( u;)? cpu.+mac os x/) //判断是否是 iOS终端

  // 1:iOS(极速包) 2:Android(极速包) 3:H5  4:Web
  let deviceType = isWeb
    ? +OsType.PC_Windows
    : isIOS
    ? +OsType.IOS_H5
    : +OsType.ANDROID_H5
  /** 判断极速包设备类型：android是2，ios是1 */
  const speedPackageConfigOSType = windowConfig.speedPackageConfig?.osType

  let deviceModel = GlobalService.getDeviceModel()

  if (windowConfig.speedPackageConfig && speedPackageConfigOSType) {
    deviceModel =
      speedPackageConfigOSType === +OsType.IOS_APP ? 'iOS' : 'Android'
    deviceType =
      speedPackageConfigOSType === +OsType.IOS_APP
        ? +OsType.IOS_APP
        : +OsType.ANDROID_APP
  }

  return {
    deviceModel,
    deviceType
  }
}
