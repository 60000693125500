import { AndroidBackupLinkType, IosBackupLinkType } from '@/views/channel/const'
import {
  ChannelStatusList,
  ChannelTypeList,
  UserDisplayList,
  ViewDownloadList
} from '@/views/channel/const'
import { Component, Mixins } from '@/vue-property-decorator'
import {
  GlobalEventActions,
  GlobalEventActionsTypeEnum
} from '@/context/events'
import { GlobalEvents } from '@/context'
import { KindType, PackKindType, androidDownloadType } from '@/api/common/type'
import { LoginOsType } from './const'
import {
  UrlsItem,
  useDownloadAppStore
} from '@/layouts/components/downloadApp/store'
import { sendPointerDomain, windowOpen } from '@/utils/business-utils'
import { useChannelStore } from '@/views/channel/store'
import { useMainStore } from '@/store/index'
import { useSaveShortcutStore } from '@/layouts/components/saveShortcut/store'
import { windowConfig } from '@/utils/window'
import BaseMixins from './mixin/index'
import isMobile from '@/utils/isMobile'
/**渠道配置类型为p2a那么就优先下载系统pwa然后自己的p2a */
@Component<DownlodAppByPWA2APP>({ name: 'DownlodAppByPWA2APP' })
export default class DownlodAppByPWA2APP extends Mixins(BaseMixins) {
  /**点击下载次数的计数 */
  private clickDownloadCount = 0
  /**是否已经点击过了下载按钮 */
  private hasClickDownload = false
  private handleDownload(action: GlobalEventActions) {
    if (action.type == GlobalEventActionsTypeEnum.PWATOAPP_INSTALL_BY_CHANNEL) {
      this.handleDownloadApp()
    }
  }

  created() {
    GlobalEvents.subscribe(this.handleDownload)
    const handleHasInstallPwaSuccess = () => {
      const { setHasInstallPwaSuccess } = useSaveShortcutStore()
      setHasInstallPwaSuccess(true)
      window.removeEventListener('appinstalled', handleHasInstallPwaSuccess)
    }
    window.addEventListener('appinstalled', handleHasInstallPwaSuccess)
  }

  beforeDestroy() {
    GlobalEvents.unsubscribe(this.handleDownload)
  }

  /**
   * @description 上报来自下载的数据
   */
  private reportDataFromDownload(packageItem: UrlsItem) {
    useDownloadAppStore().reportDownloadUrlClick(packageItem)
    let packKind = PackKindType.Normal
    if (packageItem.type === androidDownloadType.SPEED) {
      packKind = PackKindType.Quickly
    }
    sendPointerDomain(KindType.ClickDownLoad, packKind)
    this.clickDownloadCount++
  }

  /**下载安卓极包 */
  private downloadPackageByAndroid(packageItem: UrlsItem) {
    const { customizePath, downloadPath } = packageItem
    const downloadPathList = []
    if (downloadPath) {
      downloadPathList.push(downloadPath)
    }
    if (customizePath) {
      downloadPathList.push(customizePath)
    }
    /**
     * 循环使用下载地址
     * 如果配置了自定义，第一次使用系统默认，第二次使用自定义
     */
    const url =
      downloadPathList[this.clickDownloadCount % downloadPathList.length]
    windowOpen(url)
    this.reportDataFromDownload(packageItem)
  }

  /**下载iOS极速包 */
  private downloadPackageByIos(packageItem: UrlsItem) {
    const { value } = packageItem
    windowOpen(value)
    this.reportDataFromDownload(packageItem)
  }

  /**
   * @description 在安卓设备下下载app
   * @param urlslist 下载列表
   */
  private async downloadAppByAndroid() {
    const { channelInfo } = useChannelStore()
    if (!channelInfo) {
      return
    }
    const { androidBackupLink, androidBackupLinkType } = channelInfo
    if (androidBackupLinkType === AndroidBackupLinkType.Custom) {
      windowOpen(androidBackupLink)
      return
    }
    if (this.hasClickDownload) {
      return
    }
    this.hasClickDownload = true
    await useDownloadAppStore().getDownloadConfig()
    const { urlslist } = useDownloadAppStore()
    this.hasClickDownload = false
    if (!urlslist?.length) {
      return
    }
    const speedPackage = urlslist.find(
      (url) => url.type === androidDownloadType.SPEED
    )
    const normalPackage = urlslist.find(
      (url) => url.type === androidDownloadType.NORMAL
    )
    // 如果有极速包，那么直接走极速包
    if (speedPackage) {
      this.downloadPackageByAndroid(speedPackage)
      return
    }
    // 没有极速包，走常规包
    if (normalPackage) {
      this.downloadPackageByAndroid(normalPackage)
    }
  }

  /**
   * @description 在iOS设备下下载app
   * @param urlslist 下载列表
   */
  private downloadAppByiOS() {
    const { channelInfo } = useChannelStore()
    if (!channelInfo) {
      return
    }
    const { channelGlobalConfig } = useMainStore()
    if (!channelGlobalConfig) {
      return
    }
    const { iosBackupLinkType, iosBackupLink } = channelInfo
    if (iosBackupLinkType === IosBackupLinkType.Custom) {
      windowOpen(iosBackupLink)
      return
    } else {
      const { downloadList } = channelGlobalConfig
      if (!downloadList) {
        return
      }
      const speedPackage = downloadList.find(
        (url) => url.type === androidDownloadType.SPEED
      )
      const normalPackage = downloadList.find(
        (url) => url.type === androidDownloadType.NORMAL
      )
      // 如果有极速包，那么直接走极速包
      if (speedPackage) {
        this.downloadPackageByIos(speedPackage)
        return
      }
      // 没有极速包，走常规包
      if (normalPackage) {
        this.downloadPackageByIos(normalPackage)
      }
    }
  }

  /**是否展示下载APP弹出框来自渠道P2A */
  private static get showDownloadAppByChannelP2A() {
    const { channelInfo } = useChannelStore()

    const { isFirstDeposit } = useMainStore()
    if (!channelInfo) {
      return false
    }

    const { downloadDisplay, userDisplay, viewDownload } = channelInfo
    const { Open } = ChannelStatusList
    const { AllUser } = UserDisplayList
    const { APP } = ViewDownloadList
    const openPartialCondition =
      downloadDisplay === Open && viewDownload === APP

    /**
     * 满足以下三个条件
     * 展示状态是打开，
     * 展示下载内容是App
     * 展示的用户群体为全部
     */
    if (openPartialCondition && userDisplay === AllUser) {
      return true
    }

    /**
     * 满足以下三个条件
     * 展示状态是打开，
     * 展示下载内容是H5
     * 用户的充值次数大于或者大于1次（仅限已首充会员）
     */
    if (openPartialCondition && isFirstDeposit) {
      return true
    }

    return false
  }

  /**渠道类型是否为p2a类型 */
  private static get isChannelByP2a() {
    const { channelInfo } = useChannelStore()
    if (!channelInfo) {
      return false
    }
    const { type } = channelInfo
    if (ChannelTypeList.Pwa2App === type) {
      return true
    }
    return false
  }

  /**没有在APP内登录过 */
  private static get isNotLoginApp() {
    const { userInfos } = useMainStore()
    return userInfos?.loginOsType === LoginOsType.NotLoginApp
  }

  /**
   * 是否能够打开下载APP的提示通过p2a渠道配置
   * 规则只能够在马甲包和h5桌面能够打开此app
   *  */
  static get canOpenDownloadAppByP2A() {
    const { hasClickDownloadAPPByChannel } = useDownloadAppStore()
    return (
      !hasClickDownloadAPPByChannel &&
      this.isNotLoginApp &&
      this.isChannelByP2a &&
      !windowConfig.speedPackageConfig &&
      this.showDownloadAppByChannelP2A
    )
  }

  private async handleDownloadApp() {
    // 如果该浏览器支持直接安装pwa，那么走pwa, 如果本身就是在pwa里面，那么走下载极速包
    // 同时该运行环境不能够是马甲包，如果是马甲包，那么还是需要记载极速包
    // 如果已经安装成功了，那么也直接走急速包
    const isSupportInstallPrompt = !!useMainStore().installPrompt
    const { hasInstallPwaSuccess } = useSaveShortcutStore()
    if (
      isSupportInstallPrompt &&
      !windowConfig.isWgPackage &&
      !hasInstallPwaSuccess
    ) {
      await useMainStore().installPrompt?.prompt?.()
      return
    } else {
      if (isMobile().apple.device) {
        this.downloadAppByiOS()
      } else {
        this.downloadAppByAndroid()
      }
    }
  }

  render() {
    return <template></template>
  }
}
