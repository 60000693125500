import { defineStore } from 'pinia'

interface SaveShortcutState {
  /**是否安装h5桌面快捷方式成功 */
  hasInstallPwaSuccess: boolean
}
export const useSaveShortcutStore = defineStore('saveShortcut', {
  state: (): SaveShortcutState => ({
    hasInstallPwaSuccess: false
  }),
  actions: {
    setHasInstallPwaSuccess(hasInstallPwaSuccess: boolean) {
      this.hasInstallPwaSuccess = hasInstallPwaSuccess
    }
  }
})
