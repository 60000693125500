import { ConfigProvider, Empty } from 'ant-design-vue'
import { EmptyImageReport } from '@/components/business-components/empty-image'
import { getComponentFromProp } from 'ant-design-vue/lib/_util/props-util'
import { merge } from 'lodash'
import type { PluginObject } from 'vue'

const plugin: PluginObject<Record<string, unknown>> = {
  install() {
    merge(ConfigProvider, {
      methods: {
        renderEmptyComponent(
          _createElement: () => void,
          componentName:
            | 'Table'
            | 'List'
            | 'Select'
            | 'TreeSelect'
            | 'Cascader'
            | 'Transfer'
            | 'Mentions'
        ) {
          const renderEmpty =
            getComponentFromProp(this, 'renderEmpty', {}, false) ||
            (() => {
              if (componentName === 'Table' || componentName === 'List') {
                return <Empty image={<EmptyImageReport />} />
              }
              return (
                <Empty
                  imageStyle={{
                    display: 'none'
                  }}
                />
              )
            })

          return renderEmpty()
        }
      }
    })
  }
}
export default plugin
