import Vue, { PluginObject } from 'vue'

const namespace = 'disable-copy-parse'

const handler = (e: Event) => {
  e.preventDefault()
}

const Plugin: PluginObject<unknown> = {
  install: (_Vue): void => {
    _Vue.directive(namespace, {
      bind(el, binding) {
        Vue.nextTick(() => {
          if (binding.value === false) return
          el.addEventListener('paste', handler)
          el.addEventListener('copy', handler)
          el.addEventListener('cut', handler)
        })
      },

      unbind(el, binding) {
        if (binding.value === false) return
        el.removeEventListener('paste', handler)
        el.removeEventListener('copy', handler)
        el.removeEventListener('cut', handler)
      }
    })
  }
}

export default Plugin
