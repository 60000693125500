import isMobile from './isMobile'
export default class Validator {
  static isEmail(context: unknown): boolean {
    if (typeof context === 'string') {
      return /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(context)
    }

    return false
  }

  static isEmpty(context: unknown): boolean {
    return ['', undefined, null].includes(context as string | null | undefined)
  }

  static isMobile = isMobile
}
