export default {
  name: 'down',
  theme: 'outline',
  icon: {
    tag: 'svg',
    attrs: {
      xmlns: 'http://www.w3.org/2000/svg',
      width: 35.969,
      height: 21.998,
      viewBox: '0 0 35.969 21.998',
      focusable: false
    },
    children: [
      {
        tag: 'path',
        attrs: {
          id: 'comm_icon_fh',
          d: 'M2209.28,137.564l-17.743-15.773a2,2,0,0,1-.271-3.058,2.037,2.037,0,0,1,.274-.23l17.74-15.77a1.992,1.992,0,0,1,2.817,2.816l-16.422,14.6,16.422,14.6a1.992,1.992,0,1,1-2.817,2.817Z',
          transform: 'translate(-102.181 2212.68) rotate(-90)'
        },
        children: []
      }
    ]
  }
}
