import { Entity, Model, from, parse, type } from 'typox'
import FavoriteItem from './favoriteItem'

@Entity()
export default class _FavoriteInfo extends Model {
  constructor(source?: unknown) {
    super()
    this.merge(source)
  }

  @from('total')
  @type(Number)
  total = 0

  @from('favoriteList')
  @parse((v) => (v || []).map((x: unknown) => new FavoriteItem().parse(x)))
  @type(Array)
  rows: FavoriteItem[] = []

  isEmpty(): boolean {
    return this.rows.length > 0
  }
}
