function test(url: string): Promise<Record<string, unknown>> {
  const start = Date.now()
  const conn = navigator.connection as unknown as {
    downlink: string
    effectiveType: string
  }
  const res = {
    status: -1,
    type: '',
    time: -1,
    length: '-1',
    downlink: conn?.downlink,
    net: conn?.effectiveType
  }
  return fetch(url)
    .then((e) => {
      res.type = e.headers.get('content-type') as unknown as string
      res.length = e.headers.get('content-length') as unknown as string
      res.time = Date.now() - start
      res.status = e.status
    })
    .catch(() => {
      res.time = Date.now() - start
    })
    .then(() => res)
}

export interface Option {
  image?: string[]
}

export default function (ary: string[]): Promise<Record<string, unknown>> {
  return new Promise((resolve) => {
    const res = {} as Record<string, unknown>

    const next = (urls: string[]) => {
      if (urls.length === 0) {
        resolve(res)
      } else {
        const url = urls.shift() as string
        test(url)
          .then((e) => {
            res[url] = e
          })
          .finally(() => {
            next(urls)
          })
      }
    }

    next(ary.map((v) => v))
  })
}
