export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'System Error',
        NETWORK_ERROR:
          'The network connection failed, please try again after switching the network',
        RSP_DATA_NOT_JSON: 'Response value format error',
        GEETEST_RESTRICTED:
          'Operate Frequently, Please Retry in {seconds} seconds'
      },
      tips: { title: 'Reminder' }
    }
  }
}
