import { CombineParams, GameType } from './pictureUrlCore'
import {
  LayoutDesignGameIconEnum,
  LayoutDesignPlatformIconEnum
} from '../../../../../api/common/type'

/** 取得图标形状 全局配置 layoutDesign 中可以拿到游戏卡片和平台卡片的类型代号,在游戏资源的路径中,就有这个类型代号的参数 */
export class PatternCode {
  /** 布局设计与形状代号映射(平台)  */
  private layoutAddressPlatform = {
    [LayoutDesignPlatformIconEnum.EuropeanAmerican]: 'EA',
    [LayoutDesignPlatformIconEnum.LeftRight1]: 'L1',
    [LayoutDesignPlatformIconEnum.LeftRight2]: 'L2',
    [LayoutDesignPlatformIconEnum.Rounded]: 'RD',
    [LayoutDesignPlatformIconEnum.Square]: 'SQ',
    [LayoutDesignPlatformIconEnum.Rectangle]: 'RT'
  }
  /** 获得平台形状代号 */
  private getCodePlatform() {
    // 这个是提前注入的值,必须保证当它执行到此处时有值,无值的话就会出问题
    const platKey = window.LOBBY_SITE_CONFIG.INJECT_DATA!
      .apiGetOptimizationSiteConfig.data.data.layoutDesign
      .platformIcon as LayoutDesignPlatformIconEnum
    return this.layoutAddressPlatform[platKey]
  }

  /** 布局设计与形状代号映射(游戏)  */
  private layoutAddressGame = {
    [LayoutDesignGameIconEnum.VerticalMask]: 'EA',
    [LayoutDesignGameIconEnum.Square]: 'CL',
    [LayoutDesignGameIconEnum.Vertical]: 'VR',
    [LayoutDesignGameIconEnum.SquareRightAngle]: 'SQ',
    [LayoutDesignGameIconEnum.Rounded]: 'RD',
    [LayoutDesignGameIconEnum.Horizontal]: 'HZ'
  }

  /** 获得游戏形状代号 */
  private getCodeGame() {
    // 这个是提前注入的值,必须保证当它执行到此处时有值,无值的话就会出问题
    const gameKey = window.LOBBY_SITE_CONFIG.INJECT_DATA!
      .apiGetOptimizationSiteConfig.data.data.layoutDesign
      .appGameIcon as LayoutDesignGameIconEnum
    return this.layoutAddressGame[gameKey]
  }

  /** 获得形状代号信息 */
  public run(params: CombineParams) {
    const { gameType, isHotListPlatform } = params
    // 如果它是子游戏,或者它是热门下的平台游戏入口,则走子游戏的图形逻辑
    if (gameType === GameType.Individual || isHotListPlatform) {
      return this.getCodeGame()
    }
    // 非热门下的平台,才走平台图形的逻辑
    else {
      return this.getCodePlatform()
    }
  }
}
