/**
 * 独立出来是因为 该方法不依赖 store
 * ant-design 重写input需要引入，但那时候不能引入 store 故单独提取，此文件中不能添加 引入 引入了store的方法
 */
import { SvgSprite } from '@/components/icon-sprite'

export function createSvgSprite(
  key: string,
  options?: Partial<SvgSprite>,
  context: 'svgSprite:all' | 'svgSprite:festival' = 'svgSprite:all',
  nameContext: () => string = () => 'sprite.svg'
): SvgSprite {
  const sprite = window[context]?.[key] || {}
  const urlTargetMap = {
    'svgSprite:all': '/lobby_asset/{layout}-{bg}-{skin}',
    'svgSprite:festival': '/lobby_asset/festival/{holiday}/{layout}-{bg}'
  }
  let url = sprite.url || `/${nameContext()}#${key}`
  url = `${urlTargetMap[context]}${url}`
  return {
    viewBox: sprite?.viewBox || '0 0 20 20',
    url,
    id: `#${key}--${context}`,
    ...options
  }
}
