export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'Sistem bermasalah',
        NETWORK_ERROR:
          'Koneksi jaringan gagal, silakan coba lagi setelah beralih jaringan',
        RSP_DATA_NOT_JSON: 'Nomor Telepon Telah Terdaftar',
        GEETEST_RESTRICTED:
          'Operasi yang sering, silakan coba lagi dalam {seconds} detik'
      },
      tips: { title: 'PERINGATAN' }
    }
  }
}
