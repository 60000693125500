import { defaultUrlQueryParams } from '@/context/const'

/**
 * 禁用opener的window.open方法调用
 * @param url 要打开的网页地址
 * @param target
 * @returns Window | null
 */
export function open(url: string, target?: string): Window | null {
  const win = window.open(url, target)
  if (win) {
    win.opener = null
  }
  return win
}

/**
 * 是否是马甲包
 * @deprecated 用windowConfig.isWgPackage 代替
 */
export const isWgPackage =
  !!(
    window.WgPackage ||
    window.jsBridge ||
    window.PlatNative ||
    window.Android ||
    window.uni
  ) ||
  defaultUrlQueryParams['isWgPackage'] === 'true' ||
  !!defaultUrlQueryParams['WgPackage']

/**
 * mock 极速包
 */
// window.SpeedPackaeInit = {
//   isSpeedPackae: true,
//   pushId: '1234',
//   pkgVersion: 'DD',
//   osType: 1
// }

/**
 * 因cocos马甲包注入的变量并不能保证开始的时候就注入到window上
 * 所以业务的逻辑必须从window上取，如果提前将状态存储，则需要改成get
 *
 * 此处如果有业务开始就需要用到注入的变量判断，则需要 cocos想办法更早时机注入，保证我们js运行时刚跑起来时
 * window上就能注入WgPackage、SpeedPackaeInit变量
 */
export const windowConfig = {
  get wgPackage() {
    try {
      /**
       * 优先用jsBridge.getWGConfigInfo方法获取初始配置
       */
      if (window.jsBridge.getWGConfigInfo) {
        window.WgPackage = JSON.parse(
          window.jsBridge.getWGConfigInfo()
        ).WgPackage
      } else if (defaultUrlQueryParams['WgPackage']) {
        window.WgPackage = JSON.parse(
          decodeURIComponent(
            window.atob(String(defaultUrlQueryParams['WgPackage']))
          )
        )
      }
    } catch (error) {}

    return window.WgPackage ? window.WgPackage : undefined
  },

  /**
   * 是否为马甲包,极速包也是马甲包的一种，故区分极速包和马甲包要优先判断时候有极速包特征
   */
  get isWgPackage() {
    if (!window.WgPackage && defaultUrlQueryParams['WgPackage']) {
      try {
        window.WgPackage = JSON.parse(
          decodeURIComponent(
            window.atob(String(defaultUrlQueryParams['WgPackage']))
          )
        )
      } catch (error) {}
    }
    return (
      !!(
        window.WgPackage ||
        window.jsBridge ||
        window.PlatNative ||
        window.Android ||
        window.uni
      ) ||
      defaultUrlQueryParams['isWgPackage'] === 'true' ||
      !!defaultUrlQueryParams['WgPackage']
    )
  },
  /**
   * 极速包（特殊的马甲包，支持三方登录、推送通知等）
   */
  get speedPackageConfig() {
    try {
      /**
       * 优先用jsBridge上自定义的getWGConfigInfo方法获取初始配置
       */
      if (window.jsBridge.getWGConfigInfo) {
        window.SpeedPackaeInit = JSON.parse(
          window.jsBridge.getWGConfigInfo()
        ).SpeedPackaeInit
      } else if (defaultUrlQueryParams['SpeedPackaeInit']) {
        window.SpeedPackaeInit = JSON.parse(
          decodeURIComponent(
            window.atob(String(defaultUrlQueryParams['SpeedPackaeInit']))
          )
        )
      }
    } catch (error) {}

    return window.SpeedPackaeInit?.isSpeedPackae
      ? window.SpeedPackaeInit
      : undefined
  },
  /**
   * 明确马甲包和极速包环境
   */
  get package() {
    return {
      isApp: this.isWgPackage,
      isVest: this.isWgPackage && !this.speedPackageConfig,
      isLite: this.isWgPackage && this.speedPackageConfig?.isSpeedPackae
    }
  }
}
