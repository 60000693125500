import { MomentLanguageMap } from './config'
import {
  createIndexDBMessageFactory,
  createMomentLocaleFactory,
  createServerMessageFactory
} from './utils'
import GlobalConst from '@/context/const'
import type { Language, Messages } from './'
export const antDesignMessages: Messages = {
  en_US: () =>
    import(
      /* webpackChunkName: "site-i18n-config@en_US" */
      '@/plugins/ant-design-vue/locale/en-US'
    ),
  zh_CN: () =>
    import(
      /* webpackChunkName: "site-i18n-config@zh_CN" */
      '@/plugins/ant-design-vue/locale/zh-CN'
    ),
  zh_TW: () =>
    import(
      /* webpackChunkName: "site-i18n-config@zh_TW" */
      '@/plugins/ant-design-vue/locale/zh-TW'
    ),
  th_TH: () =>
    import(
      /* webpackChunkName: "site-i18n-config@th_TH" */
      '@/plugins/ant-design-vue/locale/th-TH'
    ),
  vi_VN: () =>
    import(
      /* webpackChunkName: "site-i18n-config@vi_VN" */
      '@/plugins/ant-design-vue/locale/vi-VN'
    ),
  ko_KR: () =>
    import(
      /* webpackChunkName: "site-i18n-config@ko_KR" */
      '@/plugins/ant-design-vue/locale/ko-KR'
    ),
  ja_JP: () =>
    import(
      /* webpackChunkName: "site-i18n-config@ja_JP" */
      '@/plugins/ant-design-vue/locale/ja-JP'
    ),
  pt_PT: () =>
    import(
      /* webpackChunkName: "site-i18n-config@pt_PT" */
      '@/plugins/ant-design-vue/locale/pt-PT'
    ),
  es_ES: () =>
    import(
      /* webpackChunkName: "site-i18n-config@es_ES" */
      '@/plugins/ant-design-vue/locale/es-ES'
    ),
  de_DE: () =>
    import(
      /* webpackChunkName: "site-i18n-config@de_DE" */
      '@/plugins/ant-design-vue/locale/de-DE'
    ),
  fr_FR: () =>
    import(
      /* webpackChunkName: "site-i18n-config@fr_FR" */
      '@/plugins/ant-design-vue/locale/fr-FR'
    ),
  it_IT: () =>
    import(
      /* webpackChunkName: "site-i18n-config@it_IT" */
      '@/plugins/ant-design-vue/locale/it-IT'
    ),
  ru_RU: () =>
    import(
      /* webpackChunkName: "site-i18n-config@ru_RU" */
      '@/plugins/ant-design-vue/locale/ru-RU'
    ),
  my_MM: () =>
    import(
      /* webpackChunkName: "site-i18n-config@my_MM" */
      '@/plugins/ant-design-vue/locale/my-MM'
    ),
  id_ID: () =>
    import(
      /* webpackChunkName: "site-i18n-config@id_ID" */
      '@/plugins/ant-design-vue/locale/id-ID'
    ),
  hi_IN: () =>
    import(
      /* webpackChunkName: "site-i18n-config@hi_IN" */
      '@/plugins/ant-design-vue/locale/hi-IN'
    ),
  /**
   * 暂时先使用英文
   */
  tl_PH: () =>
    import(
      /* webpackChunkName: "site-i18n-config@tl_PH" */
      '@/plugins/ant-design-vue/locale/tl-PH'
    ),
  km_KH: () =>
    import(
      /* webpackChunkName: "site-i18n-config@km_KH" */
      '@/plugins/ant-design-vue/locale/km-KH'
    ),
  te_IN: () =>
    import(
      /* webpackChunkName: "site-i18n-config@te_IN" */
      '@/plugins/ant-design-vue/locale/te-IN'
    ),
  ta_LK: () =>
    import(
      /* webpackChunkName: "site-i18n-config@ta_LK" */
      '@/plugins/ant-design-vue/locale/ta-LK'
    ),
  mr_IN: () =>
    import(
      /* webpackChunkName: "site-i18n-config@mr_IN" */
      '@/plugins/ant-design-vue/locale/mr-IN'
    ),
  kn_IN: () =>
    import(
      /* webpackChunkName: "site-i18n-config@kn_IN" */
      '@/plugins/ant-design-vue/locale/kn-IN'
    ),
  tr_TR: () =>
    import(
      /* webpackChunkName: "site-i18n-config@tr_TR" */
      '@/plugins/ant-design-vue/locale/tr_TR'
    ),
  bn_BN: () =>
    import(
      /* webpackChunkName: "site-i18n-config:bn_BN" */
      '@/plugins/ant-design-vue/locale/bn-BN'
    ),
  pa_PA: () =>
    import(
      /* webpackChunkName: "site-i18n-config:pa_PA" */
      '@/plugins/ant-design-vue/locale/pa-PA'
    )
}

export const momentLocale = {
  /**
   * moment 内部默认是英文，所以英文资源不必下载
   */
  en_US: () => async () => {
    /** */
  },
  ...Object.entries(MomentLanguageMap).reduce((pre, [lan, momentLan]) => {
    pre[lan as Exclude<Language, 'en_US'>] =
      createMomentLocaleFactory(momentLan)
    return pre
  }, {} as Record<Exclude<Language, 'en_US'>, ReturnType<typeof createMomentLocaleFactory>>)
}

/**
 * 基础语言包不异步
 */
export const baseMessages = {
  en_US: require('@/i18n/base/langs/en-US').default,
  zh_TW: require('@/i18n/base/langs/zh-TW').default,
  zh_CN: require('@/i18n/base/langs/zh-CN').default,
  th_TH: require('@/i18n/base/langs/th-TH').default,
  vi_VN: require('@/i18n/base/langs/vi-VN').default,
  ko_KR: require('@/i18n/base/langs/ko-KR').default,
  ja_JP: require('@/i18n/base/langs/ja-JP').default,
  pt_PT: require('@/i18n/base/langs/pt-PT').default,
  es_ES: require('@/i18n/base/langs/es-ES').default,
  de_DE: require('@/i18n/base/langs/de-DE').default,
  fr_FR: require('@/i18n/base/langs/fr-FR').default,
  it_IT: require('@/i18n/base/langs/it-IT').default,
  ru_RU: require('@/i18n/base/langs/ru-RU').default,
  my_MM: require('@/i18n/base/langs/my-MM').default,
  id_ID: require('@/i18n/base/langs/id-ID').default,
  hi_IN: require('@/i18n/base/langs/hi-IN').default,
  tl_PH: require('@/i18n/base/langs/tl-PH').default,
  km_KH: require('@/i18n/base/langs/km-KH').default,
  te_IN: require('@/i18n/base/langs/te-IN').default,
  ta_LK: require('@/i18n/base/langs/ta-LK').default,
  mr_IN: require('@/i18n/base/langs/mr-IN').default,
  kn_IN: require('@/i18n/base/langs/kn-IN').default,
  tr_TR: require('@/i18n/base/langs/tr_TR').default,
  bn_BN: require('@/i18n/base/langs/bn_BN').default,
  pa_PA: require('@/i18n/base/langs/pa_PA').default
}

export const lobbyMessages = {
  en_US: () =>
    import(
      /* webpackChunkName: "site-i18n-config@en_US" */
      '@/i18n/langs/en-US'
    ),
  zh_TW: () =>
    import(
      /* webpackChunkName: "site-i18n-config@zh_TW" */
      '@/i18n/langs/zh-TW'
    ),
  zh_CN: () =>
    import(
      /* webpackChunkName: "site-i18n-config@zh_CN" */
      '@/i18n/langs/zh-CN'
    ),
  th_TH: () =>
    import(
      /* webpackChunkName: "site-i18n-config@th_TH" */
      '@/i18n/langs/th-TH'
    ),
  vi_VN: () =>
    import(
      /* webpackChunkName: "site-i18n-config@vi_VN" */
      '@/i18n/langs/vi-VN'
    ),
  ko_KR: () =>
    import(
      /* webpackChunkName: "site-i18n-config@ko_KR" */
      '@/i18n/langs/ko-KR'
    ),
  ja_JP: () =>
    import(
      /* webpackChunkName: "site-i18n-config@ja_JP" */
      '@/i18n/langs/ja-JP'
    ),
  pt_PT: () =>
    import(
      /* webpackChunkName: "site-i18n-config@pt_PT" */
      '@/i18n/langs/pt-PT'
    ),
  es_ES: () =>
    import(
      /* webpackChunkName: "site-i18n-config@es_ES" */
      '@/i18n/langs/es-ES'
    ),
  de_DE: () =>
    import(
      /* webpackChunkName: "site-i18n-config@de_DE" */
      '@/i18n/langs/de-DE'
    ),
  fr_FR: () =>
    import(
      /* webpackChunkName: "site-i18n-config@fr_FR" */
      '@/i18n/langs/fr-FR'
    ),
  it_IT: () =>
    import(
      /* webpackChunkName: "site-i18n-config@it_IT" */
      '@/i18n/langs/it-IT'
    ),
  ru_RU: () =>
    import(
      /* webpackChunkName: "site-i18n-config@ru_RU" */
      '@/i18n/langs/ru-RU'
    ),
  my_MM: () =>
    import(
      /* webpackChunkName: "site-i18n-config@my_MM" */
      '@/i18n/langs/my-MM'
    ),
  id_ID: () =>
    import(
      /* webpackChunkName: "site-i18n-config@id_ID" */
      '@/i18n/langs/id-ID'
    ),
  hi_IN: () =>
    import(
      /* webpackChunkName: "site-i18n-config@hi_IN" */
      '@/i18n/langs/hi-IN'
    ),
  tl_PH: () =>
    import(
      /* webpackChunkName: "site-i18n-config@tl_PH" */
      '@/i18n/langs/tl-PH'
    ),
  km_KH: () =>
    import(
      /* webpackChunkName: "site-i18n-config@km_KH" */
      '@/i18n/langs/km-KH'
    ),

  ta_LK: () =>
    import(
      /* webpackChunkName: "site-i18n-config@ta_LK" */
      '@/i18n/langs/ta-LK'
    ),
  te_IN: () =>
    import(
      /* webpackChunkName: "site-i18n-config@te_IN" */
      '@/i18n/langs/te-IN'
    ),
  mr_IN: () =>
    import(
      /* webpackChunkName: "site-i18n-config@mr_IN" */
      '@/i18n/langs/mr-IN'
    ),
  kn_IN: () =>
    import(
      /* webpackChunkName: "site-i18n-config@kn_IN" */
      '@/i18n/langs/kn-IN'
    ),
  tr_TR: () =>
    import(
      /* webpackChunkName: "site-i18n-config@tr_TR" */
      '@/i18n/langs/tr_TR'
    ),
  bn_BN: () =>
    import(
      /* webpackChunkName: "site-i18n-config@bn_BN" */
      '@/i18n/langs/bn_BN'
    ),
  pa_PA: () =>
    import(
      /* webpackChunkName: "site-i18n-config@pa_PA" */
      '@/i18n/langs/pa_PA'
    )
}
export const createLobbyMessagesWithIndexDB = (storage: LocalForage) => ({
  ...Object.keys(GlobalConst.ServiceLanguageMap).reduce((pre, lan) => {
    pre[lan as Language] = createIndexDBMessageFactory(lan as Language, storage)
    return pre
  }, {} as Record<Language, ReturnType<typeof createIndexDBMessageFactory>>)
})

export const lobbyMessageWithServer = {
  ...Object.keys(GlobalConst.ServiceLanguageMap).reduce((pre, lan) => {
    pre[lan as Language] = createServerMessageFactory(lan as Language)
    return pre
  }, {} as Record<Language, ReturnType<typeof createServerMessageFactory>>)
} as Messages
