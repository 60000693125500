import { Base, Component } from '@/vue-property-decorator'
import { BrandLogoType } from '@/api/common/type'
import { GameNavigation } from '@/views/game/classes'
import { GlobalConst } from '@/context'
import { useMainStore } from '@/store/index'
import Skin from '@/controller/Skin'
import style from './style.module.scss'
@Component<LogoBox>({
  name: 'LogoBox'
})
export default class LogoBox extends Base<unknown> {
  private get isCustomMode() {
    const { brandLogoInfos } = useMainStore()
    return brandLogoInfos?.type === BrandLogoType.CUSTOM
  }

  render() {
    const { brandLogoInfos } = useMainStore()
    const { isStake } = Skin.currentTheme()
    if (!brandLogoInfos || brandLogoInfos._isFromService) {
      return <div />
    }
    const { isWeb } = useMainStore()
    if (this.isCustomMode && brandLogoInfos.hallLogo) {
      return (
        <my-img
          nativeOnClick={() => GameNavigation.goHomePage()}
          class={[style.customLogo, 'customLogo']}
          src={brandLogoInfos.hallLogo}
          // width={isWeb ? 330 : 415}
          // height={isWeb ? 74 : 76}
          options={{
            lazy: false,
            immediate: true
          }}
        />
      )
    }
    return (
      <div class={style.imgText} onClick={() => GameNavigation.goHomePage()}>
        {brandLogoInfos.hallLogo && (
          <my-img
            width={isWeb ? (isStake ? 38 : 56) : 74}
            height={isWeb ? (isStake ? 38 : 56) : 74}
            src={brandLogoInfos.hallLogo}
            useSkeleton
            options={{
              lazy: false,
              immediate: true
            }}
          />
        )}
        <div>
          <span
            title={brandLogoInfos.brandName}
            class={[
              style.imgTitle,
              GlobalConst.CssClassName.Lobby.Ellipsis,
              'logoImgTitle'
            ]}
          >
            {brandLogoInfos.brandName}
          </span>
          <p
            title={brandLogoInfos.officialWebsite}
            class={[
              style.imgContent,
              GlobalConst.CssClassName.Lobby.Ellipsis,
              'logoImgTitle'
            ]}
          >
            {brandLogoInfos.officialWebsite}
          </p>
        </div>
      </div>
    )
  }
}
