export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: '시스템 오류',
        NETWORK_ERROR:
          '네트워크 연결이 실패했습니다. 네트워크를 전환 한 후 다시 시도하십시오.',
        RSP_DATA_NOT_JSON: '응답값 형식이 부정확합니다',
        GEETEST_RESTRICTED:
          '빈번한 작업입니다. {seconds}초 후에 다시 시도하십시오.'
      },
      tips: { title: '팁' }
    }
  }
}
