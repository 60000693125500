import { RouteName } from './config'
import { delay } from '@/utils/Tool'
import Router, { Route, RouterOptions } from 'vue-router'
const positionStore = new Map()
export type ScrollType =
  // 回次回顶部
  | 'top'
  // 不记忆滚动位置
  | 'none'
  // 记忆页面上次离开的滚动位置
  | 'auto'
  | (() => HTMLElement | Element | null | void | { x: number; y: number })

let scrollType: ScrollType = 'none'

export const markScrollType = (type: ScrollType) => {
  scrollType = type
}

function getScrollContainer() {
  return document.querySelector(`#home-scroll-box .my-scrollbar-wrap`)
}

function saveScrollPosition(route: Route) {
  const scrollSelector = getScrollContainer()
  if (route && scrollSelector) {
    positionStore.set(route.name, {
      x: scrollSelector.scrollLeft,
      y: scrollSelector.scrollTop,
      time: Date.now(),
      name: route.name
    })
  }
}

function getScrollPosition(route: Route) {
  return positionStore.get(route.name)
}

export const scrollBehavior: RouterOptions['scrollBehavior'] = async function (
  to,
  from
) {
  let scrollTypeBack = scrollType
  // 首页需要保持滚动状态
  if (
    [RouteName.GAME].includes(to.name as RouteName) &&
    to.name !== from.name
  ) {
    markScrollType('auto')
    scrollTypeBack = 'auto'
  }
  /**
   * none 模式不做处理
   */
  if (scrollType !== 'none') {
    await delay(100)
    const scrollByOffest = (offset: { x: number; y: number }) => {
      if (offset) {
        const scrollSelector = getScrollContainer()
        if (scrollSelector) {
          scrollSelector.scrollTop = offset.y
          scrollSelector.scrollLeft = offset.x
        }
      }
    }
    /**
     * auto 模式用记忆的离开时的滚动位置
     */
    if (scrollTypeBack === 'auto') {
      const offset = getScrollPosition(to)
      scrollByOffest(offset)
    }
    /**
     * 函数模式时：
     * 1.return 的是html节点，直接调用scrollIntoView()方法
     * 2.return 的是offset偏移，则滚动到对应坐标
     */
    if (typeof scrollTypeBack === 'function') {
      try {
        const result = scrollTypeBack()
        if (result instanceof HTMLElement || result instanceof Element) {
          result.scrollIntoView()
        } else if (result) {
          const { x, y } = result
          scrollByOffest({ x, y })
        }
      } catch (error) {}
    }

    if (scrollTypeBack === 'top') {
      scrollByOffest({ x: 0, y: 0 })
    }

    markScrollType('none')
  }
}

export function setupScroll(router: Router) {
  router.beforeEach((to, from, next) => {
    try {
      saveScrollPosition(from)
    } catch (error) {}
    next()
  })
}
