/* eslint-disable @typescript-eslint/no-explicit-any*/
import { withSuffix } from '@ant-design/icons-vue/es/utils'
import VueIcon from '@ant-design/icons-vue'
import classNames from 'classnames'

import {
  alias,
  removeTypeTheme,
  withThemeSuffix
} from 'ant-design-vue/lib/icon/utils'

import { default as CloseCircleFill } from './lib/fill/CloseCircleFill'
import { default as CloseOutline } from './lib/outline/CloseOutline'
import { default as DownOutline } from './lib/outline/DownOutline'

import { merge } from 'lodash'

const icons = Object.values({ CloseOutline, DownOutline, CloseCircleFill })
/**
 * 未外置时调用此句即可重写
 */
VueIcon.add(...icons)

/**
 * 外置后需重写 Icon Render
 */
if ((window as any).antd) {
  const needRewriteIconsType = icons.reduce((pre, icon) => {
    pre[withSuffix(icon.name, icon.theme)] = icon
    return pre
  }, {} as Record<string, unknown>)
  const iconRender = (window as any).antd.Icon.render
  const needRewrite = (context: any, type: string) => {
    if (typeof type === 'string') {
      const computedType = withThemeSuffix(
        removeTypeTheme(alias(type)),
        context.$props.theme || 'outlined'
      )
      return Object.keys(needRewriteIconsType).includes(computedType)
        ? needRewriteIconsType[computedType]
        : false
    }
    return false
  }

  merge((window as any).antd.Icon, {
    render(this: any, ...args: []) {
      const result = needRewrite(this, this.$props.type)
      if (result) {
        const { type, spin, rotate, twoToneColor, tabIndex } = this.$props
        const listeners =
          (this.$vnode
            ? this.$vnode.componentOptions.listeners
            : this.$listeners) || {}
        const classString = classNames({
          [`anticon`]: true,
          [`anticon-${type}`]: !!type
        })
        const svgClassString = classNames({
          [`anticon-spin`]: !!spin || type === 'loading'
        })
        const svgStyle = rotate
          ? {
              msTransform: `rotate(${rotate}deg)`,
              transform: `rotate(${rotate}deg)`
            }
          : undefined
        let iconTabIndex = tabIndex
        if (iconTabIndex === undefined && 'click' in listeners) {
          iconTabIndex = -1
        }

        const iProps = {
          attrs: {
            'aria-label': type && `${type}`,
            tabIndex: iconTabIndex
          },
          on: listeners,
          class: classString,
          staticClass: ''
        }
        return (
          <i {...iProps}>
            <VueIcon
              focusable="false"
              class={svgClassString}
              type={result}
              primaryColor={twoToneColor}
              style={svgStyle}
            />
          </i>
        )
      }
      return iconRender.call(this, ...args)
    }
  })
}
