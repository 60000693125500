export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'Системная ошибка',
        NETWORK_ERROR:
          'Сетевое соединение не удалось, попробуйте еще раз после переключения сети',
        RSP_DATA_NOT_JSON: 'Неверный формат отклика',
        GEETEST_RESTRICTED:
          'Используйте часто. Повторите попытку через {секунды} секунд.'
      },
      tips: { title: 'Советы' }
    }
  }
}
