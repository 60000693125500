import { merge } from 'lodash'
import xss, { escapeAttrValue, getDefaultWhiteList } from 'xss'

/**
 * 注入默认规则的xss调用
 * @param {string} html 原html字符串
 * @param {object} options
 * @see {https://github.com/leizongmin/js-xss}
 * @returns
 */

export function xssFilter(html: string, options: object = {}) {
  const whiteList = merge({}, getDefaultWhiteList(), {})
  options = merge(
    {
      whiteList,
      onIgnoreTagAttr(tag: string, name: string, value: string) {
        const rules = [
          name.substr(0, 5) === 'data-', //允许标签以 data-开头的属性
          ['style', 'class', 'id'].includes(name) //允许style和class和id属性
        ]

        if (rules.includes(true)) {
          // 通过内置的escapeAttrValue函数来对属性值进行转义
          return name + '="' + escapeAttrValue(value) + '"'
        }
      },
      onIgnoreTag(tag: string, html_: string) {
        const rules = [['iframe'].includes(tag)]
        if (rules[0] && html_.includes(`data:text/html;`)) {
          return html_
        }
      }
    },

    options
  )
  return xss(html, options)
}
