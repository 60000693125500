import { Base, Component, Prop } from '@/vue-property-decorator'
import { GlobalConst } from '@/context'
import { useMainStore } from '@/store/index'
import AppTypes from '@/vue-types'
import classnames from 'classnames'
import type { RouterViewWithTransitionMode } from '@/store/index/type'

export interface Props {
  transitionMode?: () => RouterViewWithTransitionMode
}
@Component<BaseTransition>({
  name: 'BaseTransition'
})
export default class BaseTransition extends Base<unknown, Props> {
  @Prop(
    AppTypes.func.def(() => {
      return useMainStore().routerViewWithTransitionMode
    })
  )
  private readonly transitionMode!: NonNullable<Props['transitionMode']>
  get animateProps() {
    return {
      None: {},
      Base: {
        enterActiveClass: classnames([
          GlobalConst.CssClassName.Animate.Animated,
          GlobalConst.CssClassName.Animate.FadeIn
        ]),
        leaveActiveClass: classnames([
          GlobalConst.CssClassName.Animate.WithoutNormalFlow,
          GlobalConst.CssClassName.Animate.Animated,
          GlobalConst.CssClassName.Animate.FadeOut
        ])
      },
      Push: {
        enterActiveClass: classnames([
          GlobalConst.CssClassName.Animate.Animated,
          GlobalConst.CssClassName.Animate.PushEnter
        ]),
        leaveActiveClass: classnames([
          GlobalConst.CssClassName.Animate.Animated,
          GlobalConst.CssClassName.Animate.PushLeave
        ])
      },
      Pop: {
        enterActiveClass: classnames([
          GlobalConst.CssClassName.Animate.Animated,
          GlobalConst.CssClassName.Animate.PopEnter
        ]),
        leaveActiveClass: classnames([
          GlobalConst.CssClassName.Animate.Animated,
          GlobalConst.CssClassName.Animate.PopLeave
        ])
      }
    }[
      (() => {
        if (window.wgMock?.animationMode === 'fade') {
          return 'Base'
        } else if (window.wgMock?.animationMode === 'none') {
          return 'None'
        }
        return this.transitionMode()
      })()
    ]
  }

  render() {
    return (
      <transition
        {...{
          props: this.animateProps
        }}
      >
        {this.$slots.default}
      </transition>
    )
  }
}
