export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'सिस्टम में गड़बड़ी , तंत्र त्रुटि',
        NETWORK_ERROR:
          'नेटवर्क कनेक्शन विफल हो गया, कृपया नेटवर्क को स्विच करने के बाद पुनः प्रयास करें',
        RSP_DATA_NOT_JSON: 'प्रतिक्रिया वैल्यू फ़ॉर्मेट ग़लत है',
        GEETEST_RESTRICTED:
          'बारंबार संचालन, कृपया{seconds} सेकंड में पुन: प्रयास करें'
      },
      tips: { title: 'टिप्स' }
    }
  }
}
