import { TabKey } from '@/layouts/components/tabBar/types'
import { useMainStore } from '@/store/index'
import { useTabBarStore } from '@/layouts/components/tabBar/store/index'

/**
 * 当前底部tabbar激活高亮状态
 */
export const useBottomTabbarActive = (key?: string, tabKey?: TabKey) => {
  if (useTabBarStore().isSeperateTabSkin) {
    return false
  }
  const { isWeb, hasLogined, homeLayoutInfos } = useMainStore()

  if (isWeb) {
    return
  }

  const { updateTabItem } = useTabBarStore()
  const bottomLayouts = hasLogined
    ? homeLayoutInfos?.loginAfterBottomLayouts
    : homeLayoutInfos?.loginBeforeBottomLayouts

  const currentItem = key && bottomLayouts?.find((item) => item.name === key)

  if (currentItem && tabKey) {
    updateTabItem(tabKey)
  } else {
    // 优惠中心
    updateTabItem(TabKey.EVENT)
  }
}
