/**
 * @description 用户是否登录过APP或者h5桌面快捷方式
 * */
export const LoginOsType = {
  /**0 没有登录过极速包和原生APP  */
  NotLoginApp: 0,
  /**1 APP 登录过极速包和原生APP  */
  LoginApp: 1,
  /**2 H5快捷方式登录(后端暂时无法处理-已经和产品沟通了)  */
  LoginH5SaveShortcut: 2
}
