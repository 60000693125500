import { createMyPersistedStorage } from '@/utils/business-utils/presisted'
import { defineStore } from 'pinia'

export const useNetWorkStore = defineStore('network', {
  state: () => ({
    state: []
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: createMyPersistedStorage(),
        paths: ['state']
      }
    ]
  }
})
