export interface CurrentDeviceInterface {
  wx: () => boolean
  mobile: () => boolean
  tablet: () => boolean
  desktop: () => boolean
  ios: () => boolean
  macos: () => boolean
  ipad: () => boolean
  iphone: () => boolean
  ipod: () => boolean
  android: () => boolean
  androidPhone: () => boolean
  androidTablet: () => boolean
  blackberry: () => boolean
  blackberryPhone: () => boolean
  blackberryTablet: () => boolean
  windows: () => boolean
  windowsPhone: () => boolean
  windowsTablet: () => boolean
  fxos: () => boolean
  fxosPhone: () => boolean
  fxosTablet: () => boolean
  meego: () => boolean
  television: () => boolean

  // Orientation
  landscape: () => boolean
  portrait: () => boolean
  onChangeOrientation: (cb: (newOrientation: DeviceOrientation) => void) => void

  // Utility
  noConflict: (currentDevice: CurrentDeviceInterface) => void

  // Properties
  type: DeviceType
  orientation: DeviceOrientation
  os: DeviceOs
  browser: () => { type: string; versions: string }
  /**判断当前网页是否通过PWA打开 */
  isInStandaloneMode: () => boolean
}

export type DeviceType = 'mobile' | 'tablet' | 'desktop' | 'unknown'
export type DeviceOrientation = 'landscape' | 'portrait' | 'unknown'
export type DeviceOs =
  | 'ios'
  | 'macos'
  | 'iphone'
  | 'ipad'
  | 'ipod'
  | 'android'
  | 'blackberry'
  | 'windows'
  | 'fxos'
  | 'meego'
  | 'television'
  | 'unknown'

export const getCurrentDevice = (): CurrentDeviceInterface => {
  const device = {} as CurrentDeviceInterface

  // The client user agent string.
  // Lowercase, so we can use the more efficient indexOf(), instead of Regex
  const userAgent = window.navigator.userAgent.toLowerCase()

  // Detectable television devices.
  const television = [
    'googletv',
    'viera',
    'smarttv',
    'internet.tv',
    'netcast',
    'nettv',
    'appletv',
    'boxee',
    'kylo',
    'roku',
    'dlnadoc',
    'pov_tv',
    'hbbtv',
    'ce-html'
  ]

  // Main functions
  // --------------

  device.wx = function () {
    return device.mobile() && find('micromessenger')
  }

  device.macos = function () {
    return find('mac')
  }

  device.ios = function () {
    return device.iphone() || device.ipod() || device.ipad()
  }

  device.iphone = function () {
    return !device.windows() && find('iphone')
  }

  device.ipod = function () {
    return find('ipod')
  }

  device.ipad = function () {
    const iPadOS13Up =
      navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1

    // iPad的屏幕高始终＞屏幕宽（不管你将屏幕竖着还是横着） 后面！是为了剔除开请求桌面网站的iPhone
    const iPadOSMac =
      /macintosh|mac os x/i.test(navigator.userAgent) &&
      window.screen.height > window.screen.width &&
      !navigator.userAgent.match(/(iPhone\sOS)\s([\d_]+)/)
    return find('ipad') || iPadOS13Up || iPadOSMac
  }

  device.android = function () {
    return !device.windows() && find('android')
  }

  device.androidPhone = function () {
    return device.android() && find('mobile')
  }

  device.androidTablet = function () {
    return device.android() && !find('mobile')
  }

  device.blackberry = function () {
    return find('blackberry') || find('bb10')
  }

  device.blackberryPhone = function () {
    return device.blackberry() && !find('tablet')
  }

  device.blackberryTablet = function () {
    return device.blackberry() && find('tablet')
  }

  device.windows = function () {
    return find('windows')
  }

  device.windowsPhone = function () {
    return device.windows() && find('phone')
  }

  device.windowsTablet = function () {
    return device.windows() && find('touch') && !device.windowsPhone()
  }

  device.fxos = function () {
    return (find('(mobile') || find('(tablet')) && find(' rv:')
  }

  device.fxosPhone = function () {
    return device.fxos() && find('mobile')
  }

  device.fxosTablet = function () {
    return device.fxos() && find('tablet')
  }

  device.meego = function () {
    return find('meego')
  }

  device.mobile = function () {
    return (
      device.androidPhone() ||
      device.iphone() ||
      device.ipod() ||
      device.windowsPhone() ||
      device.blackberryPhone() ||
      device.fxosPhone() ||
      device.meego()
    )
  }

  device.tablet = function () {
    return (
      device.ipad() ||
      device.androidTablet() ||
      device.blackberryTablet() ||
      device.windowsTablet() ||
      device.fxosTablet()
    )
  }

  device.desktop = function () {
    return !device.tablet() && !device.mobile()
  }

  device.television = function () {
    let i = 0
    while (i < television.length) {
      if (find(television[i])) {
        return true
      }
      i++
    }
    return false
  }

  device.portrait = function () {
    if (
      screen.orientation &&
      Object.prototype.hasOwnProperty.call(window, 'onorientationchange')
    ) {
      return includes(screen.orientation.type, 'portrait')
    }
    if (
      device.ios() &&
      Object.prototype.hasOwnProperty.call(window, 'orientation')
    ) {
      return Math.abs(window.orientation) !== 90
    }
    return window.innerHeight / window.innerWidth > 1
  }

  device.landscape = function () {
    if (
      screen.orientation &&
      Object.prototype.hasOwnProperty.call(window, 'onorientationchange')
    ) {
      return includes(screen.orientation.type, 'landscape')
    }
    if (
      device.ios() &&
      Object.prototype.hasOwnProperty.call(window, 'orientation')
    ) {
      return Math.abs(window.orientation) === 90
    }
    return window.innerHeight / window.innerWidth < 1
  }
  ;(device.isInStandaloneMode = () => {
    return ['fullscreen', 'standalone', 'minimal-ui'].some(
      (displayMode) =>
        window.matchMedia('(display-mode: ' + displayMode + ')').matches
    )
  }),
    (device.browser = function () {
      const browserInfo = {
        type: '',
        versions: ''
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const browserArray: any = {
        IE: window.ActiveXObject || 'ActiveXObject' in window, // IE
        Chrome:
          userAgent.indexOf('chrome') > -1 && userAgent.indexOf('safari') > -1, // Chrome浏览器
        Firefox: userAgent.indexOf('firefox') > -1, // 火狐浏览器
        Opera: userAgent.indexOf('opera') > -1, // Opera浏览器
        Safari:
          userAgent.indexOf('safari') > -1 && userAgent.indexOf('chrome') == -1, // safari浏览器
        Edg: userAgent.indexOf('edg') > -1, // Edge浏览器
        QQBrowser: /qqbrowser/.test(userAgent), // qq浏览器
        WeixinBrowser: /MicroMessenger/i.test(userAgent) // 微信浏览器
      }
      for (let i in browserArray) {
        if (browserArray[i]) {
          let versions
          if (i == 'IE') {
            versions = userAgent.match(/(msie\s|trident.*rv:)([\w.]+)/)?.[2]
          } else if (i == 'Chrome') {
            for (const mt in navigator.mimeTypes) {
              //检测是否是360浏览器(测试只有pc端的360才起作用)
              if (
                navigator.mimeTypes[mt]['type'] ==
                'application/360softmgrplugin'
              ) {
                i = '360'
              }
            }
            versions = /chrome\/([0-9.]+)/.exec(userAgent)?.[1]
          } else if (i == 'Firefox') {
            versions = /firefox\/([0-9.]+)/.exec(userAgent)?.[1]
          } else if (i == 'Opera') {
            versions = /opera\/([0-9.]+)/.exec(userAgent)?.[1]
          } else if (i == 'Safari') {
            versions = /safari\/([0-9.]+)/.exec(userAgent)?.[1]
          } else if (i == 'Edg') {
            versions = /edg\/([0-9.]+)/.exec(userAgent)?.[1]
          } else if (i == 'QQBrowser') {
            versions = /qqbrowser\/([0-9.]+)/.exec(userAgent)?.[1]
          }
          browserInfo.type = i
          if (versions) {
            browserInfo.versions = versions
          }
        }
      }
      return browserInfo
    })

  // Check if element exists
  function includes(haystack: string, needle: string) {
    return haystack.indexOf(needle) !== -1
  }

  // Simple UA string search
  function find(needle: string) {
    return includes(userAgent, needle)
  }

  // Public functions to get the current value of type, os, or orientation
  // ---------------------------------------------------------------------
  function findMatch(arr: Array<keyof CurrentDeviceInterface>) {
    for (let i = 0; i < arr.length; i++) {
      const deviceType = arr[i]
      const deviceFunc = device[
        deviceType
      ] as () => keyof CurrentDeviceInterface
      if (deviceFunc()) {
        return deviceType
      }
    }
    return 'unknown'
  }

  device.type = findMatch(['mobile', 'tablet', 'desktop']) as DeviceType
  device.os = findMatch([
    'ios',
    'iphone',
    'ipad',
    'ipod',
    'android',
    'blackberry',
    'macos',
    'windows',
    'fxos',
    'meego',
    'television'
  ]) as DeviceOs

  return device
}
