export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'កំហុសប្រព័ន្ធ',
        NETWORK_ERROR:
          'ការភ្ជាប់បណ្តាញបានបរាជ័យសូមព្យាយាមម្តងទៀតបន្ទាប់ពីប្តូរបណ្តាញ',
        RSP_DATA_NOT_JSON: 'តម្លៃឆ្លើយតបខុស',
        GEETEST_RESTRICTED:
          'ប្រតិបត្តិការញឹកញាប់ សូមព្យាយាមម្តងទៀតក្នុងរយៈពេល {seconds}វិនាទី'
      },
      tips: { title: 'ព័ត៌មានជំនួយ' }
    }
  }
}
