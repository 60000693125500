export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'ระบบขัดข้อง',
        NETWORK_ERROR:
          'การเชื่อมต่อเครือข่ายล้มเหลวโปรดลองอีกครั้งหลังจากสลับเครือข่าย',
        RSP_DATA_NOT_JSON: 'รูปแบบของค่าตอบกลับไม่ถูกต้อง',
        GEETEST_RESTRICTED: 'ดำเนินการบ่อย โปรดลองอีกครั้งใน {seconds} วินาที'
      },
      tips: { title: 'เคล็ดลับ' }
    }
  }
}
