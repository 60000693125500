import siteConfig from '@/config/site.config'

/** oss域名组装 */
export const gameOssDomin = (val: string) => {
  if (!val) {
    return ''
  }
  return `https://${siteConfig.ossName}.${siteConfig?.ossType}/cocos/icon/${val}`
}

const getURLParameters = (url = window.location.href) =>
  (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
    (a: Record<string, string>, v: string) => (
      (a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a
    ),
    {}
  )

const generateUrl = (name: string) =>
  `https://${siteConfig.ossName}.${siteConfig?.ossType}/siteadmin/skin/lobby_asset/common/web/home/${name}?manualVersion=1`

/** 后台子游戏预览模式下使用美术写死的资源路径 */
export const previewGameIconUrl = (val: string) => {
  const { previewonly, gameIconType } = getURLParameters()
  if (previewonly === 'true') {
    if (gameIconType === '1') {
      return generateUrl('img_def_yx_sb.png')
    }

    if (gameIconType === '2') {
      return generateUrl('img_def_yx_fx.png')
    }
  }

  return gameOssDomin(val)
}

/** 后台平台预览模式下使用美术写死的资源路径 */
export const previewPlatformIconUrl = (val: string) => {
  const { previewonly, gameIconType } = getURLParameters()
  if (previewonly === 'true') {
    if (gameIconType === '1') {
      return generateUrl('img_def_pt_sb.png')
    }

    if (gameIconType === '2') {
      return generateUrl('img_def_pt_cfx.png')
    }
  }

  return gameOssDomin(val)
}
