import { retryMap } from '../Tool'
import { useMainStore } from '@/store/index/index'

/**
 * 域名连续错误次数
 */
const retryDomainState = {
  ossCommonDomain: {} as Record<string, { continuouErrorCount: number }>,
  ossDomain: {} as Record<string, { continuouErrorCount: number }>,
  assetsPathOrigin: {} as Record<string, { continuouErrorCount: number }>
}
/**
 * 记录域名错误次数
 * @param promiseFn
 * @param namespace
 * @returns
 */
const domainRetryErrorCountWrap = function <T>(
  promiseFn: (domain: string) => Promise<T>,
  namespace: keyof typeof retryDomainState
) {
  return async (domain: string) => {
    const result = await promiseFn(domain)
    /**
     * 成功后对域名状态进行标记：
     * 当前的错误次数设为0，当前域名之前的域名错误次数增加一次计数
     */
    try {
      const store = useMainStore()
      const list = store[namespace] || []
      const currentIndex = list.findIndex((it) => it === domain)
      let index = currentIndex
      while (index >= 0) {
        const domain = list[index]
        retryDomainState[namespace][domain] = retryDomainState[namespace][
          domain
        ] || {
          continuouErrorCount: 0
        }
        if (index === currentIndex) {
          retryDomainState[namespace][domain].continuouErrorCount = 0
        } else {
          retryDomainState[namespace][domain].continuouErrorCount++
        }
        index--
      }
    } catch (error) {}
    return result
  }
}

/**
 * 过滤域名错误次数超过5次的域名
 */
export const domainRetryListWrap = function (
  namespace: keyof typeof retryDomainState
) {
  const store = useMainStore()
  const list = store[namespace] || []
  return list.filter((domain, index) => {
    const filterErrorHost = () => {
      /**
       * 最后一个保底
       */
      if (index === list.length - 1) {
        return true
      } else {
        const item = retryDomainState[namespace][domain]
        if (item) {
          return item.continuouErrorCount < 10
        } else {
          return true
        }
      }
    }
    if (store.useLocalSkinAssets) {
      return filterErrorHost()
    }
    return true
  })
}

/**
 * oss公共桶域名的重试
 * @param promiseFn
 * @returns
 */
export const createOssCommonDomainRetry = function <T>(
  promiseFn: (ossDomain: string) => Promise<T>
) {
  return retryMap(
    domainRetryListWrap('ossCommonDomain'),
    domainRetryErrorCountWrap(promiseFn, 'ossCommonDomain')
  )
}

/**
 * oss站点桶域名的重试
 * @param promiseFn
 * @returns
 */
export const createOssDomainRetry = function <T>(
  promiseFn: (ossDomain: string) => Promise<T>
) {
  return retryMap(
    domainRetryListWrap('ossDomain'),
    domainRetryErrorCountWrap(promiseFn, 'ossDomain')
  )
}

/**
 * oss站点桶域名/lobby-asset 资源的重试，需用本域下的资源兜底
 * @param promiseFn
 * @returns
 */
export const createOssAssetsPathOrigialRetry = function <T>(
  promiseFn: (origin: string) => Promise<T>
) {
  return retryMap(
    domainRetryListWrap('assetsPathOrigin'),
    domainRetryErrorCountWrap(promiseFn, 'assetsPathOrigin')
  )
}
