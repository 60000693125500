import { Merge } from 'type-fest'

interface ScriptOptions {
  id?: string
  src: string
  async?: boolean
  [key: string]: string | undefined | boolean | (() => void)
  callback?: (script?: HTMLScriptElement) => void
}

const scriptsState = {
  /**
   * 下载成功缓存
   */
  cache: {} as Record<string, HTMLScriptElement>,
  /**
   * 队列收集，处理相同资源被并发下载调用的场景，未在下载状态中时触发下载，成功后遍历队列调用reslove
   */
  queue: {} as Record<
    string,
    Array<
      Merge<
        ScriptOptions,
        {
          resolve: (script?: HTMLScriptElement) => void
          reject: () => void
        }
      >
    >
  >,

  create: (src: string, scriptConfigs: ScriptOptions) => {
    const $script = document.createElement('script')
    const $fjs = document.querySelector('script')
    for (const key in scriptConfigs) {
      const value = scriptConfigs[key]
      if (key !== 'callback' && typeof value === 'string') {
        $script.setAttribute(key, value)
      }
    }
    $fjs?.parentNode?.insertBefore($script, $fjs)

    $script.onload = () => {
      scriptConfigs?.callback?.($script)
      scriptsState.cache[src] = $script
      while (scriptsState.queue[src].length) {
        scriptsState.queue[src].shift()?.resolve($script)
      }
    }

    $script.onerror = () => {
      scriptsState.queue[src].shift()?.reject()
    }
  }
}

export const loadScripts = async (
  scripts: ScriptOptions | Array<ScriptOptions>,
  parallel = false,
  cache = true
) => {
  scripts = Array.isArray(scripts) ? scripts : [scripts]
  const loadScript = (script: ScriptOptions) =>
    new Promise((resolve, reject) => {
      const { src } = script
      if (scriptsState.cache[src] && cache)
        return resolve(scriptsState.cache[src])

      scriptsState.queue[src] = [
        ...(scriptsState.queue[src] || []),
        {
          ...script,
          resolve: resolve as (script?: HTMLScriptElement) => void,
          reject: reject as () => void
        }
      ]
      /**
       * 非下载中才触发下载
       */
      if (scriptsState.queue[src].length === 1) {
        scriptsState.create(src, script)
      }
    })

  if (parallel) {
    /**
     * 资源并行下载，适合各资源变量无相互依赖关系的场景
     */
    return Promise.all(scripts.map((script) => loadScript(script)))
  }
  /**
   * 资源脚本中的变量加载有相互依赖关系，上一个成功了才会下载下一个
   */
  const result = []
  for (let index = 0; index < scripts.length; index++) {
    result[index] = await loadScript(scripts[index])
  }
  return result
}

export const loadJson = (url: string): Promise<Record<string, unknown>> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.send()
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) return
      if (xhr.status == 200) {
        try {
          resolve(JSON.parse(xhr.response))
        } catch (error) {
          reject()
        }
      } else {
        reject()
      }
    }
  })
}

export const loadSound = (url: string): Promise<HTMLAudioElement> => {
  return new Promise((resolve, reject) => {
    const media = new Audio()
    media.oncanplay = () => {
      resolve(media)
    }

    media.onerror = () => {
      reject('load error:' + url)
    }
    media.src = url

    if (navigator.userAgent.match(/iPhone|iPod|iPad/i) != null) {
      setTimeout(() => {
        //兼容ios不触发oncanplay
        media.load()
      }, 0)
    }
  })
}

export const loadImage = (
  url: string,
  isBlob = false
): Promise<HTMLImageElement | string> => {
  return new Promise(async (resolve, reject) => {
    if (isBlob) {
      resolve(URL.createObjectURL(await loadImage2Blob(url)))
    } else {
      const img = new Image()
      img.onload = () => {
        resolve(img)
      }

      img.onerror = () => {
        reject('load error:' + url)
      }

      img.src = url
    }
  })
}

export const loadImage2Blob = (url: string): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) return
      if (xhr.status == 200) {
        resolve(xhr.response as Blob)
      } else {
        reject()
      }
    }
    xhr.responseType = 'blob'
    xhr.send()
  })
}

export const requireContexts = (
  contexts: __WebpackModuleApi.RequireContext[],
  callback: (result: { path: string; modules: unknown }) => void
) => {
  contexts.forEach((context) =>
    context.keys().forEach((path) => {
      const modules = context(path)
      callback?.({ path, modules })
    })
  )
}
