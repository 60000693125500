export default {
  name: 'close-circle',
  theme: 'fill',
  icon: {
    tag: 'svg',
    attrs: {
      xmlns: 'http://www.w3.org/2000/svg',
      width: 28,
      height: 28,
      viewBox: '0 0 28 28',
      focusable: false
    },
    children: [
      {
        tag: 'path',
        attrs: {
          id: 'close_fill',
          d: 'M3323,1936a14,14,0,1,1,9.9-4.1A13.907,13.907,0,0,1,3323,1936Zm0-12.351h0l4.536,4.537a1.167,1.167,0,1,0,1.65-1.65l-4.537-4.537,4.537-4.537a1.167,1.167,0,1,0-1.65-1.65l-4.536,4.536-4.538-4.536a1.167,1.167,0,1,0-1.65,1.65l4.538,4.537-4.538,4.537a1.167,1.167,0,0,0,1.65,1.65l4.537-4.537Z',
          transform: 'translate(-3309 -1908)'
        },
        children: []
      }
    ]
  }
}
