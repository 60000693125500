import { FacebookHelper } from '@/controller/ThirdPartyLogin'
import { FooterInfoRes } from '@/api/common/type'
import { SocialmediaResponse } from '@/api/promote/myPromote/type'
import { apiGetFooterInfos } from '@/api/common'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'

type FooterState = {
  footerInfos: FooterInfoRes | null
  socialmediaList: Array<SocialmediaResponse> | null
}

export const useFooterStore = defineStore('footer', {
  state: (): FooterState => ({
    footerInfos: null,
    socialmediaList: null
  }),

  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.footer',
        storage: createMyPersistedStorage(),
        paths: ['socialmediaList']
      },
      {
        key: 'web.lobby.footer.footerInfos',
        paths: ['footerInfos'],
        storage: createMyPersistedStorage({
          setItem(key, value, storage) {
            try {
              const origialData = JSON.parse(value)
              origialData.footerInfos.companyInfo.content = ''
              storage.setItem(key, JSON.stringify(origialData), storage)
            } catch (error) {}
          }
        })
      }
    ]
  },
  actions: {
    async initFooterInfos() {
      const footerInfosResponse = await apiGetFooterInfos()
      if (footerInfosResponse) {
        this.footerInfos = footerInfosResponse as FooterInfoRes
      }
    },
    updateSocialMediaList(list: SocialmediaResponse[]) {
      const itemFacebook = list.find((el) => {
        return el.name.toLowerCase() == 'facebook'
      })
      if (itemFacebook) {
        FacebookHelper.initSDK()
      }
      this.socialmediaList = list
    }
  }
})
