import { Entity, Model, from, nullable, parse, type } from 'typox'
import { _PlatformCategoriesItemInter as PlatformCategoriesItemInter } from './platformCategoriesItemInter'
import { _GameCategoriesInfoInter } from './gameCategoriesInfoInter'
import { numberToBool, parseArray } from '@/service/extensions'
import PlatformCategoriesItem from './platformCategoriesItem'

/** 游戏菜单信息 */
@Entity()
export default class _GameCategoriesInfo
  extends Model
  implements _GameCategoriesInfoInter
{
  constructor(source?: unknown) {
    super()
    this.merge(source)
  }

  /** web端使用的唯一id */
  @type(String)
  @parse((_, item) => `${item?.p0}`)
  webId = ''

  /** 游戏类别id */
  @from('p0')
  @type(Number)
  gameCategoryId = 0

  /** 游戏类别名称 */
  @from('p1')
  @type(String)
  gameCategoryName = ''

  /** 是否展示该类别  0-否, 1-是 */
  @from('p2')
  @type(Boolean)
  @parse(numberToBool)
  display = false

  /**" PC端配置方形图片数量 */
  @from('p3')
  @type(Number)
  pcSquare = 0

  /** h5端配置方形图片数量 */
  @from('p4')
  @type(Number)
  appSquare = 0

  /**" PC端配置竖形图片数量 */
  @from('p5')
  @type(Number)
  pcVertical = 0

  /** h5端配置竖形图片数量 */
  @from('p6')
  @type(Number)
  appVertical = 0

  /**" PC端配置二级菜单行数 */
  @from('p7')
  @type(Number)
  pcMenuRow = 0

  /** h5端配置二级菜单行数 */
  @from('p8')
  @type(Number)
  appMenuRow = 0

  /** 平台详情列表 */
  @from('l')
  @type(Array)
  @parseArray(PlatformCategoriesItem)
  @nullable()
  list: PlatformCategoriesItemInter[] = []
}
