/**
 * 获取密码强度分数
 * @param password
 * @param account
 * @returns
 */
/**
    1、密码长度是否大于 12 码
    2、是否存在小写英文
    3、是否存在大写英文
    4、是否存在数字
    5、是否存在标点符号
    分数
      4分
      同时满足以上 1-5 条件
      3分
      满足第 1 条件
      剩余的满足 2-5 中的 3 个条件
      2分
      其余归类此处
      1分
      2-5 规则中只达成其中一个条件
      账号与密码同组字符串
 */
export function checkPasswordStrength(
  password: string,
  account: string
): number {
  // 正则表达式匹配密码规则
  const regexLowercase = /[a-z]/
  const regexUppercase = /[A-Z]/
  const regexNumber = /\d/
  const regexPunctuation = /[!@#$%^&*_=\+\\,\-.\/?\(\)]/

  // 检查密码是否符合各个规则
  const meetsLength = password.length >= 12
  const hasLowercase = regexLowercase.test(password)
  const hasUppercase = regexUppercase.test(password)
  const hasNumber = regexNumber.test(password)
  const hasPunctuation = regexPunctuation.test(password)

  // 根据规则计算得分
  let score = 0
  // 收集满足2-5规则中只达成其中一个条件的索引值
  const singleCondition = [
    hasLowercase,
    hasUppercase,
    hasNumber,
    hasPunctuation
  ].filter((x) => x)

  if (
    password.length >= 8 &&
    (singleCondition.length === 1 || (password && password === account))
  ) {
    score = 1
  } else if (
    meetsLength &&
    hasLowercase &&
    hasUppercase &&
    hasNumber &&
    hasPunctuation
  ) {
    score = 4
  } else if (meetsLength && singleCondition.length === 3) {
    score = 3
  } else if (password.length >= 8) {
    score = 2
  }

  return score
}
