import { OsType } from '@/api/common/type'
import { getCurrentDevice } from '@/utils/Device'

export const getOsType = (): OsType => {
  const map = new Map([
    [getCurrentDevice().ios() && getCurrentDevice().mobile(), OsType.IOS_H5],
    [getCurrentDevice().androidPhone(), OsType.ANDROID_H5],
    [
      getCurrentDevice().windows() && getCurrentDevice().desktop(),
      OsType.PC_Windows
    ]
  ])

  return map.get(true) || OsType.PC_Windows
}
