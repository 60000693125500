import { AccountType } from '@/api/common/type'
import { Component } from 'vue'
import { GlobalManager } from '@/context'
import { RouteName } from './config'
import { ThemeComponentType } from '@/context/components-manager/config/themes/type'
import { ValueOf } from 'type-fest'
import { get, merge } from 'lodash'
import { nodeThemeConfig } from '@/context/const'
import { scrollBehavior, setupScroll } from './scroll'
import EmbeddedPage from '@/views/game/entry/embedded'
import HomePage from '@/views/game/entry/home'
import Layouts from '@/layouts'
import PromoteGame from '@/views/promote-game'
import Render from '@/components/business-components/func-render/async-component'
import Router, { RawLocation, Route } from 'vue-router'
import SearchPage from '@/views/game/entry/search'
import SubGamePage from '@/views/game/entry/subGame'
import WGGame from '@/views/wg-game'
import guard from './guard'
import style from '@/layouts/styles/common.module.scss'
import themeConfigManager from '@/context/components-manager/config/themes'

export const ThemeSkinType = nodeThemeConfig.ThemeSkinType
export type ThemeSkinType = ValueOf<typeof ThemeSkinType>

/**
 * 传入版式走不同的布局组件，由路由守卫中拿到结果
 * @param skinType
 * @returns
 */
export const withLayoutComponent = (skinType?: ThemeSkinType) => {
  if (skinType) {
    const com = themeConfigManager[skinType][ThemeComponentType.Layouts]
      ?.com as () => Promise<Component>
    return com
  }
  return Layouts
}

const superRoute = {
  push: Router.prototype.push,
  replace: Router.prototype.replace,
  go: Router.prototype.go
} as const
let routerChangeTimes = 0

/**
 * 重写路由跳转方法
 * 1.使重复路由跳转时控制台不报错
 * 2.拦截通过path的路由跳转，强制要求通过name进行路由匹配跳转
 */
Object.keys(superRoute).forEach((key) => {
  const methods = key as keyof typeof superRoute
  if (methods == 'go') {
    Router.prototype[methods as 'go'] = function (val: number) {
      const context = this as Router
      routerChangeTimes += val
      if (context.mode === 'history' && routerChangeTimes < 0) {
        this.push({
          name: RouteName.ROOT
        })
      } else {
        superRoute[methods as 'go'].bind(this)(val)
      }
    }
  } else {
    Router.prototype[methods as 'push' | 'replace'] = async function (
      location: RawLocation
    ): Promise<Route> {
      /**
       * 非字串，对象模式，未传name也未传path,只传了query或者params 等参数载荷 的场景
       */
      const onlyPayload =
        typeof location !== 'string' &&
        !get(location, 'name') &&
        !get(location, 'path')
      if (
        (!get(location, 'name') ||
          get(location, 'path') ||
          typeof location === 'string') &&
        !onlyPayload
      ) {
        throw new Error('为了方便管理，请统一使用 name 匹配 做路由跳转')
      }
      try {
        /**
         * 跳转路由时, 保留的参数
         */
        const {
          cid,
          id,
          aid,
          languageCode,
          currency,
          agent_code,
          isDebug,
          // type,
          afId,
          gtagId,
          gtmId,
          fb_dynamic_pixel,
          kwaiPixelBaseCode,
          ks_px_test,
          pixel_id,
          click_id,
          tiktokBaesCode,
          _atrk_c,
          _atrk_cr,
          _atrk_pt,
          _atrk_bi,
          _atrk_f,
          previewonly,
          gameIconType,
          /**
           * 马甲包、极速包注入依据
           */
          isWgPackage,
          WgPackage,
          SpeedPackaeInit,
          appsflyer_id,
          appsflyer_appId,
          appsflyer_auth
        } = GlobalManager.App.$route.query

        const querys = {
          cid,
          id,
          aid,
          languageCode,
          currency,
          agent_code,
          isDebug,
          // type,
          afId,
          gtagId,
          gtmId,
          fb_dynamic_pixel,
          kwaiPixelBaseCode,
          ks_px_test,
          pixel_id,
          click_id,
          tiktokBaesCode,
          _atrk_c,
          _atrk_cr,
          _atrk_pt,
          _atrk_bi,
          _atrk_f,
          previewonly,
          gameIconType,
          /**
           * 马甲包、极速包注入依据
           */
          isWgPackage,
          WgPackage,
          SpeedPackaeInit,
          /** 马甲包af应用s2s保留参数 */
          appsflyer_id,
          appsflyer_appId,
          appsflyer_auth,
          /**
           * mock.格式和fixed.格式参数保留
           */
          ...Object.entries(GlobalManager.App.$route.query).reduce(
            (pre, [key, value]) => {
              if (/(mock|fixed).[\S]+/.test(key)) {
                pre[key] = value
              }
              return pre
            },
            {} as Record<string, unknown>
          )
        } as Record<string, string>

        Object.keys(querys).forEach((key) => {
          if (querys[key]) {
            location.query = merge({ [key]: querys[key] }, location.query)
          }
        })
        routerChangeTimes++
        return await superRoute[methods as 'push' | 'replace'].bind(this)(
          location
        )
      } catch (e) {
        if ((e as { name: string })?.name === 'NavigationDuplicated') {
          return {} as Route
        } else {
          throw e
        }
      }
    }
  }
})

const routes = (skinType?: ThemeSkinType) => [
  {
    path: '/',
    redirect: {
      name: RouteName.GAME
    },
    name: RouteName.ROOT
  },
  {
    path: '/home',
    name: 'home',
    component: withLayoutComponent(skinType),
    redirect: {
      name: RouteName.GAME
    },
    children: [
      {
        name: RouteName.GAME,
        path: '/home/game',
        mode: 'sync',
        component: HomePage,
        meta: {
          depIndex: 0,
          title: RouteName.GAME,
          showTabBar: true
        }
      },
      {
        name: RouteName.WG_GAME,
        path: '/home/wg-game',
        mode: 'sync',
        component: WGGame,
        meta: {
          title: RouteName.WG_GAME
        }
      },
      {
        name: RouteName.SUB_GAME,
        path: '/home/subgame',
        mode: 'sync',
        component: SubGamePage,
        meta: {
          title: RouteName.SUB_GAME,
          depIndex: 1
        }
      },
      {
        name: RouteName.GAME_SEARCH,
        path: '/home/search',
        mode: 'sync',
        component: SearchPage,
        meta: {
          title: RouteName.GAME_SEARCH,
          depIndex: 1
        }
      },
      {
        name: RouteName.GAME_EMBEDDED,
        path: '/home/embedded',
        mode: 'sync',
        component: EmbeddedPage,
        meta: {
          title: RouteName.GAME_EMBEDDED,
          depIndex: 1
        }
      },
      {
        name: RouteName.EVENT,
        path: '/home/event',
        component: () =>
          import(/* webpackChunkName: "event" */ '@/views/event'),
        children: [
          {
            name: RouteName.EVENT_ITEM,
            path: '/home/event/item/:template/:eventId',
            component: () => import('@/views/event/item'),
            meta: {
              depIndex: 2
            }
          }
        ],
        meta: {
          depIndex: 1,
          title: RouteName.EVENT,
          showTabBar: true
        }
      },
      {
        name: RouteName.TASK,
        path: '/home/task',
        mode: 'standard',
        component: () => import('@/views/task'),
        meta: {
          depIndex: 3,
          title: RouteName.TASK,
          showTabBar: true
        }
      },
      {
        name: RouteName.CASHBACK,
        path: '/home/cashback',
        component: () => import('@/views/cashback'),
        meta: {
          depIndex: 1,
          title: RouteName.CASHBACK,
          showTabBar: true
        }
      },
      {
        name: RouteName.PROMOTE,
        path: '/home/promote',
        component: () => import('@/views/promote'),
        meta: {
          depIndex: 3,
          private: false,
          title: RouteName.PROMOTE
        }
      },
      {
        name: RouteName.PROXYTRANSFER,
        path: '/home/proxyTransfer',
        component: () => import('@/views/promote/components/proxyTransfer')
      },
      {
        name: RouteName.MYTRANSFERRECORD,
        path: '/home/myTransferRecord',
        component: () => import('@/views/promote/components/transferRecord')
      },
      {
        name: RouteName.PICKUPRECORD,
        path: '/home/pickUpRecord',
        component: () => import('@/views/promote/components/pickUpRecord')
      },
      {
        name: RouteName.RECHARGE_FUND,
        path: '/home/rechargeFund',
        component: () => import('@/views/rechargeFund'),
        meta: {
          // private: true,
          title: RouteName.RECHARGE_FUND,
          showTabBar: true
        }
      },
      {
        name: RouteName.YUEBAO,
        path: '/home/yuebao',
        component: () => import('@/views/yuebao'),
        meta: {
          depIndex: 3,
          // private: true,
          title: RouteName.YUEBAO,
          showTabBar: true
        }
      },
      {
        name: RouteName.CENTER_WALLET,
        path: '/home/center-wallet',
        component: () => import('@/views/center-wallet'),
        meta: {
          depIndex: 3,
          title: RouteName.CENTER_WALLET
        }
      },
      {
        name: RouteName.VIP,
        path: '/home/vip',
        component: () => import('@/views/reward/vip'),
        meta: {
          depIndex: 3,
          private: true,
          title: RouteName.VIP,
          showTabBar: true
        }
      },
      {
        name: RouteName.WITHDRAW_DETAILS,
        path: '/home/withdraw/withdraw-details/:orderId',
        component: () => import('@/views/finance/withdraw/withdraw-details'),
        meta: {
          depIndex: 4,
          private: true,
          title: RouteName.WITHDRAW_DETAILS
        }
      },
      {
        name: RouteName.AUDIT_DETAILS,
        path: '/home/withdraw/audit-details/:id',
        component: () => import('@/views/finance/withdraw/audit-details'),
        meta: {
          depIndex: 4,
          private: true,
          title: RouteName.AUDIT_DETAILS
        }
      },
      {
        name: RouteName.WITHDRAW,
        path: '/home/withdraw',
        mode: 'standard',
        component: () => import('@/views/finance/withdraw'),
        meta: {
          depIndex: 3,
          private: true,
          title: RouteName.WITHDRAW
        }
      },
      {
        name: RouteName.NOTICE,
        path: '/home/notice',
        component: () => import('@/views/notice'),
        meta: {
          depIndex: 3,
          title: RouteName.NOTICE
        }
      },
      {
        name: RouteName.CUSTOMERS_QUESTION_DETAIL,
        path: '/home/customers-question-detail',
        component: () => import('@/views/customers-question-detail'),
        meta: {
          title: RouteName.CUSTOMERS_QUESTION_DETAIL
        }
      },
      {
        name: RouteName.RECORDS,
        path: '/home/records',
        component: () => import('@/views/records'),
        meta: {
          depIndex: 1,
          title: RouteName.RECORDS,
          showTabBar: true
        }
      },
      {
        name: RouteName.CANRECEIVE,
        path: '/home/canReceive',
        component: () => import('@/views/canReceive'),
        meta: {
          title: RouteName.CANRECEIVE,
          showTabBar: true
        }
      },
      {
        name: RouteName.RECEIVERECORDS,
        path: '/home/receive-records',
        component: () => import('@/views/receive-records'),
        meta: {
          title: RouteName.RECEIVERECORDS
        }
      },
      /**
       *  个人中心
       */
      ...[
        {
          name: RouteName.SETTING,
          path: '/home/setting',
          component: () => import('@/views/center/setting'),
          mode: 'standard',
          meta: {
            depIndex: 3,
            private: true,
            title: RouteName.SETTING
          }
        },
        {
          name: RouteName.REPORT,
          path: '/home/report',
          component: () => import('@/views/center/report/index'),
          meta: {
            depIndex: 3,
            private: true,
            title: RouteName.REPORT
          }
        },
        {
          name: RouteName.SECURITY,
          path: '/home/security',
          component: () => import('@/views/center/security'),
          meta: {
            depIndex: 3,
            private: true,
            title: RouteName.SECURITY
          }
        },
        {
          name: RouteName.ABOUT_US,
          path: '/home/about',
          component: () => import('@/views/center/about-us'),
          mode: 'standard',
          meta: {
            depIndex: 3,
            title: RouteName.ABOUT_US
          }
        },
        {
          name: RouteName.VERSION_UPDATE,
          path: '/home/update',
          component: () => import('@/views/version-update'),
          meta: {
            depIndex: 3,
            private: true,
            title: RouteName.VERSION_UPDATE
          }
        },
        {
          name: RouteName.SPORTS_PARLAYBET,
          path: '/home/sports-parlay-pet',
          component: () =>
            import('@/views/selfoperated-games/sports/views/parlay-bet'),
          meta: {
            private: true,
            title: RouteName.SPORTS_PARLAYBET,
            hiddenMobileTabBar: true
          }
        },
        {
          name: RouteName.SPORTS_BETRECORD,
          path: '/home/sports-bet-record',
          component: () =>
            import('@/views/selfoperated-games/sports/views/bet-record'),
          meta: {
            private: true,
            title: RouteName.SPORTS_BETRECORD,
            hiddenMobileTabBar: true
          }
        },
        {
          name: RouteName.SPORTS_DETAIL,
          path: '/home/sports-detail',
          component: () =>
            import('@/views/selfoperated-games/sports/views/details'),
          meta: {
            private: true,
            title: RouteName.SPORTS_DETAIL,
            hiddenMobileTabBar: true
          }
        },
        {
          name: RouteName.SPORTS_HOME,
          path: '/home/sports-subgame',
          component: () =>
            import('@/views/selfoperated-games/sports/views/sub-page'),
          meta: {
            // private: true,
            title: RouteName.SPORTS_HOME,
            hiddenMobileTabBar: true
          }
        },
        {
          name: RouteName.MINE,
          path: '/home/mine',
          component: () => import('@/views/mine'),
          meta: {
            depIndex: 2,
            private: true,
            title: RouteName.MINE,
            showTabBar: true,
            /**
             * 能访问的账号类型，代表userInfos?.account_type === AccountType.Demo 的账号（主播号）可进
             */
            whiteRoleType: [AccountType.Demo]
          }
        }
      ]
    ].map((it) => {
      return {
        ...it,
        component:
          it.mode === 'sync'
            ? it.component
            : Render.createAsyncComponent({
                name: it.name,
                component: it.component as unknown as () => Promise<unknown>,
                loadingComponentConfig: {
                  class: style['route-loading']
                },
                mode: it.mode === 'async-on-sync' ? 'async-on-sync' : 'standard'
              })
      }
    })
  },
  {
    name: RouteName.ERROR_TEXT,
    path: '/error',
    component: () => import('@/views/error-text/index'),
    meta: {
      title: RouteName.ERROR_TEXT
    }
  },
  {
    name: RouteName.NOT_FOUND,
    path: '/404',
    component: () => import('@/views/error-text/404'),
    meta: {
      title: RouteName.NOT_FOUND
    }
  },
  {
    name: RouteName.PROMOTE_GAME,
    path: '/promote-game',
    component: PromoteGame,
    meta: {
      title: 'loading'
    }
  },
  /**
   * 测试环境供测试测试使用 ，后面会删掉
   */
  // 注释掉该路由,因为种种原因,业主在生产上也打开了该路由,所以需要将之注释掉
  // {
  //   name: RouteName.DEMO_REGISTER,
  //   path: '/demo-register',
  //   component: () => import('@/views/demo-register'),
  //   meta: {
  //     title: RouteName.DEMO_REGISTER
  //   }
  // },
  {
    name: RouteName.IFRAME_ROUTE,
    path: '/iframe-route',
    component: () => import('@/views/iframe'),
    meta: {
      title: RouteName.IFRAME_ROUTE
    }
  },
  {
    path: '*',
    redirect: {
      name: RouteName.ROOT
    }
  }
]
export const createRouter = (skinType?: ThemeSkinType) =>
  new Router({
    mode: 'history',
    routes: routes(skinType),
    scrollBehavior
  })

/**
 * 路由懒加载
 * @param showTabBar {Boolean} true显示H5端tabBar
 */
const router = createRouter()

/**获取路由中所有的一级页面（页面底部带有showTabBar的） */
export const allFirstRoutes = routes().reduce((acc, cur) => {
  const childrens = cur?.children
  if (childrens?.length) {
    for (const children of childrens) {
      const { meta, name } = children
      const showTabBar = meta?.showTabBar
      if (showTabBar) {
        acc = acc.concat(name)
      }
    }
  }
  return acc
}, [] as Array<RouteName>)

const environment = process.env.ENVIRONMENT
if (environment && ['dev', 'test', 'stest'].includes(environment)) {
  router.addRoute({
    name: RouteName.DEVTOOLS,
    path: '/devtools',
    component: () => import('@/views/devtools'),
    meta: {
      title: RouteName.DEVTOOLS
    }
  })
}

export const resetRouter = (newRouter: Router) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(router as any).matcher = (newRouter as any).matcher
}

guard(router)

setupScroll(router)
export default router
