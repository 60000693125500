import Vue from 'vue'
export default class VueUtils {
  /**
   *  注入jsx h函数
   *  首先尝试从根Vue实例取方法没有的话就新建一个
   */
  static $createElement = new Vue().$createElement

  /**
   * 根据条件收集所有符合特征的vue子组件
   */
  public static getChilds(filter: (context: Vue) => boolean, context: Vue) {
    const results: Vue[] = []
    const fn = (filter_: (context: Vue) => boolean, context_: Vue) => {
      context_.$children.forEach((child) => {
        if (filter_(child)) {
          results.push(child)
        } else {
          fn(filter_, child)
        }
      })
    }
    fn(filter, context)
    return results
  }
}
