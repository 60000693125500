import Ant from '@/plugins/ant-design-vue'
import Appier from '@/utils/Appier'
import MonitoringAnalysis from '@/controller/MonitoringAnalysis'
import Vue from 'vue'
import type { MyEvent } from '@/context/events'
import type { SvgSprite } from '@/components/icon-sprite'
import type GoogleTagManager from '@/utils/GoogleTagManager'
import type KwaiPixelBaseCode from '@/utils/KwaiPixelBaseCode'
import type MetaPixel from '@/utils/MetaPixel'
import type TiktokBaesCode from '@/utils/TiktokBaesCode'

interface UniPostMessageData {
  data: {
    eventName: string
    eventValues: object
  }
}
declare global {
  interface Window {
    'svgSprite:all': Record<string, SvgSprite>
    'svgSprite:festival'?: Record<string, SvgSprite>
    initSplash: {
      nodeClassName: string
      destroy: (retry?: boolean) => void
    }
    LOBBY_UTILS: {
      mediaScreen: () => {
        device: string
        size: string
        nodesktop: string
      }
    }
    eruda: import('eruda').Eruda
    wgMock: {
      keepAlive: boolean
      cardLite: boolean
      noLazy: boolean
      blurAnimation: boolean
      openPC: boolean
      animationMode: 'none' | 'default' | 'fade'
    }
    WgPackage: {
      //包名
      name: string
      version: string
      //设备唯一id
      device: string
      //原生设备名称  eg:iPhone Simulator|google Pixel 4
      deviceModel: string
      //系统类型
      // osType: 1 | 2 //android是2，ios是1
    }
    jsBridge: {
      share?: (data: string) => void
      getWGConfigInfo?: () => string
      postMessage: (eventName: string, params: string) => void
    }
    Android: {
      eventTracker: (eventName: string, params: string) => void
    }
    android_JSInter: {
      eventTracker: (eventName: string, params: string) => void
    }
    uni: {
      postMessage: (data: UniPostMessageData) => void
      getEnv: (data: object) => void
    }
    PlatNative: {
      event: (eventName: string, params: string) => void
    }
    closeGame: () => void
    /** ga */
    GTM: GoogleTagManager
    MonitoringAnalysis: MonitoringAnalysis
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Array<any>
    /** meta pixel */
    Pixel: MetaPixel
    fbq: (type: string, eventName: string, params: object) => void
    /** KwaiPixelBaseCode */
    KwaiPixe: KwaiPixelBaseCode
    kwaiq: {
      instance: (kwaiqID: string) => {
        track: (eventName: string, params: object) => void
      }
    }
    /** TiktokBaesCode */
    Tiktok: TiktokBaesCode
    ttq: { track: (eventName: string, params: object) => void }
    AF: (val1: string, val2: string, val3: object) => void
    /** Appier */
    Appier: Appier
    appier_q: Array<object>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    EVENT_CODE_MAP: any
    lobby_game_url: string
    SpeedPackaeInit?: {
      pushId: string
      pkgVersion: string
      isSpeedPackae: boolean
      osType: 1 | 2 //android是2，ios是1
    }
    SpeedPackageThirdLoginCallback: (actions: {
      type: 'string'
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any
    }) => void //cocos调用三方登录返回
    clabe: {
      init: () => void
      validate: (value: string) => {
        ok: boolean
      }
    }
  }
}

;(async () => {
  /**
   * 必须先 use Ant 再调用 main.async 以确保Ant重写生效
   * 否则 main.async中的依赖引用的是未重写的 Ant
   */
  Vue.use(Ant)

  const { events } = require('./main.async') as {
    events: MyEvent
  }

  events
    .subscribe((action) => {
      console.log(
        `[EVENT:${action.type}]<==================${JSON.stringify(action)}`
      )
    })
    .dispatch({ type: 'LOADED', payload: { Vue } })
})()
