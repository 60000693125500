/** 游戏顶的类型 */
export enum _GameItemType {
  /** 平台 */
  Platform = 1,
  /** 普通游戏 */
  NormalGame = 2
}

/** 游戏项的接口 */
export interface _GameItemInter {
  /** web端使用的唯一id */
  webId: string
  /** 游戏 id */
  gameId: number
  /** 游戏名称 */
  gameName: string
  /** 游戏图标 */
  gameIcon: string
  /** 自定义图片 0-否, 1-是  */
  isCustomIcon: boolean
  /** 是否美术上传的原始图片 0-否, 1-是 */
  isOriginImg: boolean
  /** 维护状态 0-否, 1-是  */
  maintainStatus: boolean
  /** 是否推荐 0-否, 1-是  */
  recommendStatus: boolean
  /** 是否支持试玩 0-否, 1-是  */
  demoModeSupport: boolean
  /** 屏幕方向: 0=横屏, 1=竖屏  */
  screenDirection: boolean
  /** 是否主播号: 0-否, 1-是 */
  anchorSupport: boolean
  /** 游戏类别  */
  gameCategoryId: number
  /** 游戏平台ID
   * 注: 方型卡片,需要特殊判断platformId,如果是13(WG)平台,则要进行logo的拼接,其它平台全都是整图,则没有这个问题.
   */
  platformId: number
  /** 热门类型 1=平台 2=游戏 */
  gameType: _GameItemType
  /** 平台名称 */
  platformName: string
  /** 平台logo */
  platformLogo: string
  /** 游戏打开方式 0-内嵌, 1-外链 */
  isExternalLink: boolean
  /** 最小准入余额 */
  minimumEntryBalance: number
  /** 是否三合一图(图片/logo/背景色) */
  isCombinate: boolean
  /** 方形图标5159独有后续会删除 */
  squareGameIcon: string
  /** 自定义图片5159独有后续会删除 0-否, 1-是  */
  isSquareCustomIcon: boolean
  /** 是否方形三合一图5159独有后续会删除(图片/logo/背景色) 0-否，1是 */
  isSquareCombinate: boolean
}
