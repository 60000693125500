import { PngSprite } from '@/components/icon-sprite'
import { createOssAssetsPathOrigialRetry } from './retry'
import { cssRuleSelector, urlFormatter } from '../Tool'
import { loadImage } from '../Loader'
import { parse as urlParse } from 'url'
import { useMainStore } from '@/store/index'
import Skin from '@/controller/Skin'
import axios from 'axios'

/**
 * /lobby_asset/* 开头的资源
 * {layout} ===> siteConfig.type
 * {skin}===> siteConfig.skinType
 * @param path
 * @returns
 */
export const assetsPath = (
  path: string,
  // origin = `${location.origin}/assets`
  origin = useMainStore().useLocalSkinAssetsOnly
    ? `${location.origin}/assets`
    : `${useMainStore().ossHost}siteadmin/skin`
) => {
  if (typeof path !== 'string') return path
  const needHackPath = /^\/lobby_asset\/[\S]+/.test(path)
  if (needHackPath) {
    const { siteInfos, skinAssetsHash } = useMainStore()
    const layoutType = siteInfos?.type
    const skinValue = siteInfos?.skinTypeValue
    const bgType = siteInfos?.backgroundColor
    const holidayType = Skin.holidayThemeType

    const layout =
      typeof layoutType !== 'undefined' ? String(layoutType) : `{layout}`
    const skin = typeof skinValue !== 'undefined' ? String(skinValue) : `{skin}`
    const bg = typeof bgType !== 'undefined' ? String(bgType) : `{bg}`
    const holiday =
      typeof holidayType !== 'undefined' ? String(holidayType) : `{holiday}`
    const targetUrl = path
      .replace(/\{layout\}/g, layout)
      .replace(/\{bg\}/g, bg)
      .replace(/\{skin\}/g, skin)
      .replace(/\{holiday\}/g, holiday)

    const getVersion = (targetUrl: string) => {
      const assetsHash = skinAssetsHash || {}
      const hashVersion =
        assetsHash[
          targetUrl.replace(/^\/lobby_asset\//, '').replace(/\?[\S]+$/, '')
        ]
      if (hashVersion) {
        return hashVersion
      }
      return process.env.APP_VERSION
    }

    return urlFormatter(`${origin}${targetUrl}`, {
      /**
       * 用于强行刷新资源版本的入口，一般不可变，除非发生错误的资源被sw缓存
       * 如遇到运维配置或者架构配置错误导致oss桶返回值类型错误导致sw强缓存了错误类型的情况可通过手动升级manualVersion字段解决
       */
      manualVersion: 1,
      version: getVersion(targetUrl)
      /**
       * 开启sw缓存
       * 暂时关闭 线上加速域名有问题
       */
      // swRuntimeCacheName: 'web-lobby-runtime'
    })
  } else {
    return path
  }
}

export { createSvgSprite } from './svg'

export function createPngSprite(
  key: string,
  options?: Partial<PngSprite>,
  context: () => Record<string, PngSprite> | null = () =>
    useMainStore().allPngSprite
) {
  const config = context()?.[key] || {
    x: 1,
    y: 1,
    width: 1,
    height: 1,
    image: '',
    id: '',
    total_width: 1,
    total_height: 1
  }
  return { ...{ id: key }, ...config, ...options }
}

/**
 * icon-sprite 支持png 路径，并改变颜色，返回构造属性
 * @param color 图片颜色
 * @param width
 * @param height
 * @param path 图片路径
 * @returns 构造属性
 * 使用示例： <icon-sprite
      {...{
        props: createSinglePng2SpriteProps('#e3e3e3', 1200, 36,
          '/lobby_asset/festival/{holiday}/common/web/img_footer_line.png'
        )
      }}
    />
 */
export function createSinglePng2SpriteProps(
  color: string,
  width: number,
  height: number,
  path: string
) {
  return {
    linearGradientConfig: {
      colorStop: [
        {
          progress: 0,
          color: color
        },
        {
          progress: 1,
          color: color
        }
      ]
    },
    useCanvas: true,
    sprite: {
      x: 0,
      y: 0,
      width: width,
      height: height,
      image: path,
      total_width: width,
      total_height: height
    }
  }
}

export function cssAssetsPathReplace() {
  try {
    const config = {
      baseUnit: 100,
      unit: 'rem'
    }
    const reg =
      /::?(before|after)[\S\s]+\{[\S\s]*content: ('|")(\/lobby_asset\/[\S]+)('|")/
    const targets = cssRuleSelector((cssRule) => {
      return reg.test(cssRule.cssText)
    })
    const events: Record<string, Array<(url: string) => void>> = {}
    /**
     * 收集需要下载的项目
     */
    targets.forEach((it) => {
      const [, , , path] = it.cssText.match(reg) || []
      const item = urlParse(path, true)
      const {
        key = '',
        mode = 'sprite',
        scale: scaleString = '1'
      } = item.query || {}
      const sprite = createPngSprite(key as string)
      const scale = Number(scaleString as string)
      const callback = (url: string) => {
        let styles: Record<string, string> = {
          display: 'inline-block',
          width: `${(sprite.width * scale) / config.baseUnit}${config.unit}`,
          height: `${(sprite.height * scale) / config.baseUnit}${config.unit}`,
          backgroundImage: `url(${url})`,
          backgroundPosition: `${(-sprite.x * scale) / config.baseUnit}${
            config.unit
          } ${(-sprite.y * scale) / config.baseUnit}${config.unit}`,
          backgroundSize: `${(sprite.total_width * scale) / config.baseUnit}${
            config.unit
          } ${(sprite.total_height * scale) / config.baseUnit}${config.unit}`
        }
        if (mode === 'mask') {
          const maskImage = `url(${url})`
          styles = {
            'mask-image': maskImage,
            '-webkit-mask-image': maskImage
          }
        }

        Object.assign(styles, { content: `""`, visibility: 'visible' })
        for (const key in styles) {
          it.style[key] = styles[key as keyof typeof styles]
        }
      }
      const originUrl = mode === 'mask' ? path : sprite.image
      events[originUrl] = events[originUrl] || []
      events[originUrl].push(callback)
    })

    /**
     * 开始下载
     */
    Object.entries(events).forEach(([originUrl, callbacks]) => {
      const isSvg = /.svg(\?(\S+))?$/.test(originUrl)
      let lastUrl = ''
      createOssAssetsPathOrigialRetry(async (origin) => {
        const url = assetsPath(originUrl, origin)
        lastUrl = url
        if (isSvg) {
          const res = await axios.get(url)
          return `data:image/svg+xml;base64,${window.btoa(res.data)}`
        }
        const result = await loadImage(url)
        return (result as HTMLImageElement).src
      })
        .then((result) => {
          const url = result
          callbacks.forEach((callback) => {
            callback(url)
          })
        })
        .catch(() => {
          callbacks.forEach((callback) => {
            callback(lastUrl)
          })
        })
    })
  } catch (error) {}
}
