export enum TabKey {
  NULL = 'null',
  MENU = 'menu',
  HOME = 'home',
  EVENT = 'discount',
  LOGIN = 'login',
  REGISTER = 'register',
  PAY = 'recharge',
  WITHDRAW = 'withdraw',
  MINE = 'mine',
  VIP = 'vip',
  PROMOTE = 'promote',
  YUEBAO = 'yueBao',
  SERVICE = 'customer',
  DOWNLOAD = 'download',
  ACTIVITY = 'activity',
  TASK = 'task',
  REBATE = 'rebate',
  INTEREST = 'interest',
  CANRECEIVE = 'canReceive',
  RECORDS = 'records',
  FEEDBACK = 'feedback',
  AWARD = 'award',
  ACCOUNT_DETAILS = 'accountDetails',
  BETTING_RECORD = 'bettingRecord', //投注记录
  PERSONAL_STATMENT = 'personalStatement',
  SETTINGS = 'settings', //个人资料
  RECEIVERECORD = 'receiveRecord',
  OFFICIAL_CHANNEL = 'officialChannel', // 官方频道
  WITHDRAWMANAGEMENT = 'withdrawalManagement', //提现管理
  GAME = 'GAME',
  RECHARGEFUND = 'rechargeFund', //公积金
  COMMONQUESTION = 'commonQuestion', //常见问题
  MESSAGECENTER = 'messageCenter', //消息中心
  CENTERWALLET = 'centerWallet', //中心钱包
  RECHARGE_FUND = 'rechargeFund' // 公积金
}
