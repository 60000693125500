/**
 * 开始使用的是big.js，更轻量，但因有需求使用json-bigint,json-bigint内部依赖bignumber.js，
 * 固舍弃big.js 用 bignumber.js代替
 */
import BigNumber from 'bignumber.js'
const defaultConfig: BigNumber.Config = { EXPONENTIAL_AT: [-7, 20] }

// 递归运算，支持3个以上的多参数运算
const countReduce = (
  func: (a: BigNumber.Value, b: BigNumber.Value) => BigNumber,
  ...args: BigNumber.Value[]
) => {
  return args.reduce((count, item, index) => {
    if (index === 0) return count
    count = func(count, item)
    return count
  }, args[0])
}

/**
 * 导出加减乘除
 */
const plus = (...args: BigNumber.Value[]) =>
  countReduce(
    (a: BigNumber.Value, b: BigNumber.Value) =>
      new BigNumber(a).plus(new BigNumber(b)),
    ...args
  )
const minus = (...args: BigNumber.Value[]) =>
  countReduce(
    (a: BigNumber.Value, b: BigNumber.Value) =>
      new BigNumber(a).minus(new BigNumber(b)),
    ...args
  )
const times = (...args: BigNumber.Value[]) =>
  countReduce(
    (a: BigNumber.Value, b: BigNumber.Value) =>
      new BigNumber(a).times(new BigNumber(b)),
    ...args
  )
const div = (...args: BigNumber.Value[]) =>
  countReduce(
    (a: BigNumber.Value, b: BigNumber.Value) =>
      new BigNumber(a).div(new BigNumber(b)),
    ...args
  )

const random = () => BigNumber.random()

const maximum = (...n: BigNumber.Value[]) => {
  return BigNumber.maximum(...n)
}

const minimum = (...n: BigNumber.Value[]) => {
  return BigNumber.minimum(...n)
}

type combineType = number | string | BigNumber

/**
 * 判断第一个数值小于或者等于第二个数字
 * @param firstNumber 第一个数值
 * @param secondary  第二个数值
 * @returns
 */
const isLessThanOrEqualTo = (
  firstNumber: combineType,
  secondary: combineType
) => new BigNumber(firstNumber).lte(secondary)

/**
 * 判断第一个数值大于第二个数字
 * @param firstNumber 第一个数值
 * @param secondary  第二个数值
 * @returns
 */
const isGreaterThan = (firstNumber: combineType, secondary: combineType) =>
  new BigNumber(firstNumber).gt(secondary)

/**
 * 判断第一个数值大于或者等于第二个数字
 * @param firstNumber 第一个数值
 * @param secondary  第二个数值
 * @returns
 */
const isGreaterThanOrEqualTo = (
  firstNumber: combineType,
  secondary: combineType
) => new BigNumber(firstNumber).gte(secondary)

/**
 *
 *  转为纯字串，默认不转为科学计数法,如果转换失败则返回原输入
 */
const toString = (
  value: BigNumber.Value,
  EXPONENTIAL_AT: BigNumber.Config['EXPONENTIAL_AT'] = [-1e9, 1e9]
): string => {
  /**
   * 配置为无科学计数法的情况
   */
  BigNumber.config({
    EXPONENTIAL_AT
  })
  const result = new BigNumber(value).toString()
  BigNumber.config(defaultConfig)
  return isNaN(result as unknown as number) ? (value as string) : result
}

const hasDecimal = (number: BigNumber.Value): boolean => {
  const decimalPlaces = new BigNumber(number).decimalPlaces()
  return decimalPlaces > 0
}

export {
  plus,
  minus,
  times,
  div,
  random,
  minimum,
  maximum,
  toString,
  hasDecimal,
  isLessThanOrEqualTo,
  isGreaterThan,
  isGreaterThanOrEqualTo
}
