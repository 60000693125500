export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'System-Fehler',
        NETWORK_ERROR:
          'Die Netzwerkverbindung ist fehlgeschlagen. Bitte versuchen Sie es erneut, nachdem Sie das Netzwerk gewechselt haben',
        RSP_DATA_NOT_JSON: 'Das Antwortwertformat ist falsch',
        GEETEST_RESTRICTED:
          'Häufige Vorgänge, bitte versuchen Sie es in {seconds} Sekunden erneut'
      },
      tips: { title: 'Tipps' }
    }
  }
}
