import { BootInstallationSwitch } from '@/layouts/consts'
import { Currency } from '@/config/currencies.config'
import {
  DownloadUrlInfos,
  RewardBindData,
  RewardBindType,
  androidDownloadType
} from '@/api/common/type'
import { ForceInStallationSwitch } from '@/layouts/consts'
import { GlobalManager } from '@/context'
import { apiGetActiveRewardBindBeforeLogin } from '@/api/common'
import { apiReportDownloadUrlClick, getDownloadUrl } from '@/api/common'
import { defineStore } from 'pinia'
import { pick } from 'lodash'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import isMobile from '@/utils/isMobile'

export interface DownloadAppState {
  amount: number
  urlslist?: UrlsItem[]
  downloadUrls?: DownloadUrlInfos
  /** 是否点击过游戏弹出渠道下载APP弹出框*/
  hasClickDownloadAPPByChannel: boolean
  /**是否已经调用过获取新人福利的接口 */
  hasCallApiGetActiveRewardBindBeforeLogin: boolean
  /**新人福利开启的币种 */
  newRewardsCurrency: string
}
export interface DownloadAppRewardItem {
  amount: number
  currency: string
}
export interface UrlsItem {
  name: string
  value?: string
  size?: number | string
  type: androidDownloadType
  downloadPath?: string
  customizePath?: string
}

export const useDownloadAppStore = defineStore('downloadApp', {
  state: (): DownloadAppState => ({
    newRewardsCurrency: '',
    amount: 0,
    urlslist: [],
    downloadUrls: {},
    hasClickDownloadAPPByChannel: false,
    hasCallApiGetActiveRewardBindBeforeLogin: false
  }),
  getters: {
    /**站点币种是否属于当前的新人福利币种列表中 */
    isSiteCurrencyBelongNewRewardsCurrency(): boolean {
      const currentCurrency = useMainStore().userInfos?.currency
      return this.newRewardsCurrency.includes(currentCurrency as string)
    },
    /**提示下载APP和创建桌面快捷方式是否打开强制 */
    isForceInstallation() {
      const isForceInstallation = false
      const { brandLogoInfos } = useMainStore()
      if (!brandLogoInfos) {
        return false
      }
      const { forceInstallationSwitch } = brandLogoInfos
      const { Open } = ForceInStallationSwitch
      if (Open === forceInstallationSwitch) {
        return true
      }
      return isForceInstallation
    },

    getAmount() {
      const { rewardBindData, userInfos, isRealLogined } = useMainStore()
      const currencyIconMap = Currency.commonIconMap()
      const currency = (userInfos?.currency ||
        'CNY') as unknown as keyof typeof currencyIconMap
      const downloadAndLoginEventItem = (rewardBindData || []).find(
        (i) => i.bindType === RewardBindType.DownloadAndLogin
      )
      const downloadAppReward = JSON.parse(
        downloadAndLoginEventItem?.currency || '[]'
      ) as DownloadAppRewardItem[]
      let amount = 0

      if (downloadAndLoginEventItem?.isPop === 1) {
        // 登录后使用 /activetask/newcomer_benefit_pop接口中的 reward
        if (isRealLogined) {
          amount = downloadAndLoginEventItem?.reward || 0
        } else if (downloadAppReward && Array.isArray(downloadAppReward)) {
          // 登录前 /activetask/newcomer_benefit_reward 接口中的 currency amount
          amount =
            downloadAppReward.find((i) => i.currency === currency)?.amount || 0
        }
      }
      return amount
    },

    /**是否展示下载app提示框通过站点的配置 */
    showDownloadAppFromSite() {
      const { brandLogoInfos } = useMainStore()
      const showSuggestDownloadApp = false
      if (!brandLogoInfos) {
        return false
      }
      const { bootInstallationSwitch } = brandLogoInfos
      const { APP } = BootInstallationSwitch
      if (bootInstallationSwitch === APP) {
        return true
      }
      return showSuggestDownloadApp
    }
  },
  actions: {
    setHasClickDownloadAPPByChannel(hasClickDownloadAPPByChannel: boolean) {
      this.hasClickDownloadAPPByChannel = hasClickDownloadAPPByChannel
    },
    setNewRewardsCurrency(newRewardsCurrency: string) {
      this.newRewardsCurrency = newRewardsCurrency
    },
    setHasCallApiGetActiveRewardBindBeforeLogin(
      hasCallApiGetActiveRewardBindBeforeLogin: boolean
    ) {
      this.hasCallApiGetActiveRewardBindBeforeLogin =
        hasCallApiGetActiveRewardBindBeforeLogin
    },
    // 上报埋点
    async reportDownloadUrlClick(item: UrlsItem) {
      const { uuid } = useMainStore()
      const queryParams = pick(GlobalManager.App.$route.query, ['cid', 'id'])
      if (queryParams?.cid || queryParams?.id) {
        apiReportDownloadUrlClick({
          accessType: 1,
          accessUrl: window.location?.href,
          packageType: item?.type,
          promoter_info:
            (queryParams?.cid as string) || (queryParams?.id as string) || '',
          visitor_device: uuid
        })
      }
    },
    /**获取新人福利数据 */
    async getNewComerBenefit() {
      const result = await apiGetActiveRewardBindBeforeLogin()
      const dataPop = result?.list as RewardBindData[]
      const { setDownloadReward } = useMainStore()
      const { setNewRewardsCurrency } = useDownloadAppStore()
      const { setHasCallApiGetActiveRewardBindBeforeLogin } =
        useDownloadAppStore()
      const DownloadAndLoginItem = dataPop.find(
        (item) => item.bindType === RewardBindType.DownloadAndLogin
      )
      setNewRewardsCurrency(result?.currency ?? '')
      if (DownloadAndLoginItem) {
        setDownloadReward({
          reward: DownloadAndLoginItem.reward,
          bindType: RewardBindType.DownloadAndLogin,
          bindTypeText: DownloadAndLoginItem.bindTypeText,
          currency: DownloadAndLoginItem.currency as string
        })
      }
      setHasCallApiGetActiveRewardBindBeforeLogin(true)
    },

    async getDownloadUrls() {
      try {
        const { data } = await getDownloadUrl()
        this.downloadUrls = data.data
      } catch (error) {
        //
      }
      return this.downloadUrls
    },

    async getDownloadConfig() {
      const { t } = useI18n()
      await this.getDownloadUrls()

      //h5才会往下走 web是打开下载站 h5是弹出modal
      if (!this.downloadUrls) {
        return
      }
      const androidDownloadNames = {
        [androidDownloadType.SPEED]: t('lobby.rightBar.downloadAppSpeed'),
        [androidDownloadType.NORMAL]: t('lobby.rightBar.downloadAppNormal'),
        [androidDownloadType.COMPLETE]: t('lobby.rightBar.downloadAppComplete')
      }
      const { downloadList, androidList } = this.downloadUrls
      const isIOS = isMobile().apple.device
      const urlslist: UrlsItem[] | undefined = isIOS
        ? downloadList
        : androidList?.map((item) => {
            const name = item.size
              ? `${androidDownloadNames[item.type]} (${item.size?.toFixed(1)}M)`
              : (androidDownloadNames[item.type] as string)
            return {
              name,
              size: item.size?.toFixed(1),
              downloadPath: item.downloadPath,
              customizePath: item.customizePath,
              type: item.type
            }
          })

      this.urlslist = urlslist
    }
  }
})
