import { isSpecialIOS } from '@/utils/UserAgent'
import { windowConfig } from '../window'
import { windowOpen } from '@/utils/business-utils'

/** 当链接来自一个临时刚请求的接口时，可以用该方法，避免出现ios阻塞的情况 */
export const windowOpenAfterQuery = (url: string, win: Window | null) => {
  // let win!: Window | null
  // win = window.open('', '_blank')
  if (!url) return
  if (isSpecialIOS && !windowConfig.isWgPackage && win) {
    win.location = url
  } else {
    windowOpen(url)
  }
}
