export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'سسٹم کی خرابی',
        NETWORK_ERROR:
          'ข่าย ข่าย เครือ ล้มเหลว ล้มเหลว โปรดลอง โปรดลอง หลัง หลัง จาก จาก สลับ เครือ เครือ เครือ',
        RSP_DATA_NOT_JSON: 'رسپانس ویلیو فارمیٹ کی خرابی',
        GEETEST_RESTRICTED:
          'اوپیرایٹ کثرت سے ، براہ کرم {سیکنڈ} سیکنڈ میں دوبارہ کوشش کریں'
      },
      tips: { title: 'ریمائس' }
    }
  }
}
