export default {
  lobby: {
    common: {
      errorMessages: {
        DOMAIN_NOT_MATCH: '',
        SYSTEM_ERROR: 'सिस्टम एरर',
        NETWORK_ERROR:
          'नेटवर्क कनेक्शन अयशस्वी झाले, कृपया नेटवर्क स्विच केल्यानंतर पुन्हा प्रयत्न करा',
        RSP_DATA_NOT_JSON: 'प्रतिसाद मूल्य स्वरूप चुकीचे आहे',
        GEETEST_RESTRICTED:
          'वारंवार ऑपरेशन्स, कृपया {seconds}सेकंदांनी पुन्हा प्रयत्न करा'
      },
      tips: { title: 'टिपा' }
    }
  }
}
