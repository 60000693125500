import { Base, Component, Prop } from '@/vue-property-decorator'
import { Button } from 'ant-design-vue'
import AppTypes from '@/vue-types'

type Props = {
  block?: boolean
  disabled?: boolean
  type?:
    | 'link'
    | 'primary'
    | 'danger'
    | 'dashed'
    | 'ghost'
    | 'default'
    | 'finance'
    | 'success'
}

@Component<CustomButton>({ name: 'CustomButton' })
class CustomButton extends Base<unknown, Props> {
  @Prop(AppTypes.bool.def(false))
  private block!: Props['block']

  @Prop(AppTypes.bool.def(false))
  private disabled!: Props['disabled']

  @Prop(AppTypes.string)
  private type!: Props['type']
  render() {
    return (
      <Button
        {...{
          props: {
            block: this.block,
            disabled: this.disabled,
            type: this.type,
            ...this.$attrs
          },
          on: {
            ...this.$listeners
          }
        }}
      >
        {this.$slots.default}
      </Button>
    )
  }
}

export default CustomButton
