export default {
  name: 'close',
  theme: 'outline',
  icon: {
    tag: 'svg',
    attrs: {
      xmlns: 'http://www.w3.org/2000/svg',
      width: 16,
      height: 16,
      viewBox: '0 0 16 16',
      focusable: false
    },
    children: [
      {
        tag: 'g',
        attrs: { id: 'comm_icon_x', transform: 'translate(-1209.5 -160.5)' },
        children: [
          {
            tag: 'path',
            attrs: {
              id: 'Line_14',
              'data-name': 'Line 14',
              d: 'M14,15a1,1,0,0,1-.707-.293l-14-14a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l14,14A1,1,0,0,1,14,15Z',
              transform: 'translate(1210.5 161.5)'
            },
            children: []
          },
          {
            tag: 'path',
            attrs: {
              id: 'Line_15',
              'data-name': 'Line 15',
              d: 'M0,15a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414l14-14a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414l-14,14A1,1,0,0,1,0,15Z',
              transform: 'translate(1210.5 161.5)'
            },
            children: []
          }
        ]
      }
    ]
  }
}
