import { Entity, Model, from, nullable, to, type } from 'typox'

/**
 * 初始Ip检测结果
 */
@Entity()
export class IpCheck extends Model {
  constructor(source?: unknown) {
    super()
    this.merge(source)
  }

  /**
   * 是否被允许访问
   */
  @from('areaStatus')
  @type(Boolean)
  isAreaAllowed = true

  /**
   * 是否被允许访问
   * @deprecated 参考:isAreaAllowed
   */
  @from('areaStatus')
  @type(Boolean)
  areaStatus = true

  /**
   * 国家代码
   */
  @type(String)
  countryCode = ''

  /**
   * 用户IP
   */
  @type(String)
  ip = ''

  @type(String)
  recommendAreaCode = ''

  /**
   * 推荐货币
   */
  @type(String)
  recommendCurrency = ''

  /**
   * 推荐语言
   */
  @type(String)
  @nullable()
  recommendLanguage = ''

  /**
   * 站点是否在维护中
   */
  @from('siteMaintenanceStatus')
  @type(Boolean)
  isInMaintenance = false

  /**
   * 站点状态
   */
  @type(Boolean)
  status = false

  /**
   * 网络请求原始响应数据，用于部分特殊业务需求
   */
  axios = {}
  /**
   * 是否从服务端拿的数据
   */
  isFromService: boolean | null = null
}

/**
 * 日志上报数据
 */
@Entity()
export class TraceMessage extends Model {
  constructor(source?: Record<string, unknown>) {
    super()
    this.merge(source)
  }

  loading = false

  /**
   * 具体事件
   */
  @to()
  @type(String)
  event = ''

  /**
   * 发生上报时的URL
   */
  @to()
  @type(String)
  href = ''

  /**
   * 触发上报的时间
   */
  @to()
  @type([Date, Number])
  created = ''

  @to()
  @nullable()
  time = ''

  /**
   * 消息内容
   */
  @to()
  @type(String)
  @nullable()
  message = ''

  /**
   * 代码位置（一般是JS报错时会有）
   */
  @to()
  @type(String)
  @nullable()
  source = ''
}
